import { getWebsession } from '../vanilla-js/websession';

type UserRole =
  | 'admin'
  | 'student'
  | 'connector'
  | 'accountManager'
  | 'reporter'
  | 'groupReporter'
  | 'marketer';

export type WebSessionUserRole =
  | 'ADMIN'
  | 'ST'
  | 'AM'
  | 'LMSC'
  | 'MKT'
  | 'RPT'
  | 'GRPT';

export const userRoleToWebSessionUserRole: Record<
  UserRole,
  WebSessionUserRole
> = {
  admin: 'ADMIN',
  student: 'ST',
  accountManager: 'AM',
  connector: 'LMSC',
  marketer: 'MKT',
  reporter: 'RPT',
  groupReporter: 'GRPT',
};

const dashboardUserAccessList: WebSessionUserRole[] = [
  userRoleToWebSessionUserRole.admin,
  userRoleToWebSessionUserRole.accountManager,
  userRoleToWebSessionUserRole.connector,
  userRoleToWebSessionUserRole.marketer,
  userRoleToWebSessionUserRole.reporter,
  userRoleToWebSessionUserRole.groupReporter,
];

export const canAccessDashboard = (userRole?: WebSessionUserRole[]) => {
  return userRole
    ? userRole.some((role) => dashboardUserAccessList.includes(role))
    : false;
};

export const isExclusivelyGroupReporter = () => {
  const roles = getWebsession()?.CurrentUser?.Role;
  return roles?.includes('GRPT') && roles.length <= 2;
};
