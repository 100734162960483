import mixpanel from 'mixpanel-browser';
import type { Dict } from 'mixpanel-browser';
import { addError } from '../error-capturing';
import { default as originalOnButtonClickHandler } from './onButtonClickHandler';
import { getWebsession } from '../vanilla-js/websession';

export const catchWrapper =
  <Arguments extends Array<unknown>>(fn: (...args: Arguments) => void) =>
  (...args: Arguments) => {
    try {
      fn(...args);
    } catch (error) {
      console.error(error);
      addError(error);
    }
  };

const disectPath = () => {
  const regex =
    /(^\/+(?<locale>\w{2}-\w{2}))?(\/+[^/.]+)*\/+((?<pagename>[^.]+)(?<extension>\.[^.]*$|$))/i;

  const { pathname, host } = window.location;
  const { locale, pagename } = regex.exec(pathname)?.groups ?? {};

  return { pathname, locale, pagename, host };
};

export const track = (eventName: string, properties?: Dict) => {
  const user = getWebsession()?.CurrentUser;
  const notLoggedIn =
    window.location.href.includes('login') ||
    document.referrer.includes('login')
      ? true
      : false;

  const richProperties = { ...disectPath(), ...properties };
  if (window.MIXPANEL_TOKEN && (!notLoggedIn || user?.Id)) {
    catchWrapper(mixpanel.track.bind(mixpanel))(eventName, richProperties);
  } else {
    console.debug(`Tracked "${eventName}"`, richProperties);
  }
};

export { default as EventTracker } from './EventTracker';
export const onButtonClickHandler = catchWrapper(originalOnButtonClickHandler);
