import 'twin.macro';
import 'styled-components/macro';
import React from 'react';

export const LanguageSelectPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LanguageSelectPage" */ './client/LanguageSelectPage'
    ),
);

export const LoginPage = React.lazy(
  () =>
    import(/* webpackChunkName: "LoginPage" */ './client/LoginPage/LoginPage'),
);

export const AppDownloadPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AppDownloadPage" */ './client/AppDownloadPage'
    ),
);

export const DashboardPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "DashboardPage" */ './client/dashboard/DashboardPage'
    ),
);

export const OrganisationGroupsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "OrganisationGroupsPage" */ './client/dashboard/OrganisationGroupsPage'
    ),
);
export const OrganisationHomePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "OrganisationHomePage" */ './client/dashboard/OrganisationHomePage'
    ),
);
export const OrganisationLogPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "OrganisationLogPage" */ './client/dashboard/OrganisationLogPage'
    ),
);
export const OrganisationRolesPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "OrganisationRolesPage" */ './client/dashboard/OrganisationRolesPage'
    ),
);
export const OrganisationUsersPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "OrganisationUsersPage" */ './client/dashboard/OrganisationUsersPage'
    ),
);
export const RecommendCoursesGroupsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "RecommendCoursesGroupsPage" */ './client/dashboard/RecommendCoursesGroupsPage'
    ),
);
export const RecommendCoursesHomePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "RecommendCoursesHomePage" */ './client/dashboard/RecommendCoursesHomePage'
    ),
);
export const RecommendCoursesOrganisationPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "RecommendCoursesOrganisationPage" */ './client/dashboard/RecommendCoursesOrganisationPage'
    ),
);
export const ReportsCourseMilestonesPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsCourseMilestonesPage" */ './client/dashboard/ReportsCourseMilestonesPage'
    ),
);
export const ReportsCoursePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsCoursePage" */ './client/dashboard/ReportsCoursePage'
    ),
);
export const ReportsGroup = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsGroup" */ './client/dashboard/ReportsGroup'
    ),
);
export const ReportsGroupMemberPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsGroupMemberPage" */ './client/dashboard/ReportsGroupMemberPage'
    ),
);
export const ReportsHomePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsHomePage" */ './client/dashboard/ReportsHomePage'
    ),
);
export const ReportsLogPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsLogPage" */ './client/dashboard/ReportsLogPage'
    ),
);
export const ReportsStudentPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsStudentPage" */ './client/dashboard/ReportsStudentPage'
    ),
);
export const ResourcesHomePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ResourcesHomePage" */ './client/dashboard/ResourcesHomePage'
    ),
);
export const ResourcesIconsLeafletsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ResourcesIconsLeafletsPage" */ './client/dashboard/ResourcesIconsLeafletsPage'
    ),
);
export const ResourcesManualsDocumentationPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ResourcesManualsDocumentationPage" */ './client/dashboard/ResourcesManualsDocumentationPage'
    ),
);
export const ResourcesPromoStudioPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ResourcesPromoStudioPage" */ './client/dashboard/ResourcesPromoStudioPage'
    ),
);

export const OnlineCoursesOverviewPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "OnlineCoursesOverviewPage" */ './client/OnlineCoursesOverviewPage'
    ),
);
export const AllAssessmentsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AllAssessmentsPage" */ './client/AllAssessmentsPage'
    ),
);
export const ResetPasswordPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ResetPasswordPage" */ './client/ResetPasswordPage'
    ),
);
export const GdprPage = React.lazy(
  () => import(/* webpackChunkName: "GdprPage" */ './client/LegalPages/gdpr'),
);
export const OtherDevicePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "OtherDevicePage" */ './client/OtherDevicePage'
    ),
);
export const Iso27001Page = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Iso27001Page" */ './client/LegalPages/iso27001'
    ),
);
export const AllCoursesPage = React.lazy(
  () =>
    import(/* webpackChunkName: "AllCoursesPage" */ './client/AllCoursesPage'),
);
export const PersonalCoursesPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PersonalCoursesPage" */ './client/PersonalCoursesPage'
    ),
);
export const AssessmentPage = React.lazy(
  () =>
    import(/* webpackChunkName: "AssessmentPage" */ './client/AssessmentPage'),
);
export const GeneralConditionsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "GeneralConditionsPage" */ './client/LegalPages/generalconditions'
    ),
);
export const GoodscanPage = React.lazy(
  () => import(/* webpackChunkName: "GoodscanPage" */ './client/GoodscanPage'),
);
export const ReactGoodscanPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "GoodscanPage" */ './client/goodscan/GoodscanPage'
    ),
);
export const CoursePage = React.lazy(
  () => import(/* webpackChunkName: "CoursePage" */ './client/CoursePage'),
);
export const SimpleCoursePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SimpleCoursePage" */ './client/SimpleCoursePage'
    ),
);
export const SimpleGoodscanPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SimpleGoodscanPage" */ './client/SimpleGoodscanPage'
    ),
);
export const ReactSimpleGoodscanPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SimpleGoodscanPage" */ './client/goodscan/GoodscanPage'
    ),
);
export const NotificationsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "NotificationsPage" */ './client/NotificationsPage'
    ),
);
export const PrivacyStatementsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PrivacyStatementsPage" */ './client/LegalPages/privacystatements'
    ),
);
export const UserAgreementsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "UserAgreementsPage" */ './client/LegalPages/useragreements'
    ),
);
export const ResetPasswordOldPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ResetPasswordOldPage" */ './client/action/ResetPasswordOldPage'
    ),
);
export const ActivateAccountPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ActivateAccountPage" */ './client/action/ActivateAccountPage'
    ),
);
export const ActivatePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ActivatePage" */ './client/action/ActivatePage'
    ),
);
export const ActivateVoucherPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ActivateVoucherPage" */ './client/action/ActivateVoucherPage'
    ),
);
export const NotFoundPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "NotFoundPage" */ './NotFoundPage/NotFoundPage'
    ),
);
export const MyDashboardPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "MyDashboardPage" */ './client/my-account/MyDashboardPage'
    ),
);
export const PersonalInfoPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PersonalInfoPage" */ './client/my-account/PersonalInfo/PersonalInfoPage'
    ),
);
export const CookieSettingsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CookieSettingsPage" */ './client/cookies/CookieSettingsPage/CookieSettingsPage'
    ),
);
export const OnlineCoursesPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "OnlineCoursesPage" */ './client/OnlineCoursesPage'
    ),
);
export const LogoffPage = React.lazy(
  () => import(/* webpackChunkName: "LogoffPage" */ './client/LogoffPage'),
);

export const MailPage = React.lazy(
  () => import(/* webpackChunkName: "MailPage" */ './MailPage'),
);

export const MailingPage = React.lazy(
  () => import(/* webpackChunkName: "MailingPage" */ './MailingPage'),
);

const lazyPages = {
  ActivateAccountPage,
  ActivatePage,
  ActivateVoucherPage,
  AllAssessmentsPage,
  AllCoursesPage,
  AppDownloadPage,
  AssessmentPage,
  CookieSettingsPage,
  CoursePage,
  DashboardPage,
  GdprPage,
  GeneralConditionsPage,
  GoodscanPage,
  ReactGoodscanPage,
  Iso27001Page,
  LanguageSelectPage,
  LoginPage,
  LogoffPage,
  MailingPage,
  MailPage,
  MyDashboardPage,
  NotFoundPage,
  NotificationsPage,
  OnlineCoursesOverviewPage,
  OnlineCoursesPage,
  OrganisationGroupsPage,
  OrganisationHomePage,
  OrganisationLogPage,
  OrganisationRolesPage,
  OrganisationUsersPage,
  OtherDevicePage,
  PersonalCoursesPage,
  PersonalInfoPage,
  PrivacyStatementsPage,
  RecommendCoursesGroupsPage,
  RecommendCoursesHomePage,
  RecommendCoursesOrganisationPage,
  ReportsCourseMilestonesPage,
  ReportsCoursePage,
  ReportsGroup,
  ReportsGroupMemberPage,
  ReportsHomePage,
  ReportsLogPage,
  ReportsStudentPage,
  ResetPasswordOldPage,
  ResetPasswordPage,
  ResourcesHomePage,
  ResourcesIconsLeafletsPage,
  ResourcesManualsDocumentationPage,
  ResourcesPromoStudioPage,
  SimpleCoursePage,
  SimpleGoodscanPage,
  ReactSimpleGoodscanPage,
  UserAgreementsPage,
};

export default lazyPages;
