import { css } from 'twin.macro'; // eslint-disable-line @typescript-eslint/no-unused-vars
import { Label } from '../Label/Label';
import React from 'react';
import { textareaStyles, error } from './Textarea.styles';
import { FieldError } from 'react-hook-form';

type DefaultTextareaProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;
interface TextareaProps extends DefaultTextareaProps {
  label?: string;
  formError?: FieldError;
}

export const Textarea = React.forwardRef(
  ({ label, formError, ...props }: TextareaProps, ref: any) => {
    return (
      <Label
        label={label}
        required={props.required}
        className={props.className}
      >
        <>
          <textarea
            css={[textareaStyles, formError && error]}
            ref={ref}
            {...props}
          />
          {formError && <span>{formError.message}</span>}
        </>
      </Label>
    );
  },
);
