import mixpanel from 'mixpanel-browser';
import { getWebsession, LoggedInUser } from '../vanilla-js/websession';

export function identify() {
  const user = getWebsession()?.CurrentUser;

  if (user && user.Id !== -1) {
    // User is logged in
    const destinctId = mixpanel.get_distinct_id();
    const { ExternalPersonReference, CompanyId, Country } =
      user as LoggedInUser;

    if (ExternalPersonReference !== destinctId) {
      mixpanel.identify(ExternalPersonReference);
      mixpanel.people.set({ Country, CompanyId, 'Course page version': 'old' });
    }
  }
}

export const init = () => {
  return new Promise((resolve, reject) => {
    if (window.MIXPANEL_TOKEN) {
      mixpanel.init(window.MIXPANEL_TOKEN, {
        api_host: 'https://api-eu.mixpanel.com',
        ip: false,
        loaded: () => {
          identify();
          resolve(mixpanel);
        },
      });
    } else {
      reject();
    }
  });
};
