import { get_language } from '../vanilla-js/common';
import { LDFlagSet, asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { launchDarklyClientId } from '../config';

import type { LoggedInUser } from '../vanilla-js/websession';
import { useEffect, useState } from 'react';

export { useFlags as useFeatureFlags } from 'launchdarkly-react-client-sdk';

export const initializeLaunchDarkly = async () =>
  asyncWithLDProvider({
    clientSideID: launchDarklyClientId,
  });

export function useIdentifyLaunchDarkly(user: LoggedInUser, ldClient: any) {
  const [launchDarklyIdentify, setLaunchDarklyIdentify] =
    useState<Promise<LDFlagSet>>();

  useEffect(() => {
    if (ldClient && user && user.Id > 0) {
      setLaunchDarklyIdentify(
        new Promise((resolve, reject) => {
          ldClient.identify(
            {
              kind: 'multi',
              user: {
                key: user.ExternalPersonReference,
                country: user.Country ?? '',
                language: get_language(),
                loginOrigin: user.LoginOrigin ?? '',
                // --- Start: Temporary until flags and segments are updated ---
                company: user.CompanyName,
                companyId: user.ExternalCompanyReference,
                isTrialAccount: user.CompanyTypeId === 7 ? true : false,
                // --- End: Temporary until flags and segments are updated ---
              },
              company: {
                key: user.ExternalCompanyReference,
                name: user.CompanyName,
                isTrialAccount: user.CompanyTypeId === 7 ? true : false,
              },
            },
            null,
            (error: null | Error, flags: LDFlagSet) => {
              if (error) {
                reject(error);
              }
              resolve(flags);
            },
          );
        }),
      );
    }
  }, [user, ldClient]);

  return launchDarklyIdentify;
}
