import tw from 'twin.macro';

export const textareaStyles = tw`mt-2 px-5 py-4 box-border text-base text-grey-100 border-solid border-2 border-grey-10 rounded-medium w-full ease-in duration-150 inline-block
  placeholder:(text-grey-60 text-base)
  hover:(border-blue-100)
  focus:(border-blue-100)
  disabled:(border-grey-10 text-grey-30)
`;

export const error = tw`border-error`;
