export const getLtiUrlParameters = () => {
  const queryString = window.location.href.split('?')[1] || '';

  const decodedQueryString = decodeURIComponent(queryString);
  const searchParams = new URLSearchParams(decodedQueryString);

  if (searchParams.has('token') && searchParams.has('next')) {
    const token = searchParams.get('token') || '';
    let url = searchParams.get('next') || '';

    if (url.indexOf('https://') < 0) {
      url = 'https://' + url;
    }

    const languageCodeRegex =
      /(?:https?:\/\/\S+\/)([a-zA-Z]{2}-[a-zA-Z]{2})(?:\/.*)?/;
    const match = url.match(languageCodeRegex);
    const languageCode = match ? match[1] : '';

    return {
      token,
      languageCode,
      url,
    };
  }
  return null;
};
