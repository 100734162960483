const sanityQueryBuilder = ({
  projectId,
  dataset,
  apiVersion = 'v2021-10-21',
}: {
  projectId: String;
  dataset: String;
  apiVersion?: String;
}): ((query: string) => (params: any) => string) => {
  return (query: string) => {
    return (params?: any): string => {
      const queryUrl = new URL(
        `https://${projectId}.apicdn.sanity.io/${apiVersion}/data/query/${dataset}`,
      );
      queryUrl.searchParams.set('query', query);
      Object.entries(params || {}).forEach(([key, value]) => {
        queryUrl.searchParams.set(key, `"${value}"`);
      });
      return queryUrl.toString();
    };
  };
};

const promoBannersQueryBuilder = sanityQueryBuilder({
  projectId: 'r8gx6ktg',
  dataset: 'production',
});

export const sanityPromoBannersQuery =
  promoBannersQueryBuilder(`*[_type=="library" && language == $language].promoBanners[]->{
  'Id': _id,
  'TitlePart2': title,
  'Description': description,
  'ButtonText': linkText,
  'ButtonLink': link,
  'DesktopImageUrl': imageUrl,
  'MuxVideoPlaybackId': coalesce(video, __i18n_base->video).asset->playbackId
}{
  ...,
  'DesktopImageUrl': 'https://image.mux.com/'+MuxVideoPlaybackId+'/thumbnail.jpg',
  'TabletImageUrl': 'https://image.mux.com/'+MuxVideoPlaybackId+'/thumbnail.jpg',
  'MobileImageUrl': 'https://image.mux.com/'+MuxVideoPlaybackId+'/thumbnail.jpg'
}`);
