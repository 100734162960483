import { useEffect } from 'react';
import { init as initMixpanel } from '../third-parties/mixpanel';
import { init as initHotjar } from '../third-parties/hotjar';
import { track } from '../analytics';
import { isUserAllowedAnalyticalCookies } from '../utils/sessionUtils';

/**
 * A hook for initializing third party tools based on user's cookie preferences.
 *
 * Example;
 * gdpr_analytics = { AcceptDate: 1657804198679, FC: 1, AC: 1, CC: 0, SC: 0 }
 *
 * Explanation;
 * FC: functional cookies (cannot be disabled by user)
 * AC: analytical cookies (can be enabled/disabled by user)
 * CC: commercial cookies (not using for now, we may need in the future)
 * SC: social media cookies (not using for now, we may need in the future)
 */
const useCookiePreferences = () => {
  useEffect(() => {
    if (isUserAllowedAnalyticalCookies()) {
      try {
        // added this check because some how it breaks unit tests
        if (window.MIXPANEL_TOKEN) {
          initMixpanel();
          track('Pageview');
        }

        initHotjar();
      } catch (error) {
        console.debug('Mixpanel/Hotjar not initialized.', error);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useCookiePreferences;
