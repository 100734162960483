import { LanguageCode } from '../models/Language';

export const getSsoV2UrlParameters = () => {
  const url = window.location.href;
  let queryString = url.split('?')[1] || '';
  queryString = queryString.split('#')[0] || '';

  const searchParams = new URLSearchParams(queryString);

  const customer = decodeURIComponent(searchParams.get('customer') || '');
  let language = decodeURIComponent(
    searchParams.get('language') || '',
  ).toLowerCase();
  const deepLink = decodeURIComponent(searchParams.get('deeplink') || '');

  if (!Object.values(LanguageCode).includes(language as LanguageCode)) {
    const langEndIndex = url.indexOf('/login-sso');
    const langStartIndex = langEndIndex - 5;
    language = url.substring(langStartIndex, langEndIndex) || '';
  }

  if (customer && language) {
    return {
      customer,
      language,
      deepLink,
    };
  }
  return null;
};
