import { Label } from './InfoLabelStyle';

type LabelProps = {
  label?: string;
  type?: string;
  small?: boolean;
};

export const InfoLabel = ({ label, type, small }: LabelProps) => {
  return (
    <Label type={type} small={small}>
      {label}
    </Label>
  );
};
