import React from 'react';
import { Heading7 } from '../../index';
import * as Styled from './ModalTitleStyle';

type Props = { children: string | React.ReactNode };

export const ModalTitle = ({ children, ...props }: Props) => {
  return (
    <Styled.TitleContainer {...props}>
      <Heading7>{children}</Heading7>
    </Styled.TitleContainer>
  );
};
