import tw, { styled } from 'twin.macro';

export type ButtonProps = {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'ghost' | 'green' | 'pink';
  size?: 'small' | 'medium' | 'large' | 'mobile';
  disabled?: boolean;
};

const mapAttributes = ({
  as,
  href,
  disabled,
}: ButtonProps & { as?: string; href?: string }) => {
  const isDisabledLink = as === 'a' && disabled;
  const isButtonElement = as === undefined || as === 'button';
  return {
    // $ prefix so it doesn't become an attribute on the HTML element
    $disabled: disabled,
    href: isDisabledLink ? undefined : href,
    disabled: isButtonElement ? disabled : undefined,
  };
};

export const Button = styled.button.attrs(mapAttributes)<ButtonProps>(
  ({
    size = 'large',
    variant = 'primary',
    $disabled,
  }: ButtonProps & ReturnType<typeof mapAttributes>) => [
    {
      minWidth: '14rem',
    },
    // flexbox to ensure alignment is correct when as="a" instead of as="button"
    tw`font-body rounded-medium focus:ring ring-opacity-50 flex justify-center items-center cursor-pointer disabled:cursor-auto`,
    size === 'large' && tw`h-9 text-base`,
    size === 'medium' && tw`h-8 text-sm`,
    size === 'small' && tw`h-7 text-sm`,
    variant === 'primary' &&
      tw`bg-primary-default text-primary-text-default hover:(bg-primary-hover) active:(bg-primary-active text-primary-text-active)`,
    variant === 'primary' &&
      $disabled &&
      tw`bg-primary-disabled text-primary-text-disabled hover:(bg-primary-disabled) active:(text-primary-text-disabled)`,
    variant === 'secondary' &&
      tw`bg-blue-10 text-secondary-text-default hover:bg-blue-20 active:bg-blue-20`,
    variant === 'secondary' &&
      $disabled &&
      tw`border-secondary-disabled text-secondary-text-disabled hover:(border-secondary-disabled) active:(text-secondary-text-disabled)`,
    variant === 'ghost' && tw`bg-transparent`,
    variant === 'ghost' &&
      $disabled &&
      tw`bg-primary-disabled text-primary-text-disabled hover:(bg-primary-disabled) active:(text-primary-text-disabled)`,
    variant === 'tertiary' && tw`bg-transparent text-blue-100`,
    variant === 'tertiary' &&
      $disabled &&
      tw`bg-primary-disabled text-primary-text-disabled hover:(bg-primary-disabled) active:(text-primary-text-disabled)`,
    variant === 'green' && tw`bg-green-100 text-blue-100`,
    variant === 'green' &&
      $disabled &&
      tw`bg-primary-disabled text-primary-text-disabled hover:(bg-primary-disabled) active:(text-primary-text-disabled)`,
    variant === 'pink' && tw`bg-pink-100 text-grey-0 no-underline`,
    variant === 'pink' &&
      $disabled &&
      tw`bg-primary-disabled text-primary-text-disabled hover:(bg-primary-disabled) active:(text-primary-text-disabled)`,
  ],
);
