export enum LanguageCode {
  CaEs = 'ca-es',
  DaDk = 'da-dk',
  DeAt = 'de-at',
  DeCh = 'de-ch',
  DeDe = 'de-de',
  EnAu = 'en-au',
  EnGb = 'en-gb',
  EnIn = 'en-in',
  EsAr = 'es-ar',
  EsEs = 'es-es',
  EsMx = 'es-mx',
  FrBe = 'fr-be',
  FrFr = 'fr-fr',
  IdId = 'id-id',
  ItIt = 'it-it',
  NlBe = 'nl-be',
  NlNl = 'nl-nl',
  PlPl = 'pl-pl',
  PtBr = 'pt-br',
  PtPt = 'pt-pt',
  SvSe = 'sv-se',
  TrTr = 'tr-tr',
  ZhCn = 'zh-cn',
}

export interface Language {
  code: LanguageCode;
  generic: Record<string, string>;
  pages: {
    data?: Record<string, string | undefined>;
    page: string;
  }[];
}

export interface LanguageFromAPI {
  languageregion: LanguageCode;
  generic: Record<string, string>;
  pages: {
    data?: Record<string, string | undefined>;
    page: string;
  }[];
}
