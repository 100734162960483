import React from 'react';
import * as Styled from './ModalContentStyle';

type Props = {
  minHeight?: string;
  children: string | React.ReactNode;
};

export const ModalContent = ({ minHeight, children, ...props }: Props) => {
  return (
    <Styled.ContentContainer minHeight={minHeight} {...props}>
      {children}
    </Styled.ContentContainer>
  );
};
