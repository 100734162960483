import React from 'react';
import * as Styled from './ModalActionsStyle';

type Props = {
  children: React.ReactNode;
  buttonAlign?: string | undefined;
} & Styled.ButtonContainerProps;

export const ModalActions = ({
  align = 'center',
  buttonAlign,
  children,
  ...props
}: Props) => {
  return (
    <Styled.ButtonContainer className={buttonAlign} align={align} {...props}>
      {children}
    </Styled.ButtonContainer>
  );
};
