import * as React from 'react';
import 'twin.macro';
import styled from 'styled-components';

const InlineStyledSvg = styled.svg`
  // icons have a 1/6 margin
  top: 0.167em;
  position: relative;
`;

export type IconProps = React.SVGProps<SVGSVGElement> &
  SVGRProps &
  React.RefAttributes<SVGSVGElement> & {
    svgComponent: React.ComponentType<
      React.SVGProps<SVGSVGElement> & SVGRProps
    >;
    variant?: 'inline' | 'block';
  };

export const Icon = ({
  svgComponent,
  variant = 'block',
  ...props
}: IconProps) => {
  const isInlineVariant = variant === 'inline';
  const SvgComponent = isInlineVariant ? InlineStyledSvg : svgComponent;
  const asProp = isInlineVariant ? svgComponent : undefined;
  const icon = (
    <SvgComponent
      as={asProp}
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 24 24"
      {...props}
    />
  );
  if (isInlineVariant) {
    return <span tw="inline-flex self-center">{icon}</span>;
  }
  return icon;
};
