import { activityDeepLink, Lti13ActivityDeepLink } from '../constants/routes';
import { edit_url } from '../vanilla-js/client';

export const redirectTo = (route: string) => {
  document.location = edit_url(route);
};

type CreateCourseActivityUrlParameters = {
  courseId: number;
  activityId: number;
  languageRegion: string;
  pageName: string;
};
type CreateLti13CourseActivityUrlParameters = {
  courseGuid: string;
  activityId: number;
  languageRegion: string;
};

export const createCourseActivityUrlParameters = ({
  courseId,
  activityId,
  languageRegion,
  pageName,
}: CreateCourseActivityUrlParameters) =>
  activityDeepLink
    .replace('{languageCode}', languageRegion)
    .replace('{courseCode}', courseId.toString())
    .replace('{pageName}', pageName)
    .replace('{activityId}', activityId.toString());

export const createLti13CourseActivityUrlParameters = ({
  courseGuid,
  activityId,
  languageRegion,
}: CreateLti13CourseActivityUrlParameters) =>
  Lti13ActivityDeepLink.replace('{languageCode}', languageRegion)
    .replace('{courseGuid}', courseGuid.toString())
    .replace('{activityId}', activityId.toString());
