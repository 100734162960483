import { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { getActivePageIndex } from './utils';
import { DashboardMenuItem } from '../../vanilla-js/client';
import { SpinnerPage } from '../../pages/SpinnerPage';
import { DashboardMenu } from './DashboardMenu';
import tw, { styled, css } from 'twin.macro';
import {
  injectInitScript,
  cleanUpInitScript,
  ChatboxStyle,
} from '../../third-parties/salesforce-chat';
import { useFeatureFlags } from '../../third-parties/launchdarkly';
import useDashboardMenuAccess from '../../hooks/useDashboardMenuAccess';
export interface DashboardMenuItemWithBeta extends DashboardMenuItem {
  SubItems?: Array<
    DashboardMenuItem & {
      hasNewFlag?: boolean;
      hasBetaFlag?: boolean;
    }
  >;
}

const StyledContainer = styled.div(
  ({ backgroundCircle }: { backgroundCircle: string }) => [
    tw`bg-grey-5 relative min-h-[calc(100vh - 75px)] flex flex-col`,
    css`
      &:before {
        z-index: 0;
        content: '';
        background-image: url(/img/${backgroundCircle});
        background-size: 66%;
        background-position: 140% 140%;
        ${tw`absolute top-0 bottom-0 right-0 left-0 opacity-20 bg-fixed bg-no-repeat overflow-hidden pointer-events-none`}
      }
    `,
  ],
);

export const DashboardLayout = () => {
  const location = useLocation();
  const [backgroundCircle, setBackgroundCircle] = useState<string>('');
  const { embeddedChat } = useFeatureFlags();
  const accessibleMenuItems = useDashboardMenuAccess();

  useEffect(() => {
    if (accessibleMenuItems.length) {
      setBackgroundCircle(
        accessibleMenuItems[getActivePageIndex(accessibleMenuItems)]
          ?.Background,
      );
    }
  }, [location, accessibleMenuItems]);

  useEffect(() => {
    const assessmentsSelectionSubItemId = 4354;
    const lessonSelectionSubItemId = 4382;
    const lti13ItemId = 4337;
    const connectSubMenuIdsWithNewFlag = [
      assessmentsSelectionSubItemId,
      lessonSelectionSubItemId,
    ];
    const connectSubMenuIdsWithBetaFlag = [lti13ItemId];
    const connectMenuItem = accessibleMenuItems.find(
      (item) => item.Id === 4330,
    );
    if (connectMenuItem) {
      connectMenuItem.SubItems = connectMenuItem.SubItems?.map((item) => {
        if (connectSubMenuIdsWithNewFlag.includes(item.Id)) {
          item.hasNewFlag = true;
        }
        if (connectSubMenuIdsWithBetaFlag.includes(item.Id)) {
          item.hasBetaFlag = true;
        }
        return item;
      });
    }
  }, [accessibleMenuItems]);

  useEffect(() => {
    if (embeddedChat) {
      injectInitScript();
    }

    return cleanUpInitScript;
  }, [embeddedChat]);

  return !accessibleMenuItems.length ? (
    <SpinnerPage />
  ) : (
    <>
      <StyledContainer backgroundCircle={backgroundCircle}>
        <DashboardMenu menuItems={accessibleMenuItems}></DashboardMenu>
        <Outlet />
      </StyledContainer>
      {embeddedChat && <ChatboxStyle />}
    </>
  );
};
