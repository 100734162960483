import { Token } from '../models/Token';
import { getCookie, setCookie } from '../vanilla-js/common';
import { WebSession, websession } from '../vanilla-js/websession';
import { redirectTo } from './routingUtils';

const windowWebsession = window.websession ?? {};
const searchParams = new URLSearchParams(window.location.search);

export const hasUserSession = (loginOrigin?: string) => {
  let session = websession;
  if (!websession || !websession.CurrentUser) {
    session = JSON.parse(localStorage.getItem('websession') ?? '{}');
  }
  return (
    session?.CurrentUser?.Id > 0 &&
    (!loginOrigin || session?.CurrentUser?.LoginOrigin === loginOrigin)
  );
};

export const isFirstVisit = () =>
  windowWebsession.IsFirstVisit && getCookie('firstVisitModalSeen') !== 'true';

export const setOnboardingAsSeen = () =>
  setCookie('firstVisitModalSeen', true, 365);

export const setWebsession = (
  newWebsession: WebSession,
  storage: Storage = localStorage,
) => {
  const websessionString =
    typeof newWebsession === 'string'
      ? newWebsession
      : JSON.stringify(newWebsession);

  storage.setItem('websession', websessionString);
};

export const setToken = (token: Token) => {
  if (token.sessiontoken === 1) {
    sessionStorage.setItem('token', token.token);
  } else {
    localStorage.setItem('token', token.token);
  }
};

export const getToken = () =>
  sessionStorage.getItem('token') ?? localStorage.getItem('token') ?? '';

export const setLanguage = (languageCode: string) => {
  sessionStorage.setItem('currentLanguage', languageCode);
  setCookie('language', languageCode, 365);
};

export function saveGDPRCookie(analyticalCookiesStatus: boolean) {
  const cookieData = {
    AcceptDate: Date.now(),
    FC: 1,
    AC: analyticalCookiesStatus ? 1 : 0,
    CC: 1,
    SC: 0,
  };

  setCookie('gdpr_analytics', JSON.stringify(cookieData), 365);

  searchParams.set('gdpr', cookieData.AC.toString());

  window.location.search = searchParams.toString();
}

export function isCookieConsentNeeded() {
  const gdprParam = searchParams.get('gdpr');

  const gdprCookie = getCookie('gdpr_analytics');

  return (
    !gdprCookie &&
    gdprParam !== '1' &&
    document.URL.indexOf('cookie-settings.html') === -1
  );
}

export function isUserAllowedAnalyticalCookies() {
  const gdprParam = searchParams.get('gdpr');

  try {
    const gdprCookie = getCookie('gdpr_analytics') || '{}';
    const { AC } = JSON.parse(gdprCookie) || { AC: 0 };

    return AC === 1 || gdprParam === '1';
  } catch (error) {
    return gdprParam === '1';
  }
}

export const clearPreviousLmsSession = () => {
  sessionStorage.removeItem('websession');
  sessionStorage.removeItem('token');
};

export const clearPreviousContentPageStorage = () => {
  sessionStorage.removeItem('contentId');
  sessionStorage.removeItem('contentType');
  sessionStorage.removeItem('contentMode');
  sessionStorage.removeItem('platform');
};

export const handleLmsNext = (
  newWebsession: WebSession,
  redirectUrl: string,
) => {
  setWebsession(newWebsession, sessionStorage);
  clearPreviousContentPageStorage();
  redirectTo(redirectUrl);
};
