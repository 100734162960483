import { Spinner } from './Spinner';

import tw from 'twin.macro';

const StyledOverlayDiv = tw.div`fixed flex items-center justify-center inset-0 bg-primary-default bg-opacity-70 overflow-y-auto h-full w-full z-[9999]`;

const StyledDialogDiv = tw.div`flex flex-col rounded-medium bg-grey-0 border-none mx-5 p-11`;

type Props = {
  isOpen: boolean;
  label?: string;
};

export const SpinnerModal = ({ isOpen, label }: Props) => {
  return isOpen ? (
    <StyledOverlayDiv role="dialog">
      <StyledDialogDiv>
        <Spinner label={label} />
      </StyledDialogDiv>
    </StyledOverlayDiv>
  ) : null;
};
