import { ReactComponent as FlagBE } from '../assets/country-flags/be.svg';
import { ReactComponent as FlagDE } from '../assets/country-flags/de.svg';
import { ReactComponent as FlagES } from '../assets/country-flags/es.svg';
import { ReactComponent as FlagFR } from '../assets/country-flags/fr.svg';
import { ReactComponent as FlagGB } from '../assets/country-flags/gb.svg';
import { ReactComponent as FlagIT } from '../assets/country-flags/it.svg';
import { ReactComponent as FlagNL } from '../assets/country-flags/nl.svg';
import { ReactComponent as FlagPL } from '../assets/country-flags/pl.svg';
import { ReactComponent as FlagPT } from '../assets/country-flags/pt.svg';
import { ReactComponent as FlagSE } from '../assets/country-flags/se.svg';
import { ReactComponent as FlagDK } from '../assets/country-flags/dk.svg';
import { ReactComponent as FlagAT } from '../assets/country-flags/at.svg';
import { ReactComponent as FlagCH } from '../assets/country-flags/ch.svg';
import { ReactComponent as FlagBR } from '../assets/country-flags/br.svg';
import { ReactComponent as FlagIN } from '../assets/country-flags/in.svg';
import { ReactComponent as FlagAU } from '../assets/country-flags/au.svg';
import { ReactComponent as FlagAR } from '../assets/country-flags/ar.svg';
import { ReactComponent as FlagMX } from '../assets/country-flags/mx.svg';
import { ReactComponent as FlagID } from '../assets/country-flags/id.svg';
import { ReactComponent as FlagTR } from '../assets/country-flags/tr.svg';
import { ReactComponent as FlagCN } from '../assets/country-flags/cn.svg';
import { ReactComponent as flagPlaceholder } from '../assets/country-flags/placeholder.svg';
import { ReactComponent as FlagCatalonia } from '../assets/country-flags/catalonia.svg';

interface CountryFlagProps {
  languageRegionCode: string;
}

type ReactComponent = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & { title?: string }
>;

export const CountryFlag = ({ languageRegionCode }: CountryFlagProps) => {
  const FlagPlaceholder: ReactComponent = flagPlaceholder;
  const flagIcons: Record<string, ReactComponent> = {
    ar: FlagAR,
    at: FlagAT,
    au: FlagAU,
    be: FlagBE,
    br: FlagBR,
    ch: FlagCH,
    cn: FlagCN,
    de: FlagDE,
    dk: FlagDK,
    es: FlagES,
    fr: FlagFR,
    gb: FlagGB,
    id: FlagID,
    in: FlagIN,
    it: FlagIT,
    mx: FlagMX,
    nl: FlagNL,
    pl: FlagPL,
    pt: FlagPT,
    se: FlagSE,
    tr: FlagTR,
  };

  const FlagIconFound = flagIcons[languageRegionCode.slice(-2)];

  if (/ca-es/i.test(languageRegionCode)) {
    return <FlagCatalonia className="flag" title="ca-es" />;
  }

  if (FlagIconFound) {
    return <FlagIconFound className="flag" title={languageRegionCode} />;
  } else {
    return <FlagPlaceholder className="flag" title={languageRegionCode} />;
  }
};
