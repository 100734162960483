import tw, { css, styled } from 'twin.macro';

export const AppAdDiv = tw.div`flex w-full justify-center pt-9 bg-[#F7F7F7]`;

export const AppAdBanner = styled.div(() => [
  tw`bg-blue-100 rounded-medium flex flex-col gap-4 items-center py-4 px-11 justify-between text-grey-0 lg:(flex-row gap-8)`,
  css`
    a {
      text-decoration: none;
      min-width: 160px;
    }
  `,
]);
