import tw, { styled, css } from 'twin.macro';

export const SlideUpContainer = styled.aside(() => [
  tw`w-full h-auto bg-blue-100 text-grey-0 py-3 fixed bottom-0 left-0 flex flex-col items-center z-10 lg:hidden`,
  css`
    transition: all 0.3s linear;
    &.hidden {
      transform: translateY(100%);
    }
  `,
]);

export const ExitButton = tw.button`absolute right-5`;
