import { Button, Heading6, Paragraph } from '../../common-components';
import { InfoLabel } from '../../common-components/InfoLabel';
import { allCoursesPageId } from '../../constants/pageIds';
import useTranslation from '../../hooks/useTranslation';
import { AppAdDiv, AppAdBanner } from './AppAdvertBannerStyles';

export const AppAdvertBanner = () => {
  const t = useTranslation(allCoursesPageId);

  return (
    <AppAdDiv>
      <AppAdBanner>
        <InfoLabel label={t('new')} />
        <Heading6>{t('txt_go_mobile')}</Heading6>
        <Paragraph>{t('txt_app_available')}</Paragraph>
        <Button as="a" href="app-download.html" variant="secondary">
          {t('txt_download_now')}
        </Button>
      </AppAdBanner>
    </AppAdDiv>
  );
};
