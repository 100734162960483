import React from 'react';
import * as Styled from './ModalImageStyle';

type Props = {
  image: string;
  modalType?: string | undefined;
} & Styled.ImageContainerProps;

export const ModalImage = ({ parentWidth, image, modalType }: Props) => {
  return (
    <Styled.ImageContainer parentWidth={parentWidth}>
      <Styled.Image
        className={modalType}
        parentWidth={parentWidth}
        src={image}
      />
    </Styled.ImageContainer>
  );
};
