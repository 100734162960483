import { redirectTo } from './routingUtils';
import { setToken } from './sessionUtils';

type ExchangeCodeForTokenOptions = {
  code: string;
  codeVerifier: string;
};

export async function authorizeAgrioSSO() {
  const authUrl = 'https://agrio.ternair.com/ae/identity/connect/authorize';
  const codeVerifier = generateCodeVerifier();
  const codeChallenge = await generateCodeChallenge(codeVerifier);

  localStorage.setItem('codeVerifier', codeVerifier);

  const params = new URLSearchParams({
    response_type: 'code',
    redirect_uri: window.AGRIO_REDIRECT_URL,
    scope: 'openid profile',
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
    response_mode: 'query',
    client_id: window.AGRIO_CLIENT_ID,
  });

  window.location.href = `${authUrl}?${params.toString()}`;
}

export async function exchangeCodeForToken({
  code,
  codeVerifier,
}: ExchangeCodeForTokenOptions) {
  const redirectUri = window.AGRIO_REDIRECT_URL;
  const clientId = window.AGRIO_CLIENT_ID;
  const data = new URLSearchParams({
    grant_type: 'authorization_code',
    code,
    client_id: clientId,
    code_verifier: codeVerifier,
    redirect_uri: redirectUri,
  });

  try {
    const agrioResponse = await fetch(window.ghapi + '/api/AgrioToken/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: data,
    }).then((response) => response.json());
    return agrioResponse;
  } catch (error) {
    console.error(`Failed to exchange code for token: ${error}`);
  }
}

export async function fetchAgrioSSOUser(token: string) {
  const response = await fetch(window.ghapi + '/api/AgrioToken/userinfo', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.ok) {
    return await response.json();
  } else {
    console.error(`Failed to fetch user: ${response.status}`);
  }
}

export async function fetchEdApiToken(agrioUserInfo: any, accessToken: any) {
  const ssoBodyRequest = getSsoBodyRequest(agrioUserInfo, accessToken);

  try {
    const response = await fetch(window.ghapi + '/api/agriotoken', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(ssoBodyRequest),
    });
    if (!response.ok) {
      const errorResponse = await response.text();
      throw new Error(errorResponse);
    }
    const jsonResponse = await response.json();
    setToken({
      sessiontoken: jsonResponse.sessionToken,
      token: jsonResponse.token,
    });
  } catch (error) {
    throw new Error('Failed to get Education API JWT: ' + error);
  }
}

export function getSsoBodyRequest(agrioUserInfo: any, accessToken: string) {
  return {
    foreignUserId: agrioUserInfo.sub,
    firstName: agrioUserInfo.Firstname,
    surname: agrioUserInfo.Lastname,
    email: agrioUserInfo.Email,
    accessToken: accessToken,
  };
}

export function redirectOnSuccess(deepLink: string | null) {
  if (deepLink) {
    redirectTo(deepLink);
  } else {
    redirectTo('personal-courses.html');
  }
}

const generateCodeVerifier = () => {
  const charset =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
  const verifierLength = 128;
  let verifier = '';

  for (let i = 0; i < verifierLength; i++) {
    verifier += charset.charAt(Math.floor(Math.random() * charset.length));
  }

  return verifier;
};

const generateCodeChallenge = async (codeVerifier: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const hash = await window.crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hash));
  const hashBase64 = btoa(
    hashArray.map((b) => String.fromCharCode(b)).join(''),
  );

  return hashBase64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
};
