import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { redirectTo } from '../utils/routingUtils';
import { useWebsession } from './useWebsession';

const ALLOWED_ROUTE_MATCHERS = [
  'assessment',
  '/course/',
  'cookies',
  'iso23001',
  'general-conditions',
  'gdpr',
  'goodscan',
  'login_lti',
  'logoff',
  'otherdevice',
  'privacy-statements',
];

const useLtiGaurd = () => {
  const [CurrentUser] = useWebsession('CurrentUser');

  const location = useLocation();

  return useEffect(() => {
    const isLti13User = CurrentUser && CurrentUser.LoginOrigin === 'LTI13';
    const isAdmin = CurrentUser && CurrentUser.Role?.includes('ADMIN');

    const isLocationAllowed = ALLOWED_ROUTE_MATCHERS.some((matcher) =>
      location.pathname.toLowerCase().includes(matcher),
    );

    if (isLti13User && !isAdmin && !isLocationAllowed) {
      redirectTo('/client/logoff.html');
    }
  }, [location, CurrentUser]);
};

export default useLtiGaurd;
