import {
  fetchJson,
  fetchBlob,
  fetchJsonFromWebsite,
} from '../vanilla-js/common';
import { getToken } from './sessionUtils';

export const get = async <Input, Output>(
  endpoint: string,
  body?: Input,
): Promise<Output> => {
  return fetchJson({
    url: endpoint,
    data: body as any,
  });
};

export const getFromWebsite = async <Input, Output>(
  endpoint: string,
  body?: Input,
): Promise<Output> => {
  return fetchJsonFromWebsite({
    url: endpoint,
    data: body as any,
  });
};

export const getFromURL = async <Input, Output>(
  url: string,
  body?: Input,
): Promise<Output> => {
  return (await fetch(url)).json();
};

export const getWithAuth = async <Input, Output>(
  url: string,
  body?: Input,
): Promise<Output> => {
  return (
    await fetch(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
  ).json();
};

export const postToURL = async <Input, Output>(
  url: string,
  body?: Input,
): Promise<Output> => {
  return (await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(body),
  })) as unknown as Output;
};

export const post = async <Input, Output>(
  endpoint: string,
  request?: Input,
): Promise<Output> => {
  const response = await fetch(
    new Request(window.ghapi + endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(request) as any,
    }),
  );
  if (!response.ok) {
    throw response;
  }
  return response.json() as Promise<Output>;
};

export const getUnauth = async <Input, Output>(
  endpoint: string,
  body?: Input,
): Promise<Output> => {
  const response = await fetch(
    new Request(endpoint, {
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        Accept: 'application/json',
      },
      body: body as any,
    }),
  );
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json() as Promise<Output>;
};

export const postUnauth = async <Input, Output>(
  endpoint: string,
  data?: Input,
): Promise<Output> => {
  const response = await fetch(
    new Request(window.ghapi + endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(data),
    }),
  );
  if (!response.ok) {
    throw response;
  }
  return response.json() as Promise<Output>;
};
export const put = async <Input, Output>(
  endpoint: string,
  request?: Input,
): Promise<Output> => {
  return fetchJson({
    url: endpoint,
    method: 'PUT',
    data: JSON.stringify(request) as any,
  });
};

export const getWithoutJSON = async <Input, Output>(
  endpoint: string,
  body?: Input,
): Promise<Output> => {
  return fetchBlob({
    url: endpoint,
    data: body as any,
  });
};

export const postWithoutJSON = async <Input, Output>(
  endpoint: string,
  body?: Input,
): Promise<Output> => {
  return fetchBlob({
    url: endpoint,
    method: 'POST',
    data: JSON.stringify(body) as any,
  });
};
