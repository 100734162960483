import tw, { styled } from 'twin.macro';

export const Footer = styled.footer(() => [
  tw`flex flex-col items-center pt-10 bg-grey-0 border-t border-grey-10`,
  tw`md:(pt-10)`,
  tw`xl:(pt-10 flex-row flex-wrap w-full items-start)`,
]);

export const HeadSection = styled.section(() => [
  tw`flex flex-col`,
  tw`md:(px-10 w-auto)`,
]);

export const SocialList = styled.ul(() => [
  tw`list-none text-blue-100 mt-8`,
  tw`flex flex-row flex-wrap space-x-6`,
  tw`md:(ml-auto items-center)`,
]);

export const SocialListLink = tw.a`h-8 w-8 inline-block text-blue-100 hover:text-blue-130`;

export const BodyDiv = styled.div(() => [
  tw`text-grey-80 mt-10 px-11 space-y-10 w-full text-center`,
  tw`md:(flex flex-row justify-center space-x-11 space-y-0 w-auto)`,
  tw`xl:(mt-6 ml-auto pl-10 text-left)`,
]);

export const TailSection = styled.section(() => [
  tw`self-stretch flex flex-col items-center bg-grey-5 mt-10 space-y-6 px-5 py-8 text-grey-80 text-sm`,
  tw`lg:(flex flex-row flex-wrap justify-between px-10 py-6 space-y-0 w-full)`,
]);

export const LegalList = styled.ul(() => [
  tw`flex flex-col flex-wrap items-center justify-center gap-4`,
  tw`md:(flex-row gap-6)`,
  tw`xl:(gap-8)`,
]);

export const LegalListItem = tw.li`inline-block hover:underline`;

export const LanguageSelectWrapper = styled.div(() => [tw`w-[225px]`]);
