import tw, { css } from 'twin.macro';

type BadgeProps = {
  variant: 'new' | 'beta';
  children: React.ReactNode;
};

const badgeVariantToBgColorMap: Record<BadgeProps['variant'], string> = {
  new: '#0affba',
  beta: '#fac541',
};

const Badge = ({ children, variant }: BadgeProps) => {
  return (
    <div
      css={[
        tw`py-1 px-3 inline-block rounded-small text-sm text-text-primary font-normal`,
        css`
          background-color: ${badgeVariantToBgColorMap[variant]};
        `,
      ]}
    >
      {children}
    </div>
  );
};

export default Badge;
