import { get_language, mergeArrays } from './common';
import { addError } from '../error-capturing';

const sessionStorageKey = 'lang';

export const lang = {};
window.lang = lang;

async function fetchJson(url) {
  try {
    return (await fetch(url)).json();
  } catch (e) {
    console.error(e);
    addError(e, {
      source: 'vanilla-js/language#fetchJson',
      url,
    });
    return {};
  }
}

let langLoaded = false;
function loadFromSessionStrorage() {
  if (!langLoaded) {
    Object.assign(lang, JSON.parse(sessionStorage.getItem(sessionStorageKey)));
    langLoaded = true;
  }
  return lang;
}

export async function getLanguageObject() {
  const langObject = loadFromSessionStrorage();
  const languageRegion = get_language();
  const differentLanguageIsLoaded =
    langObject?.languageregion?.toUpperCase() !== languageRegion.toUpperCase();

  if (differentLanguageIsLoaded) {
    dBase.removeItem('Courses');
    const newLang = await fetchJson(`/lang/${languageRegion}.json`);
    const newCrowdInLang = await fetchJson(
      `/crowdin-lang/${languageRegion}.json`
    );
    const langPayload = Object.assign({}, newLang, newCrowdInLang);
    langPayload.generic = Object.assign(
      {},
      newLang.generic,
      newCrowdInLang.generic
    );
    langPayload.pages = mergeArrays(newLang.pages, newCrowdInLang.pages);
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(langPayload));
    Object.assign(langObject, langPayload);
  }
  return langObject;
}
