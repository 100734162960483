import Hotjar from '@hotjar/browser';
import { hotjarSiteId } from '../config';

const siteId = hotjarSiteId;
const hotjarVersion = 6;

export function init() {
  if (siteId) {
    Hotjar.init(siteId, hotjarVersion);
  }
}
