import { useState } from 'react';
import { DownloadAppButton } from '../../common-components/DownloadAppButton/DownloadAppButton';
import { SlideUp } from '../../common-components/SlideUp/SlideUp';
import useTranslation from '../../hooks/useTranslation';
import { MobileIcon } from '../../common-components/Icon/icons.generated';
import { Button, Heading5, Paragraph } from '../../common-components';
import { AppAdvertInner } from './AppAdvertStyles';

export const AppAdvert = () => {
  let [showSlide, setShowSlide] = useState(true);
  const t = useTranslation();

  const slideDown = () => {
    setShowSlide(false);
  };

  if (!showSlide) {
    sessionStorage.setItem('showAppAdvert', 'false');
  }

  return (
    <SlideUp shown={showSlide} onChange={() => setShowSlide(!showSlide)}>
      <AppAdvertInner>
        <MobileIcon className="large-icon" />
        <Heading5>{t('txt_experience')}</Heading5>
        <Paragraph>{t('txt_on_the_go')}</Paragraph>
        <DownloadAppButton />
        <Button size="small" onClick={slideDown}>
          {t('txt_not_now')}
        </Button>
      </AppAdvertInner>
    </SlideUp>
  );
};
