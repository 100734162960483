import { useMemo, useState } from 'react';
import { useFeatureFlags } from '../third-parties/launchdarkly';

export const entitlements = {
  entLicenses: false,
  entGroupActivities: false,
  entLessonsAssessments: false,
  entLessonsNoAssessments: false,
  entGoodscanAccess: false,
  entIscanAccess: false,
  entOscanAccess: false,
  entAssessments: false,
  entCourses: false,
  entLessons: false,
  entLearningBite: false,
  entReportOrganisation: false,
  entReportTeam: false,
  entReportIndividual: false,
  entReportCourse: false,
  entReportActivities: false,
  entReportMilestone: false,
  entReportAssessment: false,
  entReportTeamMembers: false,
  entManageOrganisation: false,
  entManageUsers: false,
  entManageTeam: false,
  entManageOrganisationLog: false,
  entManageRoles: false,
  entConnectionMethodLti11: false,
  entConnectionMethodLti13: false,
  entConnectionMethodRs: false,
  entConnectionMethodRsMlscorm: false,
  entConnectionMethodSso: false,
  entConnectionMethodWeb: false,
  entConnectionMethodMobile: false,
  entConnectionSelectionCourses: false,
  entConnectionSelectionGoodscan: false,
  entConnectionSelectionSingleActivities: false,
  entConnectionSelectionAssessments: false,
  entConnectionSelectionLessons: false,
  entRecommendOrganisation: false,
  entRecommendTeam: false,
  entResourcesPromoStudio: false,
  entResourcesManualsDocuments: false,
  entResourcesCoursesImagesLeafletsWorkouts: false,
  entResourcesHelpcenter: false,
  entSupportDigitalCoach: false,
  entSupportDedicatedCoach: false,
  entSupportCustomerSupport24h: false,
  entSupportCustomerSupport48h: false,
  entLanguageCaEs: false,
  entLanguageDaDk: false,
  entLanguageDeDe: false,
  entLanguageDeAt: false,
  entLanguageDeCh: false,
  entLanguageEnGb: false,
  entLanguageEnAu: false,
  entLanguageEnIn: false,
  entLanguageEsEs: false,
  entLanguageEsAr: false,
  entLanguageEsMx: false,
  entLanguageFrFr: false,
  entLanguageFrBe: false,
  entLanguageItIt: false,
  entLanguageNlNl: false,
  entLanguageNlBe: false,
  entLanguagePlPl: false,
  entLanguagePtPt: false,
  entLanguagePtBr: false,
  entLanguageSvSe: false,
  entLanguageTrTr: false,
  entLanguageZhCn: false,
};

export type Entitlements = typeof entitlements;
export type EntitlementName = keyof typeof entitlements;

const entitlementFeatureRegEx = /^ent[A-Z][A-Za-z0-9_-]*/;

const isEntitlementFlag = (str: string) => entitlementFeatureRegEx.test(str);

export default function useEntitlements() {
  const flags = useFeatureFlags();

  const [entitlementsMap, setEntitlementsMap] =
    useState<Entitlements>(entitlements);

  useMemo(() => {
    const updatedEntitlements = Object.entries(flags).reduce(
      (acc, [flagName, flagValue]) => {
        if (isEntitlementFlag(flagName)) {
          acc[flagName as EntitlementName] = flagValue;
        }
        return acc;
      },
      { ...entitlements },
    );
    setEntitlementsMap({ ...updatedEntitlements });
  }, [flags]);

  return entitlementsMap;
}
