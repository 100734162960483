import { useEffect, useState } from 'react';
import { useWebsession } from './useWebsession';
import { getLibrariesHierarchy } from '../utils/apiRequests';

const useLibraryHierarchy = () => {
  const [libraryHierarchyConfig, setLibraryHierarchyConfig] = useState<
    Record<string, any>
  >({});
  const [userLibraryHierarchyConfig, setUserLibraryHierarchyConfig] = useState<
    Record<string, any>
  >({});
  const [languageMap, setLanguageMap] = useState<Record<string, any>>({});
  const [LanguageList] = useWebsession('LanguageList');
  const [response, setResponse] = useState<any>({
    'en-au': {
      base: 'en-gb',
    },
  });

  useEffect(() => {
    if (LanguageList) {
      setLanguageMap(
        LanguageList.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.LanguageRegion]: curr,
          }),
          {} as Record<string, any>,
        ),
      );
    }
  }, [LanguageList]);

  useEffect(() => {
    const loadHierarchy = async () => {
      const newResponse = await getLibrariesHierarchy();
      setResponse(newResponse);
    };
    loadHierarchy();
  }, [languageMap]);

  useEffect(() => {
    if (response) {
      setLibraryHierarchyConfig(
        Object.fromEntries(
          Object.entries(response).map(([key, value]) => [
            key,
            {
              ...(value as any),
              source: languageMap[(value as any).source],
              ...languageMap[(value as any).base],
            },
          ]),
        ),
      );
    }
  }, [response, languageMap]);

  useEffect(() => {
    setUserLibraryHierarchyConfig(
      Object.fromEntries(
        Object.entries(libraryHierarchyConfig)
          .map(([key, value]) => {
            const usrCfgStr = localStorage?.getItem(`baseLibCfg_${key}`);
            if (usrCfgStr) {
              try {
                const usrCfg = JSON.parse(usrCfgStr);
                if (usrCfg.baseEnabled === false) {
                  value = null;
                }
              } catch {
                /* ignore */
              }
            }
            return [key, value];
          })
          .filter(([, value]) => Boolean(value)),
      ),
    );
  }, [libraryHierarchyConfig]);

  return {
    config: libraryHierarchyConfig,
    userConfig: userLibraryHierarchyConfig,
  };
};

export default useLibraryHierarchy;
