// Generated by scripts/generate-icons.sh DO NOT EDIT
import React from 'react';
import { Icon, IconProps } from './Icon';

import { ReactComponent as _AddressBook } from './icons/regular/AddressBook.svg';
import { ReactComponent as _Adjustalt } from './icons/regular/Adjust-alt.svg';
import { ReactComponent as _Adjust } from './icons/regular/Adjust.svg';
import { ReactComponent as _AlarmClock } from './icons/regular/Alarm Clock.svg';
import { ReactComponent as _AlarmSnooze } from './icons/regular/Alarm Snooze.svg';
import { ReactComponent as _AngleDown } from './icons/regular/Angle Down.svg';
import { ReactComponent as _AngleLeft } from './icons/regular/Angle Left.svg';
import { ReactComponent as _AngleRight } from './icons/regular/Angle Right.svg';
import { ReactComponent as _AngleUp } from './icons/regular/Angle Up.svg';
import { ReactComponent as _ArrowAltCircleDown } from './icons/regular/Arrow Alt Circle Down.svg';
import { ReactComponent as _ArrowAltCircleLeft } from './icons/regular/Arrow Alt Circle Left.svg';
import { ReactComponent as _ArrowAltCircleRight } from './icons/regular/Arrow Alt Circle Right.svg';
import { ReactComponent as _ArrowAltCircleUp } from './icons/regular/Arrow Alt Circle Up.svg';
import { ReactComponent as _ArrowAltSquareDown } from './icons/regular/Arrow Alt Square Down.svg';
import { ReactComponent as _ArrowAltSquareLeft } from './icons/regular/Arrow Alt Square Left.svg';
import { ReactComponent as _ArrowAltSquareRight } from './icons/regular/Arrow Alt Square Right.svg';
import { ReactComponent as _ArrowAltSquareUp } from './icons/regular/Arrow Alt Square Up.svg';
import { ReactComponent as _ArrowDown } from './icons/regular/Arrow Down.svg';
import { ReactComponent as _ArrowFromBottomupload } from './icons/regular/Arrow From Bottom - upload.svg';
import { ReactComponent as _ArrowLeft } from './icons/regular/Arrow Left.svg';
import { ReactComponent as _ArrowRight } from './icons/regular/Arrow Right.svg';
import { ReactComponent as _ArrowToBottomdownload } from './icons/regular/Arrow To Bottom - download.svg';
import { ReactComponent as _ArrowUp } from './icons/regular/Arrow Up.svg';
import { ReactComponent as _ArrowsAltH } from './icons/regular/Arrows Alt H.svg';
import { ReactComponent as _ArrowsAltV } from './icons/regular/Arrows Alt V.svg';
import { ReactComponent as _ArrowsAlt } from './icons/regular/Arrows Alt.svg';
import { ReactComponent as _At } from './icons/regular/At.svg';
import { ReactComponent as _Automotive } from './icons/regular/Automotive.svg';
import { ReactComponent as _Award } from './icons/regular/Award.svg';
import { ReactComponent as _BadgeCheck } from './icons/regular/Badge Check.svg';
import { ReactComponent as _Badge } from './icons/regular/Badge.svg';
import { ReactComponent as _Ban } from './icons/regular/Ban.svg';
import { ReactComponent as _Bars } from './icons/regular/Bars.svg';
import { ReactComponent as _BellSlash } from './icons/regular/Bell Slash.svg';
import { ReactComponent as _Bell } from './icons/regular/Bell.svg';
import { ReactComponent as _Bolt } from './icons/regular/Bolt.svg';
import { ReactComponent as _Book } from './icons/regular/Book.svg';
import { ReactComponent as _Bookmark } from './icons/regular/Bookmark.svg';
import { ReactComponent as _Books } from './icons/regular/Books.svg';
import { ReactComponent as _BoxArchive } from './icons/regular/BoxArchive.svg';
import { ReactComponent as _Bug } from './icons/regular/Bug.svg';
import { ReactComponent as _Building } from './icons/regular/Building.svg';
import { ReactComponent as _Bullhorn } from './icons/regular/Bullhorn.svg';
import { ReactComponent as _BullseyeArrow } from './icons/regular/Bullseye Arrow.svg';
import { ReactComponent as _Bullseye } from './icons/regular/Bullseye.svg';
import { ReactComponent as _Calculator } from './icons/regular/Calculator.svg';
import { ReactComponent as _CalendarAlt } from './icons/regular/Calendar Alt.svg';
import { ReactComponent as _CalendarCheck } from './icons/regular/Calendar Check.svg';
import { ReactComponent as _Calendar } from './icons/regular/Calendar.svg';
import { ReactComponent as _Cameramovie } from './icons/regular/Camera-movie.svg';
import { ReactComponent as _Camera } from './icons/regular/Camera.svg';
import { ReactComponent as _CaretCircleDown } from './icons/regular/Caret Circle Down.svg';
import { ReactComponent as _CaretCircleLeft } from './icons/regular/Caret Circle Left.svg';
import { ReactComponent as _CaretCircleRight } from './icons/regular/Caret Circle Right.svg';
import { ReactComponent as _CaretCircleUp } from './icons/regular/Caret Circle Up.svg';
import { ReactComponent as _ChartArea } from './icons/regular/Chart Area.svg';
import { ReactComponent as _ChartBar } from './icons/regular/Chart Bar.svg';
import { ReactComponent as _ChartLineDown } from './icons/regular/Chart Line Down.svg';
import { ReactComponent as _ChartLine } from './icons/regular/Chart Line.svg';
import { ReactComponent as _ChartPieAlt } from './icons/regular/Chart Pie Alt.svg';
import { ReactComponent as _ChartPie } from './icons/regular/Chart Pie.svg';
import { ReactComponent as _ChartScatter } from './icons/regular/Chart Scatter.svg';
import { ReactComponent as _ChartColumn } from './icons/regular/ChartColumn.svg';
import { ReactComponent as _Check } from './icons/regular/Check.svg';
import { ReactComponent as _ChevronCircleDown } from './icons/regular/Chevron Circle Down.svg';
import { ReactComponent as _ChevronCircleLeft } from './icons/regular/Chevron Circle Left.svg';
import { ReactComponent as _ChevronCircleRight } from './icons/regular/Chevron Circle Right.svg';
import { ReactComponent as _ChevronCircleUp } from './icons/regular/Chevron Circle Up.svg';
import { ReactComponent as _ChevronDown } from './icons/regular/Chevron Down.svg';
import { ReactComponent as _ChevronLeft } from './icons/regular/Chevron Left.svg';
import { ReactComponent as _ChevronRight } from './icons/regular/Chevron Right.svg';
import { ReactComponent as _ChevronUp } from './icons/regular/Chevron Up.svg';
import { ReactComponent as _Clipboard } from './icons/regular/Clipboard.svg';
import { ReactComponent as _Clock } from './icons/regular/Clock.svg';
import { ReactComponent as _Close } from './icons/regular/Close.svg';
import { ReactComponent as _CloudDownloadAlt } from './icons/regular/Cloud Download Alt.svg';
import { ReactComponent as _CloudUploadAlt } from './icons/regular/Cloud Upload Alt.svg';
import { ReactComponent as _Cloud } from './icons/regular/Cloud.svg';
import { ReactComponent as _Cog } from './icons/regular/Cog.svg';
import { ReactComponent as _CommentAltSlash } from './icons/regular/Comment Alt Slash.svg';
import { ReactComponent as _CommentAlt } from './icons/regular/Comment Alt.svg';
import { ReactComponent as _Compass } from './icons/regular/Compass.svg';
import { ReactComponent as _CompressAlt } from './icons/regular/Compress Alt.svg';
import { ReactComponent as _CompressArrowsAlt } from './icons/regular/Compress Arrows Alt.svg';
import { ReactComponent as _Copy } from './icons/regular/Copy.svg';
import { ReactComponent as _Digging } from './icons/regular/Digging.svg';
import { ReactComponent as _DraftingCompass } from './icons/regular/Drafting Compass.svg';
import { ReactComponent as _Edit } from './icons/regular/Edit.svg';
import { ReactComponent as _Ellipsisv } from './icons/regular/Ellipsis-v.svg';
import { ReactComponent as _EnvelopeOpen } from './icons/regular/Envelope Open.svg';
import { ReactComponent as _Envelope } from './icons/regular/Envelope.svg';
import { ReactComponent as _ExclamationCircle } from './icons/regular/Exclamation Circle.svg';
import { ReactComponent as _ExclamationTriangle } from './icons/regular/Exclamation Triangle.svg';
import { ReactComponent as _ExpandAlt } from './icons/regular/Expand Alt.svg';
import { ReactComponent as _ExpandArrows } from './icons/regular/Expand Arrows.svg';
import { ReactComponent as _EyeSlash } from './icons/regular/Eye Slash.svg';
import { ReactComponent as _Eye } from './icons/regular/Eye.svg';
import { ReactComponent as _Facebook } from './icons/regular/Facebook.svg';
import { ReactComponent as _FileAlt } from './icons/regular/File Alt.svg';
import { ReactComponent as _FileSearch } from './icons/regular/File Search.svg';
import { ReactComponent as _File } from './icons/regular/File.svg';
import { ReactComponent as _Filter } from './icons/regular/Filter.svg';
import { ReactComponent as _Fingerprint } from './icons/regular/Fingerprint.svg';
import { ReactComponent as _FlagCheckered } from './icons/regular/Flag Checkered.svg';
import { ReactComponent as _Flag } from './icons/regular/Flag.svg';
import { ReactComponent as _FolderOpen } from './icons/regular/Folder Open.svg';
import { ReactComponent as _Folder } from './icons/regular/Folder.svg';
import { ReactComponent as _GamepadAlt } from './icons/regular/Gamepad Alt.svg';
import { ReactComponent as _Gauge } from './icons/regular/Gauge.svg';
import { ReactComponent as _GaugeHigh } from './icons/regular/GaugeHigh.svg';
import { ReactComponent as _Gear } from './icons/regular/Gear.svg';
import { ReactComponent as _Gift } from './icons/regular/Gift.svg';
import { ReactComponent as _GlobeAfrica } from './icons/regular/Globe Africa.svg';
import { ReactComponent as _GlobeAmericas } from './icons/regular/Globe Americas.svg';
import { ReactComponent as _GlobeAsia } from './icons/regular/Globe Asia.svg';
import { ReactComponent as _GlobeEurope } from './icons/regular/Globe Europe.svg';
import { ReactComponent as _Globe } from './icons/regular/Globe.svg';
import { ReactComponent as _GoodHabitz } from './icons/regular/GoodHabitz.svg';
import { ReactComponent as _GraduationCap } from './icons/regular/GraduationCap.svg';
import { ReactComponent as _Griplines } from './icons/regular/Grip lines.svg';
import { ReactComponent as _Hammer } from './icons/regular/Hammer.svg';
import { ReactComponent as _HandPointDown } from './icons/regular/Hand Point Down.svg';
import { ReactComponent as _HandPointLeft } from './icons/regular/Hand Point Left.svg';
import { ReactComponent as _HandPointRight } from './icons/regular/Hand Point Right.svg';
import { ReactComponent as _HandPointUp } from './icons/regular/Hand Point Up.svg';
import { ReactComponent as _Heart } from './icons/regular/Heart.svg';
import { ReactComponent as _Home } from './icons/regular/Home.svg';
import { ReactComponent as _Hourglasshalf } from './icons/regular/Hourglass-half.svg';
import { ReactComponent as _Hourglassstart } from './icons/regular/Hourglass-start.svg';
import { ReactComponent as _Hourglass } from './icons/regular/Hourglass.svg';
import { ReactComponent as _Image } from './icons/regular/Image.svg';
import { ReactComponent as _InfoCircle } from './icons/regular/Info Circle.svg';
import { ReactComponent as _Instagram } from './icons/regular/Instagram.svg';
import { ReactComponent as _Key } from './icons/regular/Key.svg';
import { ReactComponent as _Keyboard } from './icons/regular/Keyboard.svg';
import { ReactComponent as _Laptop } from './icons/regular/Laptop.svg';
import { ReactComponent as _LifeRing } from './icons/regular/Life Ring.svg';
import { ReactComponent as _LightbulbOn } from './icons/regular/Lightbulb On.svg';
import { ReactComponent as _Lightbulb } from './icons/regular/Lightbulb.svg';
import { ReactComponent as _Link } from './icons/regular/Link.svg';
import { ReactComponent as _Linkedin } from './icons/regular/Linkedin.svg';
import { ReactComponent as _Loading } from './icons/regular/Loading.svg';
import { ReactComponent as _LockAlt } from './icons/regular/Lock Alt.svg';
import { ReactComponent as _LockOpenAlt } from './icons/regular/Lock Open Alt.svg';
import { ReactComponent as _LockOpen } from './icons/regular/Lock Open.svg';
import { ReactComponent as _Lock } from './icons/regular/Lock.svg';
import { ReactComponent as _MapMarkerSlash } from './icons/regular/Map Marker Slash.svg';
import { ReactComponent as _MapMarker } from './icons/regular/Map Marker.svg';
import { ReactComponent as _Medal } from './icons/regular/Medal.svg';
import { ReactComponent as _MicrophoneSlash } from './icons/regular/Microphone Slash.svg';
import { ReactComponent as _Microphone } from './icons/regular/Microphone.svg';
import { ReactComponent as _Minuscircle } from './icons/regular/Minus-circle.svg';
import { ReactComponent as _Minussquare } from './icons/regular/Minus-square.svg';
import { ReactComponent as _Minus } from './icons/regular/Minus.svg';
import { ReactComponent as _Mobile } from './icons/regular/Mobile.svg';
import { ReactComponent as _Newspaper } from './icons/regular/Newspaper.svg';
import { ReactComponent as _PaperPlane } from './icons/regular/Paper Plane.svg';
import { ReactComponent as _Paperclip } from './icons/regular/Paperclip.svg';
import { ReactComponent as _Pause } from './icons/regular/Pause.svg';
import { ReactComponent as _Pen } from './icons/regular/Pen.svg';
import { ReactComponent as _PencilAlt } from './icons/regular/Pencil Alt.svg';
import { ReactComponent as _PhoneLaptop } from './icons/regular/Phone Laptop.svg';
import { ReactComponent as _PhotoVideo } from './icons/regular/Photo Video.svg';
import { ReactComponent as _Play } from './icons/regular/Play.svg';
import { ReactComponent as _Plug } from './icons/regular/Plug.svg';
import { ReactComponent as _PlusCircle } from './icons/regular/Plus Circle.svg';
import { ReactComponent as _PlusSquare } from './icons/regular/Plus Square.svg';
import { ReactComponent as _Plus } from './icons/regular/Plus.svg';
import { ReactComponent as _Podcast } from './icons/regular/Podcast.svg';
import { ReactComponent as _Print } from './icons/regular/Print.svg';
import { ReactComponent as _QuestionCircle } from './icons/regular/Question Circle.svg';
import { ReactComponent as _QuestionSquare } from './icons/regular/Question Square.svg';
import { ReactComponent as _Random } from './icons/regular/Random.svg';
import { ReactComponent as _RedoAlt } from './icons/regular/Redo Alt.svg';
import { ReactComponent as _RepeatAlt } from './icons/regular/Repeat Alt.svg';
import { ReactComponent as _RocketLaunch } from './icons/regular/Rocket Launch.svg';
import { ReactComponent as _Rocket } from './icons/regular/Rocket.svg';
import { ReactComponent as _Rss } from './icons/regular/Rss.svg';
import { ReactComponent as _SearchMinus } from './icons/regular/Search Minus.svg';
import { ReactComponent as _SearchPlus } from './icons/regular/Search Plus.svg';
import { ReactComponent as _Search } from './icons/regular/Search.svg';
import { ReactComponent as _ShareAlt } from './icons/regular/Share Alt.svg';
import { ReactComponent as _ShieldAlt } from './icons/regular/Shield Alt.svg';
import { ReactComponent as _ShieldCheck } from './icons/regular/Shield Check.svg';
import { ReactComponent as _Shield } from './icons/regular/Shield.svg';
import { ReactComponent as _SlidersH } from './icons/regular/Sliders H.svg';
import { ReactComponent as _SlidersV } from './icons/regular/Sliders V.svg';
import { ReactComponent as _Snooze } from './icons/regular/Snooze.svg';
import { ReactComponent as _Sparkles } from './icons/regular/Sparkles.svg';
import { ReactComponent as _Star } from './icons/regular/Star.svg';
import { ReactComponent as _StickyNote } from './icons/regular/Sticky Note.svg';
import { ReactComponent as _Stopwatch } from './icons/regular/Stopwatch.svg';
import { ReactComponent as _SyncAlt } from './icons/regular/Sync Alt.svg';
import { ReactComponent as _Tablet } from './icons/regular/Tablet.svg';
import { ReactComponent as _TachometerAlt } from './icons/regular/Tachometer Alt.svg';
import { ReactComponent as _Tag } from './icons/regular/Tag.svg';
import { ReactComponent as _Tags } from './icons/regular/Tags.svg';
import { ReactComponent as _Textsize } from './icons/regular/Text-size.svg';
import { ReactComponent as _ThLarge } from './icons/regular/Th Large.svg';
import { ReactComponent as _ThList } from './icons/regular/Th List.svg';
import { ReactComponent as _Th } from './icons/regular/Th.svg';
import { ReactComponent as _ThumbsDown } from './icons/regular/Thumbs Down.svg';
import { ReactComponent as _ThumbsUp } from './icons/regular/Thumbs Up.svg';
import { ReactComponent as _Thumbtack } from './icons/regular/Thumbtack.svg';
import { ReactComponent as _Timescircle } from './icons/regular/Times-circle.svg';
import { ReactComponent as _Times } from './icons/regular/Times.svg';
import { ReactComponent as _Toolbox } from './icons/regular/Toolbox.svg';
import { ReactComponent as _Tools } from './icons/regular/Tools.svg';
import { ReactComponent as _TrashAlt } from './icons/regular/Trash Alt.svg';
import { ReactComponent as _Trash } from './icons/regular/Trash.svg';
import { ReactComponent as _TrophyAlt } from './icons/regular/Trophy Alt.svg';
import { ReactComponent as _Trophy } from './icons/regular/Trophy.svg';
import { ReactComponent as _TvAlt } from './icons/regular/Tv Alt.svg';
import { ReactComponent as _Tv } from './icons/regular/Tv.svg';
import { ReactComponent as _Twitter } from './icons/regular/Twitter.svg';
import { ReactComponent as _Undo } from './icons/regular/Undo.svg';
import { ReactComponent as _UserAltSlash1 } from './icons/regular/User Alt Slash-1.svg';
import { ReactComponent as _UserAltSlash } from './icons/regular/User Alt Slash.svg';
import { ReactComponent as _UserAlt } from './icons/regular/User Alt.svg';
import { ReactComponent as _UserCircle } from './icons/regular/User Circle.svg';
import { ReactComponent as _UserCog } from './icons/regular/User Cog.svg';
import { ReactComponent as _UserEdit } from './icons/regular/User Edit.svg';
import { ReactComponent as _UserHeadset } from './icons/regular/User Headset.svg';
import { ReactComponent as _UserLock } from './icons/regular/User Lock.svg';
import { ReactComponent as _UserMinus } from './icons/regular/User Minus.svg';
import { ReactComponent as _UserPlus } from './icons/regular/User Plus.svg';
import { ReactComponent as _UserShield } from './icons/regular/User Shield.svg';
import { ReactComponent as _UserTag } from './icons/regular/User Tag.svg';
import { ReactComponent as _UserTimes } from './icons/regular/User Times.svg';
import { ReactComponent as _UserUnlock } from './icons/regular/User Unlock.svg';
import { ReactComponent as _User } from './icons/regular/User.svg';
import { ReactComponent as _UsersClass } from './icons/regular/Users Class.svg';
import { ReactComponent as _UsersCog } from './icons/regular/Users Cog.svg';
import { ReactComponent as _UsersMedical } from './icons/regular/Users Medical.svg';
import { ReactComponent as _UsersSlash } from './icons/regular/Users Slash.svg';
import { ReactComponent as _Users } from './icons/regular/Users.svg';
import { ReactComponent as _VolumeDown } from './icons/regular/Volume Down.svg';
import { ReactComponent as _VolumeMute } from './icons/regular/Volume Mute.svg';
import { ReactComponent as _VolumeOff } from './icons/regular/Volume Off.svg';
import { ReactComponent as _VolumeSlash } from './icons/regular/Volume Slash.svg';
import { ReactComponent as _VolumeUp } from './icons/regular/Volume Up.svg';
import { ReactComponent as _Volume } from './icons/regular/Volume.svg';
import { ReactComponent as _WifiSlash } from './icons/regular/Wifi Slash.svg';
import { ReactComponent as _Wifi } from './icons/regular/Wifi.svg';
import { ReactComponent as _Youtube } from './icons/regular/Youtube.svg';
import { ReactComponent as _SolidBars } from './icons/solid/Bars.svg';
import { ReactComponent as _SolidInfoCircle } from './icons/solid/Info Circle.svg';
import { ReactComponent as _SolidPencilAlt } from './icons/solid/Pencil Alt.svg';
import { ReactComponent as _SolidPlay } from './icons/solid/Play.svg';
import { ReactComponent as _SolidTimescircle } from './icons/solid/Times-circle.svg';

export type SpecificIconProps = Omit<IconProps, 'svgComponent'>;

export const AddressBookIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_AddressBook} {...props} />
);

export const AdjustaltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Adjustalt} {...props} />
);

export const AdjustIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Adjust} {...props} />
);

export const AlarmClockIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_AlarmClock} {...props} />
);

export const AlarmSnoozeIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_AlarmSnooze} {...props} />
);

export const AngleDownIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_AngleDown} {...props} />
);

export const AngleLeftIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_AngleLeft} {...props} />
);

export const AngleRightIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_AngleRight} {...props} />
);

export const AngleUpIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_AngleUp} {...props} />
);

export const ArrowAltCircleDownIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ArrowAltCircleDown} {...props} />
);

export const ArrowAltCircleLeftIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ArrowAltCircleLeft} {...props} />
);

export const ArrowAltCircleRightIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ArrowAltCircleRight} {...props} />
);

export const ArrowAltCircleUpIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ArrowAltCircleUp} {...props} />
);

export const ArrowAltSquareDownIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ArrowAltSquareDown} {...props} />
);

export const ArrowAltSquareLeftIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ArrowAltSquareLeft} {...props} />
);

export const ArrowAltSquareRightIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ArrowAltSquareRight} {...props} />
);

export const ArrowAltSquareUpIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ArrowAltSquareUp} {...props} />
);

export const ArrowDownIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ArrowDown} {...props} />
);

export const ArrowFromBottomuploadIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ArrowFromBottomupload} {...props} />
);

export const ArrowLeftIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ArrowLeft} {...props} />
);

export const ArrowRightIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ArrowRight} {...props} />
);

export const ArrowToBottomdownloadIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ArrowToBottomdownload} {...props} />
);

export const ArrowUpIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ArrowUp} {...props} />
);

export const ArrowsAltHIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ArrowsAltH} {...props} />
);

export const ArrowsAltVIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ArrowsAltV} {...props} />
);

export const ArrowsAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ArrowsAlt} {...props} />
);

export const AtIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_At} {...props} />
);

export const AutomotiveIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Automotive} {...props} />
);

export const AwardIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Award} {...props} />
);

export const BadgeCheckIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_BadgeCheck} {...props} />
);

export const BadgeIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Badge} {...props} />
);

export const BanIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Ban} {...props} />
);

export const BarsIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Bars} {...props} />
);

export const BellSlashIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_BellSlash} {...props} />
);

export const BellIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Bell} {...props} />
);

export const BoltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Bolt} {...props} />
);

export const BookIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Book} {...props} />
);

export const BookmarkIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Bookmark} {...props} />
);

export const BooksIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Books} {...props} />
);

export const BoxArchiveIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_BoxArchive} {...props} />
);

export const BugIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Bug} {...props} />
);

export const BuildingIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Building} {...props} />
);

export const BullhornIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Bullhorn} {...props} />
);

export const BullseyeArrowIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_BullseyeArrow} {...props} />
);

export const BullseyeIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Bullseye} {...props} />
);

export const CalculatorIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Calculator} {...props} />
);

export const CalendarAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_CalendarAlt} {...props} />
);

export const CalendarCheckIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_CalendarCheck} {...props} />
);

export const CalendarIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Calendar} {...props} />
);

export const CameramovieIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Cameramovie} {...props} />
);

export const CameraIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Camera} {...props} />
);

export const CaretCircleDownIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_CaretCircleDown} {...props} />
);

export const CaretCircleLeftIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_CaretCircleLeft} {...props} />
);

export const CaretCircleRightIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_CaretCircleRight} {...props} />
);

export const CaretCircleUpIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_CaretCircleUp} {...props} />
);

export const ChartAreaIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ChartArea} {...props} />
);

export const ChartBarIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ChartBar} {...props} />
);

export const ChartLineDownIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ChartLineDown} {...props} />
);

export const ChartLineIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ChartLine} {...props} />
);

export const ChartPieAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ChartPieAlt} {...props} />
);

export const ChartPieIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ChartPie} {...props} />
);

export const ChartScatterIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ChartScatter} {...props} />
);

export const ChartColumnIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ChartColumn} {...props} />
);

export const CheckIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Check} {...props} />
);

export const ChevronCircleDownIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ChevronCircleDown} {...props} />
);

export const ChevronCircleLeftIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ChevronCircleLeft} {...props} />
);

export const ChevronCircleRightIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ChevronCircleRight} {...props} />
);

export const ChevronCircleUpIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ChevronCircleUp} {...props} />
);

export const ChevronDownIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ChevronDown} {...props} />
);

export const ChevronLeftIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ChevronLeft} {...props} />
);

export const ChevronRightIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ChevronRight} {...props} />
);

export const ChevronUpIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ChevronUp} {...props} />
);

export const ClipboardIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Clipboard} {...props} />
);

export const ClockIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Clock} {...props} />
);

export const CloseIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Close} {...props} />
);

export const CloudDownloadAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_CloudDownloadAlt} {...props} />
);

export const CloudUploadAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_CloudUploadAlt} {...props} />
);

export const CloudIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Cloud} {...props} />
);

export const CogIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Cog} {...props} />
);

export const CommentAltSlashIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_CommentAltSlash} {...props} />
);

export const CommentAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_CommentAlt} {...props} />
);

export const CompassIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Compass} {...props} />
);

export const CompressAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_CompressAlt} {...props} />
);

export const CompressArrowsAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_CompressArrowsAlt} {...props} />
);

export const CopyIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Copy} {...props} />
);

export const DiggingIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Digging} {...props} />
);

export const DraftingCompassIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_DraftingCompass} {...props} />
);

export const EditIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Edit} {...props} />
);

export const EllipsisvIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Ellipsisv} {...props} />
);

export const EnvelopeOpenIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_EnvelopeOpen} {...props} />
);

export const EnvelopeIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Envelope} {...props} />
);

export const ExclamationCircleIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ExclamationCircle} {...props} />
);

export const ExclamationTriangleIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ExclamationTriangle} {...props} />
);

export const ExpandAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ExpandAlt} {...props} />
);

export const ExpandArrowsIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ExpandArrows} {...props} />
);

export const EyeSlashIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_EyeSlash} {...props} />
);

export const EyeIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Eye} {...props} />
);

export const FacebookIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Facebook} {...props} />
);

export const FileAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_FileAlt} {...props} />
);

export const FileSearchIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_FileSearch} {...props} />
);

export const FileIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_File} {...props} />
);

export const FilterIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Filter} {...props} />
);

export const FingerprintIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Fingerprint} {...props} />
);

export const FlagCheckeredIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_FlagCheckered} {...props} />
);

export const FlagIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Flag} {...props} />
);

export const FolderOpenIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_FolderOpen} {...props} />
);

export const FolderIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Folder} {...props} />
);

export const GamepadAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_GamepadAlt} {...props} />
);

export const GaugeIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Gauge} {...props} />
);

export const GaugeHighIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_GaugeHigh} {...props} />
);

export const GearIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Gear} {...props} />
);

export const GiftIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Gift} {...props} />
);

export const GlobeAfricaIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_GlobeAfrica} {...props} />
);

export const GlobeAmericasIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_GlobeAmericas} {...props} />
);

export const GlobeAsiaIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_GlobeAsia} {...props} />
);

export const GlobeEuropeIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_GlobeEurope} {...props} />
);

export const GlobeIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Globe} {...props} />
);

export const GoodHabitzIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_GoodHabitz} {...props} />
);

export const GraduationCapIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_GraduationCap} {...props} />
);

export const GriplinesIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Griplines} {...props} />
);

export const HammerIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Hammer} {...props} />
);

export const HandPointDownIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_HandPointDown} {...props} />
);

export const HandPointLeftIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_HandPointLeft} {...props} />
);

export const HandPointRightIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_HandPointRight} {...props} />
);

export const HandPointUpIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_HandPointUp} {...props} />
);

export const HeartIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Heart} {...props} />
);

export const HomeIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Home} {...props} />
);

export const HourglasshalfIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Hourglasshalf} {...props} />
);

export const HourglassstartIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Hourglassstart} {...props} />
);

export const HourglassIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Hourglass} {...props} />
);

export const ImageIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Image} {...props} />
);

export const InfoCircleIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_InfoCircle} {...props} />
);

export const InstagramIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Instagram} {...props} />
);

export const KeyIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Key} {...props} />
);

export const KeyboardIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Keyboard} {...props} />
);

export const LaptopIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Laptop} {...props} />
);

export const LifeRingIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_LifeRing} {...props} />
);

export const LightbulbOnIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_LightbulbOn} {...props} />
);

export const LightbulbIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Lightbulb} {...props} />
);

export const LinkIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Link} {...props} />
);

export const LinkedinIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Linkedin} {...props} />
);

export const LoadingIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Loading} {...props} />
);

export const LockAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_LockAlt} {...props} />
);

export const LockOpenAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_LockOpenAlt} {...props} />
);

export const LockOpenIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_LockOpen} {...props} />
);

export const LockIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Lock} {...props} />
);

export const MapMarkerSlashIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_MapMarkerSlash} {...props} />
);

export const MapMarkerIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_MapMarker} {...props} />
);

export const MedalIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Medal} {...props} />
);

export const MicrophoneSlashIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_MicrophoneSlash} {...props} />
);

export const MicrophoneIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Microphone} {...props} />
);

export const MinuscircleIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Minuscircle} {...props} />
);

export const MinussquareIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Minussquare} {...props} />
);

export const MinusIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Minus} {...props} />
);

export const MobileIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Mobile} {...props} />
);

export const NewspaperIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Newspaper} {...props} />
);

export const PaperPlaneIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_PaperPlane} {...props} />
);

export const PaperclipIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Paperclip} {...props} />
);

export const PauseIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Pause} {...props} />
);

export const PenIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Pen} {...props} />
);

export const PencilAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_PencilAlt} {...props} />
);

export const PhoneLaptopIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_PhoneLaptop} {...props} />
);

export const PhotoVideoIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_PhotoVideo} {...props} />
);

export const PlayIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Play} {...props} />
);

export const PlugIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Plug} {...props} />
);

export const PlusCircleIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_PlusCircle} {...props} />
);

export const PlusSquareIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_PlusSquare} {...props} />
);

export const PlusIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Plus} {...props} />
);

export const PodcastIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Podcast} {...props} />
);

export const PrintIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Print} {...props} />
);

export const QuestionCircleIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_QuestionCircle} {...props} />
);

export const QuestionSquareIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_QuestionSquare} {...props} />
);

export const RandomIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Random} {...props} />
);

export const RedoAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_RedoAlt} {...props} />
);

export const RepeatAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_RepeatAlt} {...props} />
);

export const RocketLaunchIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_RocketLaunch} {...props} />
);

export const RocketIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Rocket} {...props} />
);

export const RssIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Rss} {...props} />
);

export const SearchMinusIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_SearchMinus} {...props} />
);

export const SearchPlusIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_SearchPlus} {...props} />
);

export const SearchIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Search} {...props} />
);

export const ShareAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ShareAlt} {...props} />
);

export const ShieldAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ShieldAlt} {...props} />
);

export const ShieldCheckIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ShieldCheck} {...props} />
);

export const ShieldIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Shield} {...props} />
);

export const SlidersHIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_SlidersH} {...props} />
);

export const SlidersVIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_SlidersV} {...props} />
);

export const SnoozeIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Snooze} {...props} />
);

export const SparklesIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Sparkles} {...props} />
);

export const StarIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Star} {...props} />
);

export const StickyNoteIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_StickyNote} {...props} />
);

export const StopwatchIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Stopwatch} {...props} />
);

export const SyncAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_SyncAlt} {...props} />
);

export const TabletIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Tablet} {...props} />
);

export const TachometerAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_TachometerAlt} {...props} />
);

export const TagIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Tag} {...props} />
);

export const TagsIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Tags} {...props} />
);

export const TextsizeIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Textsize} {...props} />
);

export const ThLargeIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ThLarge} {...props} />
);

export const ThListIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ThList} {...props} />
);

export const ThIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Th} {...props} />
);

export const ThumbsDownIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ThumbsDown} {...props} />
);

export const ThumbsUpIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_ThumbsUp} {...props} />
);

export const ThumbtackIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Thumbtack} {...props} />
);

export const TimescircleIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Timescircle} {...props} />
);

export const TimesIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Times} {...props} />
);

export const ToolboxIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Toolbox} {...props} />
);

export const ToolsIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Tools} {...props} />
);

export const TrashAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_TrashAlt} {...props} />
);

export const TrashIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Trash} {...props} />
);

export const TrophyAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_TrophyAlt} {...props} />
);

export const TrophyIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Trophy} {...props} />
);

export const TvAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_TvAlt} {...props} />
);

export const TvIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Tv} {...props} />
);

export const TwitterIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Twitter} {...props} />
);

export const UndoIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Undo} {...props} />
);

export const UserAltSlash1Icon = (props: SpecificIconProps) => (
  <Icon svgComponent={_UserAltSlash1} {...props} />
);

export const UserAltSlashIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_UserAltSlash} {...props} />
);

export const UserAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_UserAlt} {...props} />
);

export const UserCircleIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_UserCircle} {...props} />
);

export const UserCogIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_UserCog} {...props} />
);

export const UserEditIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_UserEdit} {...props} />
);

export const UserHeadsetIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_UserHeadset} {...props} />
);

export const UserLockIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_UserLock} {...props} />
);

export const UserMinusIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_UserMinus} {...props} />
);

export const UserPlusIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_UserPlus} {...props} />
);

export const UserShieldIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_UserShield} {...props} />
);

export const UserTagIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_UserTag} {...props} />
);

export const UserTimesIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_UserTimes} {...props} />
);

export const UserUnlockIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_UserUnlock} {...props} />
);

export const UserIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_User} {...props} />
);

export const UsersClassIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_UsersClass} {...props} />
);

export const UsersCogIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_UsersCog} {...props} />
);

export const UsersMedicalIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_UsersMedical} {...props} />
);

export const UsersSlashIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_UsersSlash} {...props} />
);

export const UsersIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Users} {...props} />
);

export const VolumeDownIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_VolumeDown} {...props} />
);

export const VolumeMuteIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_VolumeMute} {...props} />
);

export const VolumeOffIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_VolumeOff} {...props} />
);

export const VolumeSlashIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_VolumeSlash} {...props} />
);

export const VolumeUpIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_VolumeUp} {...props} />
);

export const VolumeIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Volume} {...props} />
);

export const WifiSlashIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_WifiSlash} {...props} />
);

export const WifiIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Wifi} {...props} />
);

export const YoutubeIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_Youtube} {...props} />
);

export const SolidBarsIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_SolidBars} {...props} />
);

export const SolidInfoCircleIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_SolidInfoCircle} {...props} />
);

export const SolidPencilAltIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_SolidPencilAlt} {...props} />
);

export const SolidPlayIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_SolidPlay} {...props} />
);

export const SolidTimescircleIcon = (props: SpecificIconProps) => (
  <Icon svgComponent={_SolidTimescircle} {...props} />
);
