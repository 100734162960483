import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Language, LanguageCode, LanguageFromAPI } from '../models/Language';
import { fetchCrowdInLanguage } from '../utils/apiRequests';
import { useSessionStorage } from './useSessionStorage';

function getLanguageCodeFromUrl() {
  const { pathname } = window.location;
  const firstPathnamePart = pathname.split('/')[1];
  const isLanguageCode = RegExp(/^[a-z]{2}-[a-z]{2}$/i).test(firstPathnamePart);
  const formattedLanguageCode = firstPathnamePart.toLowerCase();

  if (!isLanguageCode) {
    return;
  }
  return formattedLanguageCode as LanguageCode;
}

export function useCrowdInLanguage() {
  const [language, setLanguage] =
    useSessionStorage<LanguageFromAPI>('crowdInLang');
  const languageCode = getLanguageCodeFromUrl() || LanguageCode.EnGb;

  const { data, isLoading } = useQuery(
    'crowdInLanguage',
    (): Promise<LanguageFromAPI | null> => {
      const needsFetching =
        !language || language.languageregion !== languageCode;
      return needsFetching
        ? fetchCrowdInLanguage(languageCode)
        : Promise.resolve(null);
    },
  );

  useEffect(() => {
    if (data) setLanguage(data);
  }, [data, setLanguage]);

  return {
    language: {
      generic: language?.generic,
      pages: language?.pages,
      code: language?.languageregion ?? languageCode,
    } as Language,
    isLoading,
  };
}

export default useCrowdInLanguage;
