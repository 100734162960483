export const loginPageId = 'login.html';
export const notFound404Page = '404.html';
export const personalCoursesPageId = 'personal-courses.html';
export const personalInfoTestPageId = 'my-account/personal-info.html';
export const activateVoucherPageId = '/action/ActivateVoucher.html';
export const otherDevicePageId = 'otherdevice.html';
export const cookieSettingsPageId = '/cookies/cookie-settings.html';
export const contactSupportPageId = 'contact-and-support.html';
export const coursePageId = 'course.html';
export const ssoLoginPageId = 'sso-login';
export const appDownloadPageId = 'app-download.html';
export const allCoursesPageId = 'all-courses.html';
export const privacyStatementsPageId = 'privacy-statements.html';
export const generalConditionsPageId = 'general-conditions.html';
export const userAgreementsPageId = 'user-agreements.html';
export const goodscanPageId = 'goodscan.html';
export const leafletsAndIconsPageId = 'dashboard/resources-icons-leaflets.html';
