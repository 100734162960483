import tw, { css, styled } from 'twin.macro';

export const AppAdvertInner = styled.div(() => [
  tw`flex flex-col gap-4 items-center text-center py-5 px-5`,
  css`
    .large-icon {
      height: 40px;
      width: auto;
    }
    span {
      color: rgb(10, 255, 186);
    }
  `,
]);
