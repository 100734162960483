import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import './error-capturing';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import { EventTracker } from './analytics';
import { initializeLaunchDarkly } from './third-parties/launchdarkly';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
    },
  },
});

let rootElement = document.getElementById('root');

if (!rootElement) {
  document.body.innerHTML = '<div id="root"></div>';
  rootElement = document.getElementById('root');
}

(async () => {
  const LDProvider = await initializeLaunchDarkly();

  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <QueryClientProvider client={queryClient}>
          <Router>
            <ErrorBoundary>
              <EventTracker>
                <App />
              </EventTracker>
            </ErrorBoundary>
          </Router>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </LDProvider>
    </React.StrictMode>,
    rootElement,
  );
})();
