import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { WebSession } from '../vanilla-js/websession';
import { fetchWebsession } from '../utils/apiRequests';

export let persistedwebsession: WebSession | null = JSON.parse(
  localStorage.getItem('websession') ?? 'null',
);

export function useWebsession<T extends keyof WebSession>(
  key: T,
): [WebSession[T] | undefined, boolean, () => Promise<void>] {
  const { isLoading, refetch, isError } = useQuery(
    'websession',
    (): Promise<WebSession | null> | Promise<null> => {
      // eslint-disable-next-line promise/prefer-await-to-then
      return fetchWebsession().then((websession) => {
        const minimisedWebsession = { ...websession, ModuleImageList: [] };

        localStorage.setItem('websession', JSON.stringify(minimisedWebsession));
        persistedwebsession = websession;

        return websession;
      });
    },
    {
      enabled: false,
      retry: 3,
      retryDelay: (attempt) =>
        Math.min(attempt > 1 ? 2 ** attempt * 1000 : 3000, 90 * 1000),
    },
  );

  useEffect(() => {
    if (isError) {
      sessionStorage.removeItem('token');
      localStorage.removeItem('token');
      localStorage.removeItem('websession');
      persistedwebsession = null;
    }
  }, [isError]);

  useEffect(() => {
    if (!persistedwebsession && !isLoading) {
      refetch();
    }
  }, [isLoading, refetch]);

  return [
    persistedwebsession ? persistedwebsession[key] : undefined,
    isLoading,
    () =>
      new Promise(async (resolve) => {
        await refetch();

        resolve(void 0);
      }),
  ];
}
