import type { MouseEvent } from 'react';
import { track } from './index';

const getButton = (target: HTMLElement) => {
  if (target.tagName === 'BUTTON') {
    return target;
  }

  let parent = target.parentElement;
  let foundButton = false;
  while (parent && !foundButton) {
    if (parent?.tagName === 'BUTTON') {
      foundButton = true;
    } else {
      parent = parent.parentElement;
    }
  }

  return parent;
};

const onButtonClickHandler = (event: MouseEvent) => {
  const button = getButton(event.target as HTMLElement);

  if (button) {
    track('Click', {
      buttonText: button.innerText,
      label: button.dataset.trackLabel,
    });
  }
};

export default onButtonClickHandler;
