import tw, { css, styled } from 'twin.macro';

type ContentContainerProps = {
  minHeight?: string;
};

export const ContentContainer = styled.div<ContentContainerProps>(
  ({ minHeight }) => [
    tw`leading-6 px-8 pt-5`,
    minHeight &&
      css`
        min-height: ${minHeight};
        line-height: 28px;
        font-size: 18px;
      `,
  ],
);
