import React, { ReactNode } from 'react';
import tw from 'twin.macro';

export const Heading1 = tw.h1`font-bold text-5xl md:text-6xl lg:text-6xl xl:text-7xl 2xl:text-7xl`;
export const Heading2 = tw.h2`font-bold text-4xl md:text-5xl lg:text-5xl xl:text-6xl 2xl:text-6xl`;
export const Heading3 = tw.h3`font-bold text-3xl md:text-4xl lg:text-4xl xl:text-5xl 2xl:text-5xl`;
export const Heading4 = tw.h4`font-bold text-2xl md:text-3xl lg:text-3xl xl:text-4xl 2xl:text-4xl`;
export const Heading5 = tw.h5`font-bold text-xl md:text-2xl lg:text-2xl xl:text-3xl 2xl:text-3xl`;
export const Heading6 = tw.h6`font-bold text-xl md:text-xl lg:text-xl xl:text-2xl 2xl:text-2xl`;
const StyledH7 = tw.span`font-bold text-lg md:text-xl lg:text-xl xl:text-xl 2xl:text-xl block`;

type HeadingProps = {
  children: ReactNode;
  className?: string;
};
export const Heading7 = ({ children, className, ...props }: HeadingProps) => {
  return (
    <StyledH7 {...props} className={className} role="heading" aria-level={7}>
      {children}
    </StyledH7>
  );
};
export const StyledH8 = tw.span`font-bold text-lg md:text-lg lg:text-lg xl:text-lg 2xl:text-lg block`;
export const Heading8 = ({ children, className, ...props }: HeadingProps) => {
  return (
    <StyledH8 {...props} className={className} role="heading" aria-level={8}>
      {children}
    </StyledH8>
  );
};
