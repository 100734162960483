import React, { useEffect } from 'react';

import * as Styled from './ModalStyle';
import { track } from '../../../analytics';

type Props = {
  id?: string;
  width?: string;
  height?: string;
  isOpen: boolean;
  trackLabel?: string;
  style?: object;
  children?: React.ReactNode;
  bgColor?: 'purple';
  role?: string;
  onClose?: () => void;
  hasHeader?: boolean;
};

export const Modal = ({
  id,
  width,
  height,
  isOpen,
  trackLabel,
  style,
  children,
  bgColor,
  hasHeader = true,
  role,
  onClose,
}: Props) => {
  useEffect(() => {
    if (isOpen && trackLabel) {
      track('Open Modal', { label: trackLabel });
    }
  }, [isOpen, trackLabel]);

  const dismiss = () => {
    if (trackLabel) {
      track('Dismiss Modal', { label: trackLabel });
    }
    onClose?.();
  };

  return isOpen ? (
    <Styled.OverlayDiv id={id} bgColor={bgColor} style={style}>
      <Styled.DialogDiv
        width={width}
        height={height}
        role={role}
        aria-modal="true"
      >
        {hasHeader && (
          <Styled.CloseButton onClick={dismiss}>
            <Styled.TimesIcon />
          </Styled.CloseButton>
        )}
        <Styled.ContentContainer>{children}</Styled.ContentContainer>
      </Styled.DialogDiv>
    </Styled.OverlayDiv>
  ) : null;
};
