import * as React from 'react';
import { createGlobalStyle } from 'styled-components';
import tw, { GlobalStyles as GlobalStylesTwinMacro } from 'twin.macro';

import '../../css/vendor/FA5/css/all.min.css';

const AppGlobalStyles = createGlobalStyle`
  body {
    ${tw`antialiased`}
  }

  a {
    ${tw`underline`}
  }
`;

export const GlobalStyles = () => (
  <>
    <GlobalStylesTwinMacro />
    <AppGlobalStyles />
  </>
);
