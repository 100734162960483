const HTML = {};

//DIV
export function CreateDIV(Owner, CSSClass, CSSText, ID, InnerHTML, OnClick) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (CSSText === void 0) {
    CSSText = null;
  }
  if (InnerHTML === void 0) {
    InnerHTML = null;
  }
  if (OnClick === void 0) {
    OnClick = null;
  }
  if (ID === void 0) {
    ID = null;
  }

  var i = createElement('div', Owner, CSSClass, InnerHTML, OnClick);
  if (ID !== null) {
    i.id = ID;
  }
  if (CSSText !== null) {
    i.style.cssText = CSSText;
  }

  return i;
}
HTML.CreateDIV = CreateDIV;

//SPAN
export function CreateSPAN(Owner, CSSClass, ID, InnerHTML, OnClick) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (InnerHTML === void 0) {
    InnerHTML = null;
  }
  if (OnClick === void 0) {
    OnClick = null;
  }
  if (ID === void 0) {
    ID = null;
  }

  var i = createElement('span', Owner, CSSClass, InnerHTML, OnClick);
  if (ID !== null) {
    i.id = ID;
  }

  return i;
}
HTML.CreateSPAN = CreateSPAN;

//UL
export function CreateUL(Owner, CSSClass) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }

  return createElement('ul', Owner, CSSClass);
}
HTML.CreateUL = CreateUL;

//LI
export function CreateLI(Owner, CSSClass, InnerHTML, OnClick) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (InnerHTML === void 0) {
    InnerHTML = null;
  }
  if (OnClick === void 0) {
    OnClick = null;
  }

  return createElement('li', Owner, CSSClass, InnerHTML, OnClick);
}
HTML.CreateLI = CreateLI;

//Hx
export function CreateHeading(
  Owner,
  Number,
  CSSClass,
  InnerHTML,
  DataLanguageHTML
) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (InnerHTML === void 0) {
    InnerHTML = null;
  }
  if (DataLanguageHTML === void 0) {
    DataLanguageHTML = null;
  }

  var i = createElement('h' + Number, Owner, CSSClass, InnerHTML, null);
  if (DataLanguageHTML !== null)
    i.setAttribute('data-language-html', DataLanguageHTML);

  return i;
}
HTML.CreateHeading = CreateHeading;

//P
export function CreateP(Owner, CSSClass, InnerHTML, DataLanguageHTML) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (InnerHTML === void 0) {
    InnerHTML = null;
  }
  if (DataLanguageHTML === void 0) {
    DataLanguageHTML = null;
  }

  var i = createElement('p', Owner, CSSClass, InnerHTML, null);
  if (DataLanguageHTML !== null)
    i.setAttribute('data-language-html', DataLanguageHTML);

  return i;
}
HTML.CreateP = CreateP;

//HR
export function CreateHR(Owner, CSSClass) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }

  return createElement('hr', Owner, CSSClass);
}
HTML.CreateHR = CreateHR;

//BR
export function CreateBR(Owner, CSSClass) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }

  return createElement('br', Owner, CSSClass);
}
HTML.CreateBR = CreateBR;

//A
export function CreateAnchor(
  Owner,
  CSSClass,
  InnerHTML,
  OnClick,
  Href,
  Target
) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (InnerHTML === void 0) {
    InnerHTML = null;
  }
  if (OnClick === void 0) {
    OnClick = null;
  }
  if (Href === void 0) {
    Href = null;
  }
  if (Target === void 0) {
    Target = null;
  }

  var i = createElement('a', Owner, CSSClass, InnerHTML, OnClick);
  if (Href !== null) i.href = Href;
  if (Target !== null) i.target = Target;

  return i;
}
HTML.CreateAnchor = CreateAnchor;

//IMG
export function CreateImage(Owner, Src, CSSClass, OnClick, Alt, CSSText) {
  if (Src === void 0) {
    Src = null;
  }
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (OnClick === void 0) {
    OnClick = null;
  }
  if (Alt === void 0) {
    Alt = null;
  }
  if (CSSText === void 0) {
    CSSText = null;
  }

  var i = createElement('img', Owner, CSSClass, '', OnClick);
  i.ondragstart = function (e) {
    return false;
  };
  if (Src !== null) i.src = Src;
  if (Alt !== null) i.alt = Alt;
  if (CSSText !== null) i.style.cssText = CSSText;

  return i;
}
HTML.CreateImage = CreateImage;

//VIDEO
export function CreateVideo(
  Owner,
  Src,
  CSSClass,
  Loop,
  Muted,
  AutoPlay,
  PlaysInline,
  CSSText
) {
  Src = Src || null;
  CSSClass = CSSClass || null;
  Loop = Loop || false;
  Muted = Muted || false;
  AutoPlay = AutoPlay || false;
  PlaysInline = PlaysInline || true;
  CSSText = CSSText || null;

  var v = HTML.createElement('video', Owner, CSSClass);

  if (AutoPlay) {
    v.setAttribute('autoplay', '');
  }
  if (PlaysInline) {
    v.setAttribute('playsinline', '');
  }
  if (Loop) {
    v.setAttribute('loop', '');
  }
  if (CSSText !== null) {
    v.style.cssText = CSSText;
  }

  v.onloadeddata = function () {
    if (Muted) {
      v.setAttribute('muted', '');
      this.muted = true;
    }
    if (Muted) {
      v.setAttribute('defaultMuted', '');
      this.defaultMuted = true;
    }
  };

  var s = document.createElement('source');
  s.setAttribute('src', Src);
  v.appendChild(s);

  return v;
}
HTML.CreateVideo = CreateVideo;

export function SetImage(Image, Src) {
  Image.src = Src;
}
HTML.SetImage = SetImage;

//LABEL
export function CreateLabel(
  Owner,
  CSSClass,
  For,
  InitialValue,
  DataLanguageHTML
) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (For === void 0) {
    For = null;
  }
  if (InitialValue === void 0) {
    InitialValue = null;
  }
  if (DataLanguageHTML === void 0) {
    DataLanguageHTML = null;
  }

  var i = createElement('label', Owner, CSSClass);
  if (For !== null) i.setAttribute('for', For);
  if (DataLanguageHTML !== null)
    i.setAttribute('data-language-html', DataLanguageHTML);
  if (InitialValue !== null) i.innerHTML = InitialValue;

  return i;
}
HTML.CreateLabel = CreateLabel;

//BLOCKQUOTE
export function CreateBlockQuote(Owner, CSSClass, InitialValue) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (InitialValue === void 0) {
    InitialValue = null;
  }

  var i = createElement('blockquote', Owner, CSSClass);
  if (InitialValue !== null) i.innerHTML = InitialValue;

  return i;
}
HTML.CreateBlockQuote = CreateBlockQuote;

//EMAIL

//INPUT HIDDEN
export function CreateInputHidden(Owner, Name, Value, DataLanguageValue) {
  if (DataLanguageValue === void 0) {
    DataLanguageValue = null;
  }

  var i = createInput(Owner, null, null, 'hidden', null, Value, Name);
  if (DataLanguageValue !== null)
    i.setAttribute('data-language-value', DataLanguageValue);

  return i;
}
HTML.CreateInputHidden = CreateInputHidden;

//INPUT TEXT
export function CreateInputText(
  Owner,
  CSSClass,
  OnClick,
  OnChange,
  Placeholder,
  InitialValue,
  Name,
  DataLanguagePlaceholder
) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (OnClick === void 0) {
    OnClick = null;
  }
  if (OnChange === void 0) {
    OnChange = null;
  }
  if (Placeholder === void 0) {
    Placeholder = null;
  }
  if (Name === void 0) {
    Name = null;
  }
  if (DataLanguagePlaceholder === void 0) {
    DataLanguagePlaceholder = null;
  }

  var i = createInput(
    Owner,
    CSSClass,
    OnClick,
    'text',
    Placeholder,
    InitialValue,
    Name
  );
  if (OnChange !== null) i.onchange = OnChange;
  if (OnChange !== null) i.onkeyup = OnChange;
  i.setAttribute('autocorrect', 'off');
  i.setAttribute('autocapitalize', 'off');
  if (DataLanguagePlaceholder !== null)
    i.setAttribute('data-language-placeholder', DataLanguagePlaceholder);

  return i;
}
HTML.CreateInputText = CreateInputText;

//INPUT SEARCH
export function CreateInputSearch(
  Owner,
  CSSClass,
  OnClick,
  OnChange,
  Placeholder,
  InitialValue,
  Name,
  DataLanguagePlaceholder
) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (OnClick === void 0) {
    OnClick = null;
  }
  if (OnChange === void 0) {
    OnChange = null;
  }
  if (Placeholder === void 0) {
    Placeholder = null;
  }
  if (Name === void 0) {
    Name = null;
  }
  if (DataLanguagePlaceholder === void 0) {
    DataLanguagePlaceholder = null;
  }

  var i = createInput(
    Owner,
    CSSClass,
    OnClick,
    'search',
    Placeholder,
    InitialValue,
    Name
  );
  if (OnChange !== null) i.onchange = OnChange;
  if (OnChange !== null) i.onkeyup = OnChange;
  i.setAttribute('autocorrect', 'off');
  i.setAttribute('autocapitalize', 'off');
  if (DataLanguagePlaceholder !== null)
    i.setAttribute('data-language-placeholder', DataLanguagePlaceholder);

  return i;
}
HTML.CreateInputSearch = CreateInputSearch;

//INPUT NUMBER
export function CreateInputNumber(
  Owner,
  CSSClass,
  OnClick,
  OnChange,
  Placeholder,
  InitialValue,
  Min,
  Max,
  Name,
  DataLanguagePlaceholder
) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (OnClick === void 0) {
    OnClick = null;
  }
  if (OnChange === void 0) {
    OnChange = null;
  }
  if (Placeholder === void 0) {
    Placeholder = null;
  }
  if (Name === void 0) {
    Name = null;
  }
  if (Min === void 0) {
    Min = null;
  }
  if (Max === void 0) {
    Max = null;
  }
  if (DataLanguagePlaceholder === void 0) {
    DataLanguagePlaceholder = null;
  }

  var i = createInput(
    Owner,
    CSSClass,
    OnClick,
    'number',
    Placeholder,
    InitialValue,
    Name
  );
  //i.setAttribute("autocorrect", "off");
  //i.setAttribute("autocapitalize", "off");
  if (OnChange !== null) i.onchange = OnChange;
  if (OnChange !== null) i.onkeyup = OnChange;
  if (Min !== null) i.setAttribute('min', Min);
  if (Max !== null) i.setAttribute('max', Max);
  if (DataLanguagePlaceholder !== null)
    i.setAttribute('data-language-placeholder', DataLanguagePlaceholder);

  return i;
}
HTML.CreateInputNumber = CreateInputNumber;

//INPUT RANGE
export function CreateInputRange(
  Owner,
  CSSClass,
  OnInput,
  OnChange,
  Placeholder,
  InitialValue,
  Min,
  Max,
  Name,
  DataLanguagePlaceholder
) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (OnInput === void 0) {
    OnInput = null;
  }
  if (OnChange === void 0) {
    OnChange = null;
  }
  if (Placeholder === void 0) {
    Placeholder = null;
  }
  if (Name === void 0) {
    Name = null;
  }
  if (Min === void 0) {
    Min = null;
  }
  if (Max === void 0) {
    Max = null;
  }
  if (DataLanguagePlaceholder === void 0) {
    DataLanguagePlaceholder = null;
  }

  var i = createInput(
    Owner,
    CSSClass,
    null,
    'range',
    Placeholder,
    InitialValue,
    Name
  );
  //i.setAttribute("autocorrect", "off");
  //i.setAttribute("autocapitalize", "off");
  if (OnChange !== null) i.onchange = OnChange;
  if (OnInput !== null) i.oninput = OnInput;
  if (Min !== null) i.setAttribute('min', Min);
  if (Max !== null) i.setAttribute('max', Max);
  if (DataLanguagePlaceholder !== null)
    i.setAttribute('data-language-placeholder', DataLanguagePlaceholder);

  return i;
}
HTML.CreateInputRange = CreateInputRange;

//INPUT PASSWORD
export function CreateInputPassword(
  Owner,
  CSSClass,
  OnClick,
  Placeholder,
  InitialValue,
  Name,
  DataLanguagePlaceholder
) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (OnClick === void 0) {
    OnClick = null;
  }
  if (Placeholder === void 0) {
    Placeholder = null;
  }
  if (InitialValue === void 0) {
    InitialValue = null;
  }
  if (Name === void 0) {
    Name = null;
  }
  if (DataLanguagePlaceholder === void 0) {
    DataLanguagePlaceholder = null;
  }

  var i = createInput(
    Owner,
    CSSClass + ' passwordInput',
    OnClick,
    'password',
    Placeholder,
    InitialValue,
    Name
  );
  if (DataLanguagePlaceholder !== null)
    i.setAttribute('data-language-placeholder', DataLanguagePlaceholder);

  return i;
}
HTML.CreateInputPassword = CreateInputPassword;

//INPUT EMAIL
export function CreateInputEmail(
  Owner,
  CSSClass,
  OnClick,
  Placeholder,
  InitialValue,
  Name,
  DataLanguagePlaceholder
) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (OnClick === void 0) {
    OnClick = null;
  }
  if (Placeholder === void 0) {
    Placeholder = null;
  }
  if (Name === void 0) {
    Name = null;
  }
  if (DataLanguagePlaceholder === void 0) {
    DataLanguagePlaceholder = null;
  }

  var i = createInput(
    Owner,
    CSSClass,
    OnClick,
    'email',
    Placeholder,
    InitialValue,
    Name
  );
  i.setAttribute('autocorrect', 'off');
  i.setAttribute('autocapitalize', 'off');
  if (DataLanguagePlaceholder !== null)
    i.setAttribute('data-language-placeholder', DataLanguagePlaceholder);

  return i;
}
HTML.CreateInputEmail = CreateInputEmail;

//INPUT CHECKBOX
export function CreateInputCheck(
  Owner,
  CSSClass,
  OnClick,
  Placeholder,
  InitialValue,
  Name,
  ID
) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (OnClick === void 0) {
    OnClick = null;
  }
  if (Placeholder === void 0) {
    Placeholder = null;
  }
  if (InitialValue === void 0) {
    InitialValue = null;
  }
  if (Name === void 0) {
    Name = null;
  }
  if (ID === void 0) {
    ID = null;
  }

  var i = createInput(
    Owner,
    CSSClass,
    OnClick,
    'checkbox',
    Placeholder,
    InitialValue,
    Name
  );
  if (ID !== null) i.id = ID;

  return i;
}
HTML.CreateInputCheck = CreateInputCheck;

//INPUT RADIO
export function CreateInputRadio(
  Owner,
  CSSClass,
  OnClick,
  Placeholder,
  InitialValue,
  Name,
  ID
) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (OnClick === void 0) {
    OnClick = null;
  }
  if (Placeholder === void 0) {
    Placeholder = null;
  }
  if (InitialValue === void 0) {
    InitialValue = null;
  }
  if (Name === void 0) {
    Name = null;
  }
  if (ID === void 0) {
    ID = null;
  }

  var i = createInput(
    Owner,
    CSSClass,
    OnClick,
    'radio',
    Placeholder,
    InitialValue,
    Name
  );
  if (ID !== null) i.id = ID;

  return i;
}
HTML.CreateInputRadio = CreateInputRadio;

//INPUT FILE
export function CreateInputFile(
  Owner,
  CSSClass,
  OnClick,
  Placeholder,
  InitialValue,
  Name
) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (OnClick === void 0) {
    OnClick = null;
  }
  if (Placeholder === void 0) {
    Placeholder = null;
  }
  if (InitialValue === void 0) {
    InitialValue = null;
  }
  if (Name === void 0) {
    Name = null;
  }

  return createInput(
    Owner,
    CSSClass,
    OnClick,
    'file',
    Placeholder,
    InitialValue,
    Name
  );
}
HTML.CreateInputFile = CreateInputFile;

//INPUT BUTTON
export function CreateInputButton(
  Owner,
  CSSClass,
  OnClick,
  InitialValue,
  Name,
  DataLanguageValue
) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (OnClick === void 0) {
    OnClick = null;
  }
  if (InitialValue === void 0) {
    InitialValue = null;
  }
  if (Name === void 0) {
    Name = null;
  }
  if (DataLanguageValue === void 0) {
    DataLanguageValue = null;
  }

  var i = createInput(
    Owner,
    CSSClass,
    OnClick,
    'button',
    '',
    InitialValue,
    Name
  );
  if (DataLanguageValue !== null)
    i.setAttribute('data-language-value', DataLanguageValue);

  return i;
}
HTML.CreateInputButton = CreateInputButton;

//INPUT SUBMIT BUTTON
export function CreateSubmitButton(
  Owner,
  CSSClass,
  OnClick,
  InitialValue,
  Name,
  DataLanguageValue
) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (OnClick === void 0) {
    OnClick = null;
  }
  if (InitialValue === void 0) {
    InitialValue = null;
  }
  if (Name === void 0) {
    Name = null;
  }
  if (DataLanguageValue === void 0) {
    DataLanguageValue = null;
  }

  var i = createInput(
    Owner,
    CSSClass,
    OnClick,
    'submit',
    '',
    InitialValue,
    Name
  );
  if (DataLanguageValue !== null)
    i.setAttribute('data-language-value', DataLanguageValue);

  return i;
}
HTML.CreateSubmitButton = CreateSubmitButton;

//SELECT
export function CreateSelect(
  Owner,
  CSSClass,
  InnerHTML,
  OnClick,
  OnChange,
  Name,
  ID
) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (InnerHTML === void 0) {
    InnerHTML = null;
  }
  if (OnClick === void 0) {
    OnClick = null;
  }
  if (OnChange === void 0) {
    OnChange = null;
  }
  if (Name === void 0) {
    Name = null;
  }
  if (ID === void 0) {
    ID = null;
  }

  var i = createElement('select', Owner, CSSClass, InnerHTML, OnClick);
  if (OnChange !== null) i.onchange = OnChange;
  //if (OnChange != null)
  //    i.onclick = OnChange;
  if (Name !== null) i.name = Name;
  if (ID !== null) i.id = ID;

  return i;
}
HTML.CreateSelect = CreateSelect;

//OPTION
export function CreateOption(
  Owner,
  Value,
  Label,
  CSSClass,
  Selected,
  Disabled
) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (Selected === void 0) {
    Selected = null;
  }
  if (Disabled === void 0) {
    Disabled = false;
  }

  var i = createElement('option', Owner, CSSClass);
  if (Value !== null) i.value = Value;
  if (Label !== null) i.label = Label;
  if (Label !== null) i.innerHTML = Label;
  if (Selected) i.selected = true;
  i.disabled = Disabled;

  return i;
}
HTML.CreateOption = CreateOption;

//TEXT AREA
export function CreateTextArea(
  Owner,
  CSSClass,
  OnClick,
  OnKeyUp,
  Name,
  ID,
  Placeholder,
  Value,
  DataLanguagePlaceholder
) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (OnClick === void 0) {
    OnClick = null;
  }
  if (OnKeyUp === void 0) {
    OnKeyUp = null;
  }
  if (Name === void 0) {
    Name = null;
  }
  if (ID === void 0) {
    ID = null;
  }
  if (DataLanguagePlaceholder === void 0) {
    DataLanguagePlaceholder = null;
  }
  if (Placeholder === void 0) {
    Placeholder = null;
  }
  if (Value === void 0) {
    Value = null;
  }

  var i = createElement('textarea', Owner, CSSClass, '', OnClick);
  if (OnKeyUp !== null) i.onkeyup = OnKeyUp;
  if (Name !== null) i.name = Name;
  if (ID !== null) i.id = ID;
  if (DataLanguagePlaceholder !== null)
    i.setAttribute('data-language-placeholder', DataLanguagePlaceholder);
  if (Placeholder !== null) i.placeholder = Placeholder;
  if (Value !== null) i.value = Value;

  return i;
}
HTML.CreateTextArea = CreateTextArea;

//IFRAME
export function CreateIFrame(Owner, CSSClass, SRC, ID) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (SRC === void 0) {
    SRC = null;
  }

  var e = createElement('iframe', Owner, CSSClass, null, null, null, ID);
  e.frameBorder = '0';
  e.setAttribute('allowfullscreen', '');
  e.setAttribute('webkitallowfullscreen', '');
  e.setAttribute('mozallowfullscreen', '');
  e.setAttribute('allow', 'autoplay; microphone');

  if (SRC !== null) e.src = SRC;
  if (ID !== null) e.id = ID;

  return e;
}
HTML.CreateIFrame = CreateIFrame;

//FORM
export function CreateForm(Owner, Method, Action, Name) {
  if (Method === void 0) {
    Method = null;
  }
  if (Action === void 0) {
    Action = null;
  }
  if (Name === void 0) {
    Name = null;
  }

  var f = createElement('form', Owner);
  if (Action !== null) f.action = Action;
  if (Method !== null) f.method = Method;
  if (Name !== null) f.name = Name;

  return f;
}
HTML.CreateForm = CreateForm;

// ............
HTML.supportsTouch = 'createTouch' in document;

export function createElement(
  ElementType,
  Owner,
  CSSClass,
  InnerHTML,
  OnClick,
  ID,
  CSSText
) {
  var element;
  element = document.createElement(ElementType);
  if (CSSClass !== null && CSSClass !== undefined) element.className = CSSClass;
  if (InnerHTML !== null && InnerHTML !== undefined)
    element.innerHTML = InnerHTML;
  if (OnClick !== null && OnClick !== undefined) {
    new ClickHandler(element, OnClick);
  }
  if (Owner !== null && Owner !== undefined) Owner.appendChild(element);
  if (ID !== null && ID !== undefined) element.id = ID;
  if (CSSText !== null && CSSText !== undefined)
    element.style.cssText = CSSText;
  return element;
}
HTML.createElement = createElement;

export function createInput(
  Owner,
  CSSClass,
  OnClick,
  Type,
  Placeholder,
  InitialValue,
  Name
) {
  var i = createElement('input', Owner, CSSClass, '', OnClick);
  i.type = Type;
  if (Placeholder !== null && Placeholder !== undefined)
    i.placeholder = Placeholder;
  if (InitialValue !== null && InitialValue !== undefined)
    i.value = InitialValue;
  if (Name !== null && Name !== undefined) i.name = Name;
  return i;
}

export function CreateGHCCVideo(
  Owner,
  Src,
  Captions,
  CSSClass,
  Loop,
  Muted,
  AutoPlay,
  PlaysInline,
  CSSText,
  Callback,
  posterImageFileName
) {
  Src = Src || null;
  CSSClass = CSSClass || null;
  Loop = Loop || false;
  Muted = Muted || false;
  AutoPlay = AutoPlay || false;
  PlaysInline = PlaysInline || true;
  CSSText = CSSText || null;
  Captions = Captions || null;

  var v = HTML.createElement('video', Owner, CSSClass);

  v.src = Src;
  v.controlsList = 'nodownload';
  v.setAttribute('preload', 'none');

  if (posterImageFileName) {
    v.setAttribute('poster', posterImageFileName);
  }

  if (AutoPlay) {
    v.setAttribute('autoplay', '');
  }
  if (PlaysInline) {
    v.setAttribute('playsinline', '');
  }
  if (Loop) {
    v.setAttribute('loop', '');
  }
  if (CSSText !== null) {
    v.style.cssText = CSSText;
  }

  v.onloadeddata = function () {
    if (Muted) {
      v.setAttribute('muted', '');
      this.muted = true;
    }
    if (Muted) {
      v.setAttribute('defaultMuted', '');
      this.defaultMuted = true;
    }
    if (Callback) {
      Callback();
    }
  };

  if (Captions !== null) {
    // Create captions track element
    var trackElement = HTML.createElement('track', v, null);
    trackElement.src = Captions;
    // trackElement.default = true; // Set track as default.
    trackElement.kind = 'captions';
    trackElement.label = 'Nederlands';
    trackElement.id = 'CaptionsNL';
  }

  return v;
}
HTML.CreateGHCCVideo = CreateGHCCVideo;

export function CreateCanvas(Owner, CSSClass, CSSText) {
  if (CSSClass === void 0) {
    CSSClass = null;
  }
  if (CSSText === void 0) {
    CSSText = null;
  }

  var i = HTML.createElement('canvas', Owner, CSSClass);
  if (CSSText !== null) {
    i.style.cssText = CSSText;
  }

  return i;
}
HTML.CreateCanvas = CreateCanvas;

// WRAPPED ICON
export function CreateWrappedIcon(
  Owner,
  WrapperDIVClassName,
  CSSText,
  IconClassName,
  ID,
  OnClick
) {
  var div, i;
  if (WrapperDIVClassName === void 0) {
    WrapperDIVClassName = null;
  }
  if (CSSText === void 0) {
    CSSText = null;
  }
  if (IconClassName === void 0) {
    IconClassName = null;
  }
  if (OnClick === void 0) {
    OnClick = null;
  }
  if (ID === void 0) {
    ID = null;
  }
  // create wrapper div
  div = createElement('div', Owner, WrapperDIVClassName, null, OnClick); // ElementType, Owner, CSSClass, InnerHTML, OnClick
  if (ID !== null) {
    div.id = ID;
  }
  if (CSSText !== null) {
    div.style.cssText = CSSText;
  }
  // create icon (i)
  createElement('i', div, IconClassName);
  return div;
}
HTML.CreateWrappedIcon = CreateWrappedIcon;

// ...............
(function (MouseActionType) {
  MouseActionType[(MouseActionType['Click'] = 0)] = 'Click';
  MouseActionType[(MouseActionType['Activate'] = 1)] = 'Activate';
  MouseActionType[(MouseActionType['DeActivate'] = 2)] = 'DeActivate';
  MouseActionType[(MouseActionType['CheckAllowPropagation'] = 3)] =
    'CheckAllowPropagation';
  MouseActionType[(MouseActionType['Down'] = 4)] = 'Down';
  MouseActionType[(MouseActionType['Up'] = 5)] = 'Up';
})(HTML.MouseActionType || (HTML.MouseActionType = {}));
var MouseActionType = HTML.MouseActionType;
export { MouseActionType };

const ClickHandler = (function () {
  function ClickHandler(element, OnClick, CancelOnMove) {
    if (CancelOnMove === void 0) {
      CancelOnMove = true;
    }
    this._mouseIn = false;
    this._mouseDown = false;
    this.startX = 0;
    this.startY = 0;
    this.uplistener = null;
    this.onClick = OnClick;
    this.owner = element;
    this.cancelOnMove = CancelOnMove;
    var _t = this;

    element.addEventListener(
      'mouseenter',
      function (e) {
        _t._mouseIn = true;
        OnClick(MouseActionType.Activate, false);
      },
      false
    );
    element.addEventListener(
      'mousemove',
      function (e) {
        if (_t._mouseDown) {
          var moveX = e.clientX;
          var moveY = e.clientY;
          if (_t.cancelOnMove) {
            if (
              Math.abs(moveX - _t.startX) > 40 ||
              Math.abs(moveY - _t.startY) > 40
            ) {
              _t._mouseDown = false;
              window.removeEventListener('mouseup', _t.uplistener, false);
              clearTimeout(_t.timeout);
              _t.onClick(MouseActionType.Up, false);
            }
          }
        }
      },
      false
    );
    element.addEventListener(
      'mouseleave',
      function (e) {
        _t._mouseIn = false;
        OnClick(MouseActionType.DeActivate, false);
      },
      false
    );
    element.addEventListener(
      'click',
      function (e) {
        _t._mouseIn = true;
        e.stopPropagation();
        OnClick(MouseActionType.Click, false);
      },
      false
    );

    this.uplistener = function (e) {
      window.removeEventListener('mouseup', _t.uplistener, false);
      if (_t._mouseDown) {
        _t._mouseDown = false;
        if (_t._mouseIn) {
          _t.onClick(MouseActionType.Click, false);
        }
        _t.onClick(MouseActionType.Up, false);
        if (!_t.onClick(MouseActionType.CheckAllowPropagation, false)) {
          if (e.stopPropagation) e.stopPropagation();
          return false;
        }
      }
    };
    //}
  }
  ClickHandler.prototype.removeTapHandler = function () {
    clearTimeout(this.timeout);
    this._mouseDown = false;
    this.onClick(MouseActionType.DeActivate, true);
  };
  return ClickHandler;
})();
HTML.ClickHandler = ClickHandler;

/** Compatitibility */
window.HTML = HTML;
