import tw, { styled, css } from 'twin.macro';
import { TimesIcon as DefautlTimesIcon } from '../../Icon/icons.generated';

type OverlayProps = {
  bgColor?: string;
  style?: object;
};

export const OverlayDiv = styled.div<OverlayProps>(({ bgColor }) => [
  tw`fixed flex items-center justify-center inset-0 overflow-y-auto h-full w-full z-[9999]`,
  bgColor === 'purple'
    ? tw`bg-primary-default bg-opacity-60`
    : tw`bg-grey-100 bg-opacity-40`,
]);

type DialogProps = {
  width?: string;
  height?: string;
};

export const DialogDiv = styled.div<DialogProps>(({ width, height }) => [
  tw`flex flex-col relative rounded-medium bg-grey-0 border-none mx-5`,
  // When mobile design is ready, width and height won't be necessary anymore
  // For desktop users, predefined width and height is used
  width &&
    css`
      width: ${width};
    `,
  height &&
    css`
      height: ${height};
    `,
  css`
    max-height: 95vh;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    .modal-text {
      max-height: 70vh;
      overflow: scroll;
      position: relative;
      &::-webkit-scrollbar {
        display: none;
      }

      .overlay {
        position: sticky;
        bottom: 80px;
        width: 100%;
        height: 40px;
        background: linear-gradient(to bottom, transparent 0%, #ffffff 100%);
      }

      .actionfixed {
        position: sticky;
        bottom: 0;
        width: 100%;
        background: white;

        button {
          margin: 0 10px;
        }
      }
    }

    @media (max-width: 608px) {
      ${tw`w-11/12 max-w-xl`}
    }
  `,
]);

export const TimesIcon = styled(DefautlTimesIcon)(() => [
  tw`w-auto h-auto text-grey-60 hover:text-grey-80 text-3xl `,
]);

export const ContentContainer = tw.div`flex flex-col`;

export const CloseButton = tw.button`cursor-pointer bg-transparent border-0 ml-auto w-auto p-5`;
