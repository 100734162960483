import { useMemo } from 'react';
import { addError } from '../error-capturing';
import useLanguage from './useLanguage';
import useCrowdInLanguage from './useCrowdInLanguage';

type TranslationOptions = {
  params?: {
    [k: string]: {
      value: string;
      tagName?: string;
    };
  };
  silent?: boolean;
};

type TranslationFunction = (
  translationKey: string,
  options?: TranslationOptions,
) => string;

const translationMap = {} as any;

export const useTranslation = (pageName?: string): TranslationFunction => {
  const { language, isLoading } = useLanguage();
  const { language: crowdInLanguage, isLoading: isCrowdInLoading } =
    useCrowdInLanguage();

  const languageData = useMemo(() => {
    if (isLoading || isCrowdInLoading) {
      return {};
    }

    const localPage = language.pages?.find(
      (page: { page: string }) =>
        page.page.replace(/^\//, '') === pageName?.replace(/^\//, ''),
    );

    const crowdInPage = crowdInLanguage.pages?.find(
      (page: { page: string }) =>
        page.page.replace(/^\//, '') === pageName?.replace(/^\//, ''),
    );
    return {
      ...(language?.generic ?? {}),
      ...(crowdInLanguage?.generic ?? {}),
      ...(localPage?.data ?? {}),
      ...(crowdInPage?.data ?? {}),
    };
  }, [pageName, language, isLoading, crowdInLanguage, isCrowdInLoading]);

  return (translationKey, options) => {
    let translation;

    let crowdInTranslation = translationMap[translationKey];
    if (crowdInTranslation) {
      translation = crowdInTranslation;
    } else {
      translation = languageData[translationKey];
    }
    if (options?.params) {
      for (const param in options.params) {
        const { tagName, value } = options.params[param];
        translation = translation?.replace(
          `[${param}]`,
          tagName ? `<${tagName}>${value}</${tagName}>` : value,
        );
      }
    }

    if (translation) {
      return translation;
    }

    if (
      !options?.silent &&
      !isLoading &&
      Object.keys(language?.generic || {}).length
    ) {
      addError(
        new Error(
          `Missing translationkey page="${
            pageName?.replace(/^\//, '') ?? ''
          }" key="${translationKey}"`,
        ),
      );
    }

    return `...`;
  };
};

export default useTranslation;
