// eslint-disable-next-line
import { css } from 'twin.macro'; // eslint-disable-line @typescript-eslint/no-unused-vars
import styles from './styles';

type LabelProps = {
  label?: string;
  required?: boolean;
  children: React.ReactChild;
  className?: string;
  small?: boolean;
};
export const Label = ({
  label,
  required,
  children,
  className,
  small,
}: LabelProps) => {
  return label ? (
    <label
      css={small ? styles.labelStyleSmall : styles.labelStyle}
      className={className}
    >
      {label}
      {required && <span css={styles.labelRequired}>*</span>}
      {children}
    </label>
  ) : (
    <>{children}</>
  );
};
