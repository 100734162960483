import React from 'react';
import tw, { styled, TwStyle } from 'twin.macro';
import { Button } from '../Button/Button';
import { CloseIcon, InfoCircleIcon } from '../Icon/icons.generated';
import useTimeout from '../../hooks/useTimeout';

type position = 'topCenter' | 'topRight' | 'bottomCenter';

const positionStyles: Record<position, TwStyle> = {
  bottomCenter: tw`bottom-4 ml-[-12rem] left-1/2`,
  topCenter: tw`top-4 ml-[-12rem] left-1/2`,
  topRight: tw`top-4 right-4`,
};

const StyledContainer = styled.div(({ position, show }: ToastProps) => [
  (show && tw`opacity-100`) || tw`opacity-0`,
  position && positionStyles[position],
  tw`bg-grey-0 fixed grid items-center py-6 max-w-sm rounded-medium transition-opacity shadow-m`,
  `grid-template-columns: 1fr 4fr 1fr;`,
]);
const StyledHeaderText = styled.div(() => [tw`text-grey-100 text-base`]);
const StyledHeaderIconDiv = styled.div(() => [tw`px-6`]);
const StyledHeaderIcon = styled(InfoCircleIcon)(() => [tw`text-blue-100`]);
const StyledButton = styled(Button)(() => [
  tw`min-w-max bg-transparent h-auto text-grey-100 hover:(bg-grey-0) active:(bg-grey-0 text-grey-100)`,
]);
const StyledBody = styled.div(() => [tw`col-start-2 text-grey-60 text-sm`]);

type ToastProps = {
  autohide?: boolean;
  closeHandler?: () => void;
  duration?: number;
  position?: position;
  show?: boolean;
  textBody?: string;
  textHeader?: string;
};

export const Toast = ({
  autohide = false,
  className,
  closeHandler,
  duration = 2000,
  position = 'topRight',
  show = false,
  textBody,
  textHeader,
}: ToastProps & { className?: string }) => {
  useTimeout(() => {
    if (autohide && show && closeHandler) {
      closeHandler();
    }
  }, duration);

  return (
    <StyledContainer className={className} position={position} show={show}>
      <StyledHeaderIconDiv>
        <StyledHeaderIcon></StyledHeaderIcon>
      </StyledHeaderIconDiv>
      <StyledHeaderText>{textHeader}</StyledHeaderText>
      <StyledButton onClick={closeHandler}>
        <CloseIcon></CloseIcon>
      </StyledButton>
      <StyledBody>{textBody}</StyledBody>
    </StyledContainer>
  );
};
