import { CheckIcon, MinusIcon } from '../Icon/icons.generated';
import tw, { css, styled, TwStyle } from 'twin.macro';

type size = 'small' | 'large';

export type CheckboxProps = {
  disabled?: boolean;
  checked?: boolean;
  indeterminate?: boolean;
  changeHandler?: (checked: boolean | undefined) => void;
  sizeVariant?: size;
};

const inputStyles: Record<size, TwStyle> = {
  small: tw`w-5 h-5`,
  large: tw`w-6 h-6`,
};

const checkIconStyles: Record<size, TwStyle> = {
  small: tw`text-base`,
  large: tw`text-xl`,
};

const StyledContainer = styled.div(({ disabled }: CheckboxProps) => [
  tw`grid cursor-pointer`,
  !disabled &&
    css`
      &:hover {
        > input {
          ${tw`border-blue-120`}
          &:checked {
            ${tw`bg-blue-120`}
          }
        }
      }
    `,
]);

const StyledInput = styled.input(
  ({
    sizeVariant = 'small',
    $indeterminate,
  }: CheckboxProps & { $indeterminate: boolean }) => [
    tw`row-start-1 row-end-1 col-start-1 col-end-1 appearance-none border-2 border-grey-30 border-solid rounded-small bg-grey-0 cursor-pointer duration-300 ease-out`,
    tw`checked:(bg-blue-100 border-blue-100)`,
    tw`disabled:(border-grey-20) disabled:checked:(border-grey-5 bg-grey-5)`,
    inputStyles[sizeVariant],
    $indeterminate &&
      tw`(bg-blue-100 border-blue-100 disabled:(border-grey-5 bg-grey-5))`,
  ],
);

const StyledCheckIcon = styled(CheckIcon)(
  // $sizeVariant is needed as a transient prop, otherwise React treats it as an invalid DOM attribute
  ({
    $checked,
    disabled = false,
    $sizeVariant,
  }: CheckboxProps & { $checked?: boolean; $sizeVariant: size }) => [
    tw`row-start-1 row-end-1 col-start-1 col-end-1 text-grey-0`,
    !$checked && tw`hidden`,
    checkIconStyles[$sizeVariant],
    disabled && tw`text-grey-30`,
  ],
);

const StyledIndeterminateIcon = styled(MinusIcon)(
  // $sizeVariant and $indeterminate are needed as transient props, otherwise React treats them as invalid DOM attributes
  ({
    disabled,
    $sizeVariant,
    $indeterminate,
  }: CheckboxProps & { $sizeVariant: size; $indeterminate: boolean }) => {
    return [
      $indeterminate && [
        tw`row-start-1 row-end-1 col-start-1 col-end-1 text-grey-0`,
        checkIconStyles[$sizeVariant],
        disabled && tw`text-grey-30`,
      ],
      !$indeterminate && tw`hidden`,
    ];
  },
);

export const Checkbox = ({
  checked,
  changeHandler,
  disabled = false,
  sizeVariant = 'small',
  indeterminate = false,
}: CheckboxProps) => {
  const handleChange = () => {
    if (!disabled) {
      if (changeHandler) {
        changeHandler(!checked);
      }
    }
  };

  return (
    <StyledContainer onClick={handleChange} disabled={disabled}>
      <StyledInput
        sizeVariant={sizeVariant}
        type="checkbox"
        checked={checked ?? false}
        readOnly
        disabled={disabled}
        $indeterminate={indeterminate}
      ></StyledInput>
      <StyledCheckIcon
        $sizeVariant={sizeVariant}
        $checked={checked}
        disabled={disabled}
      ></StyledCheckIcon>
      <StyledIndeterminateIcon
        $indeterminate={indeterminate}
        $sizeVariant={sizeVariant}
        disabled={disabled}
      ></StyledIndeterminateIcon>
    </StyledContainer>
  );
};
