import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum-slim';
import { environment, version } from './config';

const hasDatadogRum = window.DATADOG_RUM_ENABLED === 'true';
const emailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/g;

export const beforeSend: NonNullable<RumInitConfiguration['beforeSend']> = (
  event,
) => {
  const eventTarget = (event.type === 'action' && event.action.target) || null;
  if (eventTarget?.name) {
    eventTarget.name = eventTarget.name.replace(emailRegex, '*EMAIL*');
  }
};

if (hasDatadogRum) {
  datadogRum.init({
    applicationId: 'fdab3d01-47cb-433c-b7db-5ff9165ead7a',
    clientToken: 'puba7cba61c10b2f2dc67829e76613bb04a',
    site: 'datadoghq.eu',
    service: 'education-website',
    sampleRate: 100,
    trackInteractions: true,
    allowedTracingOrigins: [
      'https://myengine.goodhabitz.com',
      /https:\/\/api-.+\.goodhabitz\.com/,
    ],
    version,
    env: environment,
    beforeSend,
  });
}

export function setUserId(userId: string) {
  if (hasDatadogRum) {
    datadogRum.setUser({
      id: userId,
    });
  }
}

export function addError(error: unknown, context?: object | undefined) {
  if (hasDatadogRum) {
    try {
      datadogRum.addError(error, context);
    } catch (e) {
      console.error(error);
    }
  }
}
