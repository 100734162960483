import React from 'react';
import type { ReactNode, ReactNodeArray } from 'react';
import { onButtonClickHandler } from './';

interface Props {
  children: ReactNode | ReactNodeArray;
}

const EventTracker = ({ children }: Props) => (
  <div onClickCapture={onButtonClickHandler}>{children}</div>
);

export default EventTracker;
