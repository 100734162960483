import useTranslation from '../../hooks/useTranslation';
import { Button } from '../Button/Button';
import { MobileIcon } from '../Icon/icons.generated';
import tw from 'twin.macro'; //eslint-disable-line @typescript-eslint/no-unused-vars
import useLanguage from '../../hooks/useLanguage';

export const DownloadAppButton = () => {
  const t = useTranslation();
  const {
    language: { code },
  } = useLanguage();

  let link = `/${code}/app-download.html`;

  if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
    link =
      'https://play.google.com/store/apps/details?id=com.goodhabitz.challengeapp&pli=1';
  }
  if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
    link = 'https://apps.apple.com/app/goodhabitz/id1506741583';
  }

  return (
    <Button
      variant="green"
      size="small"
      as="a"
      href={link}
      tw="min-w-[122px] no-underline"
    >
      <MobileIcon />
      {t('txt_use_app')}
    </Button>
  );
};
