import 'twin.macro';
import useLanguage from '../../../hooks/useLanguage';
import { useWebsession } from '../../../hooks/useWebsession';
import { LegalListItem } from '../FooterStyles';

// Deal with websession structure of FooterLinkList
export const LegalLinks = () => {
  const {
    language: { code: languageCode },
  } = useLanguage();
  const [links, isLoading] = useWebsession('FooterLinkList');

  if (isLoading || !links) {
    return null;
  }

  return (
    <>
      {links
        .filter(({ LanguageCode: code }) => code === languageCode)
        .sort((a, b) => a.DisplayOrder - b.DisplayOrder)
        .map((link) => (
          <LegalListItem key={link.Url}>
            <a href={link.Url} target={link.Target} tw="no-underline">
              {link.Name}
            </a>
          </LegalListItem>
        ))}
    </>
  );
};

// Deal with websession structure of LegalDocumentsList
export const LegalDocuments = () => {
  const {
    language: { code: languageCode },
  } = useLanguage();
  const [documents, isLoading] = useWebsession('LegalDocumentList');

  if (isLoading || !documents) {
    return null;
  }

  const documentsToDisplay = [
    {
      TypeId: 1,
      Link: '/privacy-statements.html',
      FallbackName: 'Privacy Statement',
    },
    {
      TypeId: 2,
      Link: '/general-conditions.html',
      FallbackName: 'General Conditions',
    },
    {
      TypeId: 3,
      Link: '/user-agreements.html',
      FallbackName: 'User Agreements',
    },
  ]
    .map((docDefinition) => {
      const doc = documents.find(
        ({ TypeId: id }) => id === docDefinition.TypeId,
      );

      return {
        ...docDefinition,
        doc,
      };
    })
    .filter(({ doc }) => doc !== null)
    .map(({ TypeId, Link, doc, FallbackName }) => {
      let TypeName;
      try {
        TypeName = doc?.TypeName
          ? JSON.parse(doc.TypeName)[languageCode]
          : FallbackName;
      } catch {
        TypeName = FallbackName;
      }
      return {
        TypeId,
        Link,
        TypeName,
      };
    });

  return (
    <>
      {documentsToDisplay.map(({ TypeId, Link, TypeName }) => (
        <LegalListItem key={TypeId}>
          <a href={'/' + languageCode + Link} tw="no-underline">
            {TypeName}
          </a>
        </LegalListItem>
      ))}
    </>
  );
};
