import { Course } from '../models/Course';
import { getLanguageString, isnull, renderExcel } from '../vanilla-js/common';
import { LanguageInfo, getWebsession } from '../vanilla-js/websession';
import { fetchCourseList } from './apiRequests';

function createExcelHeaders(firstHeaderName: string): any[] {
  const excelHeaders = [];

  const firstHeader = { name: firstHeaderName };
  excelHeaders.push(firstHeader);

  getWebsession()?.LanguageList.forEach((language: LanguageInfo) => {
    if (language.StatusId === 1) {
      excelHeaders.push({ name: language.LanguageCode });
    }
  });

  return excelHeaders;
}

async function createExcelRows(): Promise<any[]> {
  const rows: any[] = [];
  const courseList = await fetchCourseList(0);
  const languageList = getWebsession()?.LanguageList;

  getWebsession()
    ?.CourseAreas.reverse()
    .forEach(function (a: any) {
      var areaCourses = courseList.filter(function (course: Course) {
        return (
          course.CourseAreaId === a.Id &&
          course.PublicationStatus === 1 &&
          course.NodeTypeId === 13 &&
          course.CourseAreaId !== 0
        );
      });
      var courseContainerIds = areaCourses?.map(function (course: Course) {
        return course.CourseContainerId;
      });

      // GET UNIQUE CONTAINER ID'S
      var uniqueCourseContainerIds = courseContainerIds?.filter(function (
        item: any,
        pos: any,
      ) {
        return courseContainerIds.indexOf(item) === pos;
      });

      // LOOP THROUGH CONTAINER ID'S
      uniqueCourseContainerIds.sort(function (a: number, b: number) {
        return b - a;
      });
      uniqueCourseContainerIds.forEach(function (c: any) {
        var cellArray = [];
        // GET CONTAINER COURSES
        var areaContainerCourses = areaCourses?.filter(function (d: any) {
          return d.CourseContainerId === c;
        });

        if (areaContainerCourses.length > 0) {
          var cell = { value: getLanguageString(isnull(a.Name)) };
          cellArray.push(cell);

          // SEPARATE PER LANGUAGE AND FILL ROW
          if (languageList) {
            for (let i = 0; i < languageList.length; i++) {
              let currentLanguage = languageList[i];
              // Only include published languages
              if (currentLanguage.StatusId) {
                let found = false;

                areaContainerCourses.forEach(function (d: any) {
                  if (
                    (d.Lang === currentLanguage.LanguageCode ||
                      d.Lang === '') &&
                    d.PublicationStatus === 1 &&
                    !found
                  ) {
                    var cell = { value: isnull(d.properties.title) };
                    cellArray.push(cell);
                    found = true;
                  }
                });
                if (!found) {
                  cell = { value: '-' };
                  cellArray.push(cell);
                }
              }
            }
          }
          var row = { cell: cellArray };
          rows.push(row);
        }
      });
    });

  return rows;
}

export function generateDateStamp(): string {
  const newDate = new Date();
  const currentDate =
    newDate.getDate() +
    '-' +
    (newDate.getMonth() + 1) +
    '-' +
    newDate.getFullYear();

  return currentDate;
}

export async function downloadCourseOverviewAsExcel(
  firstHeaderName: string,
  fileName: string,
): Promise<void> {
  const content = {
    general: {
      sheetname: 'Export',
    },
    headerCollection: createExcelHeaders(firstHeaderName),
    rowcollection: await createExcelRows(),
  };

  renderExcel(content, `${fileName}_${generateDateStamp()}.xlsx`);
}
