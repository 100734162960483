import React from 'react';
import tw, { styled } from 'twin.macro';
import useTranslation from '../../hooks/useTranslation';

const StyledSpinner = styled.div`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  ${tw`w-6 h-6 inline-block rounded-full border-solid border-4 border-grey-10 border-l-blue-100 border-t-blue-100 animate-spin`}
`;

export const SpinnerV2 = () => {
  const t = useTranslation();
  return (
    <StyledSpinner>
      <span tw="sr-only">{t('loading')}</span>
    </StyledSpinner>
  );
};
