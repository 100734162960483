import { useEffect, useState } from 'react';
import { fetchWebsessionWithId } from '../../utils/apiRequests';
import { getLtiUrlParameters } from '../../utils/ltiUtils';
import {
  handleLmsNext,
  setLanguage,
  setToken,
  clearPreviousLmsSession,
} from '../../utils/sessionUtils';
import { loginPageId } from '../../constants/pageIds';
import { styled } from 'twin.macro';
import Page from '../../components/Page';
import * as Styled from './LoginPage/LoginPage.styles';
import useTranslation from '../../hooks/useTranslation';
import { LegalDocument, WebSession } from '../../vanilla-js/websession';
import { Button, SpinnerModal, Checkbox } from '../../common-components';
window.showMenu = false;
const InfoText = styled.p`
  max-width: 300px;
`;
const CheckboxRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export default function LtiLoginPage() {
  const [legalDocuments, setLegalDocuments] = useState<LegalDocument[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lang, setLang] = useState<string>('');
  const [userToken, setUserToken] = useState<string>('');
  const [redirUrl, setRedirUrl] = useState<string>('');
  const [WS, setWS] = useState<WebSession | null>(null);
  type CheckingState = {
    [key: number]: boolean;
  };
  // Then initialize your state:
  const [checkedStates, setCheckedStates] = useState<CheckingState>({});
  const t = useTranslation();

  const handleLogin = async () => {
    const documentKeys = Object.keys(checkedStates);
    const totalDocuments = documentKeys.length;
    let completedDocuments = 0;

    documentKeys.forEach((key: string) => {
      fetch(window.ghapi + '/api/CompanyDocument/' + key, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      })
        // This makes sure the function gets completed before redirect
        // eslint-disable-next-line promise/prefer-await-to-then
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch document: ' + key);
          }
          // Increment the counter when the fetch is successful
          completedDocuments += 1;
          // If all documents have been processed, run the try-catch block
          if (completedDocuments === totalDocuments) {
            try {
              if (WS) {
                handleLmsNext(WS, redirUrl);
              }
            } catch (error) {
              console.warn(error);
              return setErrorMessage('Fetching web session has failed!!');
            }
          }
        })
        // eslint-disable-next-line promise/prefer-await-to-then
        .catch((error) => {
          console.warn(error);
          setErrorMessage('Fetching web session has failed!!');
        });
    });
  };

  useEffect(() => {
    const check = async () => {
      const urlParameters = getLtiUrlParameters();
      if (!urlParameters) {
        return setErrorMessage('Lti url parameters are missing!');
      }
      const { token, languageCode, url } = urlParameters;
      setLanguage(languageCode);
      setLang(languageCode);
      setUserToken(token);
      setRedirUrl(url);
      setIsLoading(true);
      if (!urlParameters) {
        setErrorMessage('Lti url parameters are missing!');
        setIsLoading(false);
      }
      try {
        clearPreviousLmsSession();
        setToken({ sessiontoken: 1, token: userToken });
        setLanguage(lang);
        const newWebsession = await fetchWebsessionWithId();
        setWS(newWebsession);
        let allAgreed = true;
        const response = await fetch(`${window.ghapi}/api/documentCheck`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const legalDocumentList = await response.json();
        const legalDocumentsNeedsToBeChecked = legalDocumentList.filter(
          (doc: any) => doc.MustBeChecked && doc.CheckId <= 0,
        );
        setLegalDocuments(legalDocumentsNeedsToBeChecked);
        let initialCheckedStates: CheckingState = {};
        legalDocumentsNeedsToBeChecked.forEach((doc: any) => {
          console.log(doc, 'doc');
          initialCheckedStates[doc.TypeId] = false;
          allAgreed = false;
        });
        if (allAgreed) {
          handleLmsNext(newWebsession, redirUrl);
        }
        setCheckedStates(initialCheckedStates);
      } catch (error) {
        console.warn(error);
        setErrorMessage('Fetching web session has failed!!');
      }
    };
    check();
    setIsLoading(false);
  }, [lang, redirUrl, userToken]);

  return (
    <Page pageId={loginPageId} layout="plain">
      <Styled.LoginContainer>
        <Styled.LoginFormContainer>
          {!errorMessage ? ( // only display the Styled.LoginForm if errorMessage is empty
            <Styled.LoginForm>
              <Styled.LanguageSelectLabel htmlFor="language-select">
                {t('lbl_language')}
              </Styled.LanguageSelectLabel>
              <InfoText>
                {t('dlg_txt_legal_documents')
                  ?.split('<br>')[0]
                  ?.replace(/<br>/g, '')
                  ?.replace(/<\/br>/g, '')}
                <br />
                <br />
                {t('dlg_txt_legal_documents')
                  ?.split('<br>')[1]
                  ?.replace(/<br>/g, '')
                  ?.replace(/<\/br>/g, '')}
              </InfoText>
              {legalDocuments.map((doc, index) => (
                <CheckboxRow key={index}>
                  <Checkbox
                    sizeVariant={'large'}
                    checked={checkedStates[doc.TypeId] || false}
                    changeHandler={() =>
                      setCheckedStates({
                        ...checkedStates,
                        [doc.TypeId]: !checkedStates[doc.TypeId],
                      })
                    }
                  ></Checkbox>
                  <a
                    target="_blank"
                    href={`https://myengine.goodhabitz.com/API/LegalDocumentDownload/${doc.TypeId}`}
                    rel="noreferrer"
                  >
                    {'' + JSON.parse(doc.TypeName)[lang]}
                  </a>
                </CheckboxRow>
              ))}
              <Button
                type="button"
                onClick={handleLogin}
                disabled={Object.values(checkedStates).includes(false)}
              >
                {t('dlg_btn_approve_legal_documents')}
              </Button>
            </Styled.LoginForm>
          ) : (
            // if errorMessage not empty, show the error message
            <div>{errorMessage}</div>
          )}
        </Styled.LoginFormContainer>
      </Styled.LoginContainer>
      <SpinnerModal isOpen={isLoading} />
    </Page>
  );
}
