import {
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  useCallback,
} from 'react';

function getSessionStorageValue(key: string) {
  const stored = sessionStorage.getItem(key);
  return stored ? JSON.parse(stored) : undefined;
}

export function useSessionStorage<T>(
  key: string,
): [T, Dispatch<SetStateAction<T | undefined>>, () => void] {
  const [value, setValue] = useState(getSessionStorageValue(key));

  const remove = useCallback(() => {
    sessionStorage.removeItem(key);
  }, [key]);

  useEffect(() => {
    if (value) sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue, remove];
}
