import React, { Fragment, useState } from 'react';
import { Combobox as HeadlessUICombobox } from '@headlessui/react';
import { AngleDownIcon, AngleUpIcon } from '../Icon/icons.generated';
import tw, { styled, TwStyle } from 'twin.macro';

const inputStyles: Record<size, TwStyle> = {
  small: tw`text-sm py-3`,
  large: tw`text-base py-4`,
};

const StyledContainerDiv = styled.div(() => [tw`relative`]);
const StyledLabel = styled.label(({ isSrLabel }: { isSrLabel: boolean }) => [
  tw`block text-sm pb-3`,
  isSrLabel && tw`sr-only`,
]);

const StyledInputContainerDiv = styled.div(() => [tw`flex`]);
const StyledInput = styled.input(
  ({ disabled, $sizeVariant }: ComboboxProps & { $sizeVariant: size }) => [
    inputStyles[$sizeVariant],
    tw`max-w-xs pl-5 pr-9 border-2 border-solid rounded-medium border-grey-10 bg-grey-0 hover:border-blue-100 focus-visible:border-blue-100`,
    disabled && tw`text-grey-30 hover:border-grey-10`,
  ],
);

const StyledButton = styled.button(({ disabled }: ComboboxProps) => [
  tw`pr-4 -ml-8  align-middle cursor-pointer`,
  disabled && tw`text-grey-30 hover:cursor-default`,
]);

const StyledIconDiv = styled.div(() => [
  tw`text-2xl flex justify-center items-center`,
]);
const StyledOptions = styled.ul(() => [
  tw`absolute z-10 w-full mt-1 overflow-auto text-base bg-grey-0 border-2 border-solid rounded-medium border-blue-100 max-h-[10rem] focus:outline-none`,
]);

const StyledOption = styled.li(({ active }: { active: boolean }) => [
  tw`pl-5`,
  active && tw`bg-blue-100 text-grey-0 rounded-small cursor-pointer `,
  !active && tw`bg-grey-0 text-grey-100`,
]);

export type ComboboxOption = {
  label: string;
  value: string;
};

type size = 'small' | 'large';

type ComboboxProps = {
  changeHandler?: (option: ComboboxOption) => void;
  className?: string;
  disabled?: boolean;
  label?: string;
  isSrLabel?: boolean;
  options?: ComboboxOption[];
  sizeVariant?: size;
  selected?: ComboboxOption;
  _nullable?: boolean;
};

export const Combobox = ({
  changeHandler,
  className,
  disabled = false,
  label,
  isSrLabel = false,
  options = [],
  selected = options[0],
  sizeVariant = 'small',
  _nullable = false,
}: ComboboxProps) => {
  const [query, setQuery] = useState('');

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => {
          return option.label.toLowerCase().includes(query.toLowerCase());
        });

  const handleChange = (option: ComboboxOption) => {
    if (changeHandler) {
      changeHandler(option);
    }
  };

  return (
    <StyledContainerDiv className={className}>
      <HeadlessUICombobox
        disabled={disabled}
        value={selected}
        nullable={_nullable}
        onChange={handleChange}
      >
        {label && (
          <HeadlessUICombobox.Label as={Fragment}>
            <StyledLabel isSrLabel={isSrLabel}>{label}</StyledLabel>
          </HeadlessUICombobox.Label>
        )}
        <StyledInputContainerDiv>
          <HeadlessUICombobox.Input
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(option: { label: string; value: string }) =>
              option?.label
            }
            as={Fragment}
          >
            <StyledInput $sizeVariant={sizeVariant}></StyledInput>
          </HeadlessUICombobox.Input>
          <HeadlessUICombobox.Button as={Fragment}>
            {({ open }) => (
              <StyledButton name="Toggle options">
                <StyledIconDiv>
                  {!open && <AngleDownIcon />}
                  {open && <AngleUpIcon />}
                </StyledIconDiv>
              </StyledButton>
            )}
          </HeadlessUICombobox.Button>
        </StyledInputContainerDiv>

        {filteredOptions.length > 0 && (
          <HeadlessUICombobox.Options as={Fragment}>
            <StyledOptions>
              {filteredOptions.map((option) => (
                <HeadlessUICombobox.Option
                  key={option.label}
                  value={option}
                  as={Fragment}
                >
                  {({ active }) => (
                    <StyledOption active={active}>{option.label}</StyledOption>
                  )}
                </HeadlessUICombobox.Option>
              ))}
            </StyledOptions>
          </HeadlessUICombobox.Options>
        )}
      </HeadlessUICombobox>
    </StyledContainerDiv>
  );
};
