import * as React from 'react';
import tw, { styled } from 'twin.macro';

import { LoadingIcon } from '../../common-components';

const StyledSpinnerContainer = tw.div`flex flex-col items-center w-full h-13`;
const StyledSpinnerSpan = tw.span`block mt-4 text-lg font-sans`;
const StyledSpinningDiv = styled.div`
  width: 64px;
  height: 64px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
type SpinnerProps = {
  label?: string;
};

export const Spinner = ({ label = 'Loading...' }: SpinnerProps) => {
  return (
    <StyledSpinnerContainer>
      <StyledSpinningDiv>
        <LoadingIcon width={64} height={64} fill="none" />
      </StyledSpinningDiv>
      <StyledSpinnerSpan>{label}</StyledSpinnerSpan>
    </StyledSpinnerContainer>
  );
};
