import tw, { css, styled } from 'twin.macro';

type LabelProps = {
  type?: string;
  small?: boolean;
};

export const Label = styled.div<LabelProps>(({ type, small }) => [
  tw`text-grey-100 bg-green-100 rounded-small`,
  css`
    padding: ${small ? '2px 6px' : '4px 12px'};
  `,
  type === 'videoLabel' &&
    tw`bg-[rgba(30, 30, 30, 0.4)] self-start text-grey-0 px-3 py-2 text-sm md:(text-base)`,
  small && tw`text-xs`,
]);
