import localforage from 'localforage';
import { addError, setUserId } from '../error-capturing';
import {
  ModalDialog,
  ModalDialogType,
  legalDocumentsCheckDoneEvent,
} from './Components/ComponentsDialog';
import {
  get_language,
  setCookie,
  xdr,
  redirectToLogin,
  normalize_path,
} from './common';
import {
  getWebsession,
  hasWebsession,
  loadWebsession,
  removeWebsession,
  websession,
} from './websession';
import { getLanguageObject } from './language';
import { clearPreviousLmsSession } from '../utils/sessionUtils';

var initializecount = 0;

export var page = {};
window.page = page;

var showNotifications = true;
window.showNotifications = showNotifications;

var contactFormIdLI = -1;

var contactFormIdNLI = -1;

export var dBase = localforage.createInstance({
  name: 'WebsiteData',
});
window.dBase = dBase;

// DISABLE AUTO GOOGLE TRANSLATE
var link = document.createElement('meta');
link.name = 'google';
link.content = 'notranslate';
document.getElementsByTagName('head')[0].appendChild(link);

function neededCoursesLoaded({ websession, result, resolveCb, language }) {
  dBase.setItem('Courses', [websession.createdate, result]);
  dBase.setItem('LoadedCatalog', language);
  websession.Courses = result;
  initPart2(resolveCb);
}

export async function initialize() {
  try {
    handleLmsSession();

    favico();
    mobileHomescreenIcon();

    await getLanguageObject();

    await get_page(pageID);

    if (!hasWebsession()) {
      loadWebsession(initialize);
    } else {
      Object.assign(websession, getWebsession());
      var userId = websession.CurrentUser.Id;
      if (userId !== -1) {
        setUserId(websession.CurrentUser.ExternalPersonReference);
      }

      const isAction =
        normalize_path(document.location.pathname)
          .split('/')[2]
          ?.toLowerCase() === 'action';
      if (!isAction) {
        // *****************
        return new Promise((resolve) => {
          dBase.getItem('Courses', async function (err, value) {
            if (err !== null) {
              new ModalDialog(
                document.body,
                ModalDialogType.NotificationFail,
                lang.generic.err_dlg_text + err,
                lang.generic.err_dlg_btn
              );
            } else {
              const loaded = await dBase.getItem('LoadedCatalog');
              const isDashboard = normalize_path(document.location.pathname)
                .split('/')[2]
                ?.toLowerCase()
                .includes('dashboard');
              if (loaded !== lang.languageregion || value === null) {
                xdr(
                  `${ghapi}/api/courseList/0${
                    !isDashboard ? `?languageRegion=${lang.languageregion}` : ''
                  }`,
                  'GET',
                  null,
                  function (cc) {
                    const courses = JSON.parse(cc);
                    const libraryHierarchyConfig =
                      window.LibraryHierarchyCfg[lang.languageregion];
                    if (!isDashboard && libraryHierarchyConfig) {
                      xdr(
                        `${ghapi}/api/courseList/0?languageRegion=${libraryHierarchyConfig.base}`,
                        'GET',
                        null,
                        function (cc) {
                          neededCoursesLoaded({
                            websession,
                            result: [...courses, ...JSON.parse(cc)],
                            resolveCb: resolve,
                            language: lang.languageregion,
                          });
                        },
                        function (x) {
                          new ModalDialog(
                            document.body,
                            ModalDialogType.NotificationFail,
                            lang.generic.err_dlg_text + x,
                            lang.generic.err_dlg_btn
                          );
                        }
                      );
                    } else {
                      neededCoursesLoaded({
                        websession,
                        result: courses,
                        resolveCb: resolve,
                        language: lang.languageregion,
                      });
                    }
                  },
                  function (x) {
                    new ModalDialog(
                      document.body,
                      ModalDialogType.NotificationFail,
                      lang.generic.err_dlg_text + x,
                      lang.generic.err_dlg_btn
                    );
                  }
                );
              } else if (isDashboard && value[1].length < 500) {
                console.log('Loading all courses for dashboard');
                xdr(
                  `${ghapi}/api/courseList/0`,
                  'GET',
                  null,
                  function (cc) {
                    neededCoursesLoaded({
                      websession,
                      result: JSON.parse(cc),
                      resolveCb: resolve,
                      language: lang.languageregion,
                    });
                  },
                  function (x) {
                    new ModalDialog(
                      document.body,
                      ModalDialogType.NotificationFail,
                      lang.generic.err_dlg_text + x,
                      lang.generic.err_dlg_btn
                    );
                  }
                );
              } else if (value[0] !== websession.createdate) {
                xdr(
                  ghapi + '/api/courseList/' + Math.round(value[0] / 1000),
                  'GET',
                  null,
                  function (cc) {
                    var result = JSON.parse(cc);
                    var dbCourses = value[1];

                    // RESET RESULTS OF COURSES
                    dbCourses.forEach(function (dbc) {
                      dbc.LastVisit = null;
                      dbc.completedId = -1;
                      dbc.favId = -1;
                      dbc.passedId = -1;
                      dbc.progress = -1;
                    });

                    // IF RESULTS PRESENT: UPDATE RESULT OF COURSES
                    result.forEach(function (r) {
                      var index = -1;
                      for (var i = 0; i < dbCourses.length; i++) {
                        if (dbCourses[i].CourseId === r.CourseId) {
                          index = i;
                          break;
                        }
                      }

                      // INSERT
                      if (index === -1) {
                        dbCourses.push(r);
                      }
                      // UPDATE
                      else {
                        dbCourses[index] = r;
                      }
                    });

                    neededCoursesLoaded({
                      websession,
                      result: dbCourses,
                      resolveCb: resolve,
                      language: lang.languageregion,
                    });
                  },
                  function (x) {
                    new ModalDialog(
                      document.body,
                      ModalDialogType.NotificationFail,
                      lang.generic.err_dlg_text + x,
                      lang.generic.err_dlg_btn
                    );
                  }
                );
              } else {
                websession.Courses = value[1];
                initPart2(resolve);
              }
            }
          });
        });
      } else {
        initPart2();
      }
    }
  } catch (ex) {
    try {
      sessionStorage.clear();
      localStorage.clear();
    } catch (e) {
      addError(e);
    }
    initializecount++;
    if (initializecount < 3) {
      initialize();
    } else {
      new ModalDialog(
        document.body,
        ModalDialogType.NotificationFail,
        lang.generic.err_dlg_text + ex,
        lang.generic.err_dlg_btn
      );
    }
    throw ex;
  }
}

window.initialize = initialize;

var initializecount2 = 0;

function handleLmsSession() {
  // If the user is on the simple course page or the goodscan page, we shouldn't clear the previous LMS session because of LTI13 internal redirection.
  if (
    window.location.pathname.includes('/course/') ||
    window.location.pathname.includes('/goodscan/')
  )
    return;

  const sessionToken = sessionStorage.getItem('token');
  if (!sessionToken) return;

  const parsedSessionToken = parseJwt(sessionToken);
  if (!parsedSessionToken) return;

  if (['LTI', 'LTI13', 'RS'].includes(parsedSessionToken.LoginOrigin)) {
    clearPreviousLmsSession();
  }
}

function parseJwt(token) {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    return null;
  }
}

function setCoursesToWebsession() {
  websession.CourseResults.forEach(function (cr) {
    for (var i = 0; i < websession.Courses.length; i++) {
      if (websession.Courses[i].CourseId === cr.CourseId) {
        websession.Courses[i].LastVisit = cr.LastVisit;
        websession.Courses[i].completedId = cr.completedId;
        websession.Courses[i].favId = cr.favId;
        websession.Courses[i].passedId = cr.passedId;
        websession.Courses[i].progress = cr.progress;
        break;
      }
    }
  });
}

function cleanUserData() {
  removeWebsession();
  if (localStorage.getItem('token')) {
    localStorage.removeItem('token');
  }
}

function setCountryCookie() {
  if (window.location.pathname !== '/') {
    setCookie('country', getContentLanguage(), 365);
    setCookie('language', get_language(), 365);
  }
}

function isUserLoggedIn() {
  return websession.CurrentUser.Id !== -1;
}

function getContactIdByName(name) {
  return websession.FormList.filter(function (f) {
    return f.Name === name;
  });
}

function setContactFormIdLI(contactId) {
  if (contactId.length > 0) {
    contactFormIdLI = contactId[0].Id;
    window.contactFormIdLI = contactFormIdLI;
  } else {
    console.log('CONTACT FORM LOGGED IN NOT FOUND');
    window.contactFormIdLI = contactFormIdLI;
  }
}

function setContactFormIdNLI(contactId) {
  if (contactId.length > 0) {
    contactFormIdNLI = contactId[0].Id;
    window.contactFormIdNLI = contactFormIdNLI;
  } else {
    console.log('CONTACT FORM NOT LOGGED IN NOT FOUND');
    window.contactFormIdNLI = contactFormIdNLI;
  }
}

function showLegalDocument(legalDocuments) {
  new ModalDialog(
    document.body,
    ModalDialogType.LegalDocuments,
    lang.generic.dlg_txt_legal_documents,
    lang.generic.dlg_btn_approve_legal_documents,
    null,
    null,
    null,
    legalDocuments
  );
}

function getLegalDocuments() {
  var legalDocuments = [];

  websession.LegalDocumentList.forEach(function (ld) {
    if (ld.MustBeChecked && ld.CheckId === 0) {
      legalDocuments.push(ld);
    }
  });

  return legalDocuments;
}

function isCourseInUrlValid(url) {
  var courseFound = false;
  var courseID = parseInt(normalize_path(url).split('/')[4]);
  var courseGuid = normalize_path(url).split('/')[4];
  for (var i = 0; i < websession.Courses.length; i++) {
    if (
      courseID === websession.Courses[i].CourseId ||
      courseGuid === websession.Courses[i].CourseGuid
    ) {
      courseFound = true;
      break;
    }
  }

  return courseFound;
}

function cleanCourseSessionValues() {
  sessionStorage.removeItem('DA-Course');
  sessionStorage.removeItem('DA-VID');
}

function removeActivityIdFromUrl() {
  history.replaceState &&
    history.replaceState(
      null,
      '',
      location.pathname +
        location.search
          .replace(/[\?&]activityId=[^&]+/, '')
          .replace(/^&/, '?') +
        location.hash
    );
}

function getActivityIdInUrl() {
  var decodedQueryString = decodeURIComponent(window.location.search);
  var searchParams = new URLSearchParams(decodedQueryString);

  return searchParams.has('activityId') ? searchParams.get('activityId') : null;
}

function hasCourseInUrl() {
  return (
    location.href.indexOf('/online-course/') >= 0 ||
    location.href.indexOf('/course/') >= 0
  );
}

function initPart2(resolveCb) {
  if (isUserLoggedIn()) {
    var coursesExistInWebsession = websession.CourseResults !== undefined;

    if (!coursesExistInWebsession) {
      if (initializecount2 >= 0) {
        cleanUserData();
        return redirectToLogin();
      }
      initializecount2++;
    } else {
      setCoursesToWebsession();
    }
  }

  setCountryCookie();

  // GET CONTACT FROM ID'S
  setContactFormIdLI(getContactIdByName('Service-Req'));
  setContactFormIdNLI(getContactIdByName('Service-Req-NLI'));

  // MENU
  // buildMenu();

  var initPageFunctionDefined = typeof init_page == 'function';
  if (initPageFunctionDefined) {
    if (hasCourseInUrl() && isCourseInUrlValid(location.href)) {
      var activityId = getActivityIdInUrl();
      if (activityId) {
        sessionStorage.setItem('ActivityId', activityId);
        removeActivityIdFromUrl();
      }

      // if (!isUserLoggedIn()) {
      //   sessionStorage.setItem('CoursePageUrl', location.href);
      // }
    }

    // var coursePageUrl = sessionStorage.getItem('CoursePageUrl');
    // if (isUserLoggedIn() && coursePageUrl) {
    //   sessionStorage.removeItem('CoursePageUrl');
    //   document.location = edit_url(coursePageUrl);
    // } else {
    //   init_page();
    // }

    init_page();
  }

  sendUpdate(websession.id);
  hidesave();

  if (isUserLoggedIn()) {
    var legalDocuments = getLegalDocuments();
    var isLegalDocumentUpToDate = legalDocuments.length === 0;

    if (isLegalDocumentUpToDate) {
      window.dispatchEvent(legalDocumentsCheckDoneEvent);
    } else {
      showLegalDocument(legalDocuments);
    }
  }

  // **** SIOUX
  if (pageID !== 'course.html') {
    cleanCourseSessionValues();
  }

  if (resolveCb) {
    console.log('promise resolved');
    resolveCb();
  }
}

function sendUpdate(id) {
  var _id = id;
  var putRefreshRate =
    websession.RefreshRate !== undefined ? websession.RefreshRate : 10;
  setInterval(function () {
    var data = {
      guid: websession.userSessionId,
      loggedin: websession.CurrentUser.Id > -1,
      useragent: navigator.userAgent,
      url: window.location.href,
      timestamp: Date.now(),
    };

    xdr(
      ghapi + '/api/websession2/' + _id,
      'PUT',
      JSON.stringify(data),
      function (r) {},
      function (ws) {
        if (ws === 401 || ws === 500) {
          removeWebsession();
          if (localStorage.getItem('token')) {
            localStorage.removeItem('token');
          }
          redirectToLogin();
        }
      }
    );
  }, putRefreshRate * 1000);
}

function favico() {
  var link = document.createElement('LINK');
  link.rel = 'icon';
  link.type = 'image/png';
  link.href = edit_url('/Logomark_32x32b.png');
  document.head.appendChild(link);
}

function mobileHomescreenIcon() {
  var icon = document.createElement('LINK');

  icon.rel = 'apple-touch-icon';
  icon.type = 'image/png';
  icon.href = edit_url('/gh-icon-homescreen.png');

  var iconTitle = document.createElement('META');
  iconTitle.name = 'apple-mobile-web-app-title';
  iconTitle.content = 'GoodHabitz';

  var iconTitleAndroid = document.createElement('META');
  iconTitleAndroid.name = 'application-name';
  iconTitleAndroid.content = 'GoodHabitz';

  document.head.appendChild(icon);
  document.head.appendChild(iconTitle);
  document.head.appendChild(iconTitleAndroid);
}

export function loadDBValue(DBItem, APICall, Method, Data, Callback) {
  dBase.getItem(DBItem, function (err, value) {
    if (err !== null) {
      new ModalDialog(
        document.body,
        ModalDialogType.NotificationFail,
        lang.generic.err_dlg_text + err,
        lang.generic.err_dlg_btn
      );
    } else {
      if (value === null || value[0] !== websession.createdate) {
        xdr(
          ghapi + APICall,
          Method,
          Data,
          function (cc) {
            var result = JSON.parse(cc);
            dBase.setItem(DBItem, [websession.createdate, result]);

            Callback(result);
          },
          function (x) {
            new ModalDialog(
              document.body,
              ModalDialogType.NotificationFail,
              lang.generic.err_dlg_text + x,
              lang.generic.err_dlg_btn
            );
          }
        );
      } else {
        Callback(value[1]);
      }
    }
  });
}

window.loadDBValue = loadDBValue;

var lazy = [];

export function setLazy() {
  lazy = document.getElementsByClassName('lazy');
}

window.setLazy = setLazy;

function lazyLoad() {
  for (var i = 0; i < lazy.length; i++) {
    if (isInViewport(lazy[i])) {
      if (lazy[i].getAttribute('data-src')) {
        lazy[i].src = lazy[i].getAttribute('data-src');
        lazy[i].removeAttribute('data-src');
      }
    }
  }

  cleanLazy();
}

window.lazyLoad = lazyLoad;

function cleanLazy() {
  lazy = Array.prototype.filter.call(lazy, function (l) {
    return l.getAttribute('data-src');
  });
}

function isInViewport(el) {
  var rect = el.getBoundingClientRect();

  return (
    rect.bottom >= 0 &&
    rect.right >= 0 &&
    rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.left <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function registerListener(event, func) {
  if (window.addEventListener) {
    window.addEventListener(event, func);
  } else {
    window.attachEvent('on' + event, func);
  }
}

window.registerListener = registerListener;

async function get_page(pageID) {
  const pageIDNoLeadingSlash = pageID.replace(/^\//, '');
  for (var x = 0; x < lang.pages.length; x++) {
    if (lang.pages[x].page.replace(/^\//, '') === pageIDNoLeadingSlash) {
      Object.assign(page, lang.pages[x].data);
      break;
    }
  }
}

export function change_language(new_languagecode) {
  var str = window.location.pathname;

  while (str.indexOf('//') != -1) {
    var str = str.replace('//', '/');
  }
  var urlpart = str.split('/');

  sessionStorage.removeItem('lang');
  var new_url = '/' + new_languagecode + '/personal-courses.html';
  sessionStorage.removeItem('lang');
  document.location = new_url;
}

window.change_language = change_language;

export function edit_url(url) {
  var lc = get_language();
  if (url == '/client/login') {
    url += '?redirect=' + window.location.origin + window.location.pathname; // Ignore args
  }
  if (url.toLowerCase().indexOf('/clientbeta/') === -1) {
    url = url.replace('/client/', '/' + lc + '/');
  } else {
    url = url.toLowerCase().replace('/clientbeta/', '/' + lc + '/beta/');
  }

  return url;
}

window.edit_url = edit_url;

export function storeDataAnalysisObject(data) {
  var dataObject = {
    CourseId: data.CourseId,
    ObjectTypeOrigin: data.ObjectOriginType,
    ObjectTypePositionOrigin: data.ObjectOriginTypePosition,
    ObjectReferenceOrigin: data.ObjectOriginReference,
    CoursetilePositionOrigin: data.CoursetileOriginPosition,
    WebsessionId: websession.id,
  };
  sessionStorage.setItem('DA-Course', JSON.stringify(dataObject));
}

window.storeDataAnalysisObject = storeDataAnalysisObject;
