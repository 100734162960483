import { CreateIFrame } from './HTML';
import { xdr, xdrAsync } from './common';
import {
  ModalDialog,
  ModalDialogType,
} from '../vanilla-js/Components/ComponentsDialog';

const lang = window.lang ?? {};
const ghapi = window.ghapi;

const GHCC = {
  iframe: null,
  sessionId: null,
  linkSpeed: 100,
  moduleChanged: null,
  activePlayer: null,
  page: null,
  passportID: null,
  FunctionTerminate: null,
};

const ghccSSO = (Page) =>
  new Promise((resolve, reject) => {
    if (GHCC.iframe) {
      resolve();
      return;
    }
    const data = {
      suffix: 'sso',
      sessionId: GHCC.sessionId,
      courseId: 0,
    };
    Page.loadDialog = new ModalDialog(
      document.body,
      ModalDialogType.Busy,
      lang.generic.load_title_txt,
      null,
      function () {
        xdrAsync(
          ghapi + '/api/ghccSso',
          'POST',
          JSON.stringify(data),
          function (cc) {
            const o = JSON.parse(cc);

            GHCC.iframe = CreateIFrame(
              document.getElementById('content'),
              null,
              o.url
            );
            GHCC.iframe.id = 'contentGHCC';
            GHCC.iframe.width = 100;
            GHCC.iframe.height = 100;
            Page.loadDialog.close();
            resolve();
          },
          function (err) {
            Page.loadDialog.close();
            new ModalDialog(
              document.body,
              ModalDialogType.NotificationFail,
              lang.generic.err_dlg_text + err,
              lang.generic.err_dlg_btn
            );

            reject();
          }
        );
      }
    );
  });

let course_started;
let openedObject;

const ghccMessageListener = (Page) => {
  window.addEventListener(
    'message',
    function (e) {
      if (e.origin !== window.GHCC_URL) {
        return;
      }

      if (e.data.Function === 'Init') {
        GHCC.activePlayer = {};
      } else if (e.data.Function === 'LinkSpeed') {
        // LINK SPEED
        GHCC.linkSpeed = e.data.Data.AVG < 100 ? 100 : e.data.Data.AVG;

        // CLOSE LOAD DIALOG
        if (Page.loadDialog) {
          Page.loadDialog.close();
        }
      } else if (e.data.Function === 'NewPassport') {
        GHCC.passportID = e.data.Data.SessionIdentifier;
      } else if (e.data.Function === 'InitializeActivity') {
        GHCC.activePlayer.ts1player = parseInt(new Date() / 1000);
        GHCC.activePlayer.PlayerStart = true;

        if (GHCC.activePlayer.PlayerProgress) {
          GHCC.activePlayer.OldScore = GHCC.activePlayer.PlayerProgress.score;
        }

        if (document.getElementById('content').style.display === 'none') {
          course_started = true;
          document.getElementById('contentMask').style.display = 'block';

          var f = document.getElementById('content');
          f.style.display = 'block';
          setTimeout(function () {
            f.className = 'grow1';

            setTimeout(function () {
              f.className = 'grow2';

              document.getElementById('contentGHCC').width = '100%';
              document.getElementById('contentGHCC').height = '100%';
              openedObject = 'Activity';

              setTimeout(function () {
                document.body.style.overflowY = 'hidden';
              }, 400);
            }, 600);
          }, 300);
        }
      } else if (e.data.Function === 'UpdateActivityStatus_DISABLED') {
        console.log('update activity');
        setTimeout(function () {
          const data = {
            suffix: 'getresults',
            moduleId: e.data.Data.ActivityClientID.substr(2),
            ts1: GHCC.activePlayer.ts1player,
            ts2: parseInt(new Date() / 1000),
            ModuleStart: GHCC.moduleChanged || false,
            PlayerStart: GHCC.activePlayer.PlayerStart,
          };
          GHCC.activePlayer.PlayerStart = false;

          xdr(
            ghapi + '/api/ghccGetPlayerResult',
            'POST',
            JSON.stringify(data),
            function (cc) {
              var result = JSON.parse(cc);
            },
            function (x) {
              new ModalDialog(
                document.body,
                ModalDialogType.NotificationFail,
                lang.generic.err_dlg_text + x,
                lang.generic.err_dlg_btn
              );
            }
          );
        }, 0);
      } else if (e.data.Function === 'TerminateActivity') {
        console.log('terminate activity');

        if (openedObject === 'Activity' && !e.data.Data.AwaitingRestart) {
          var f = document.getElementById('content');

          document.body.style.overflowY = 'scroll';
          f.className = 'shrink1';
          setTimeout(function () {
            f.className = 'shrink2';
            setTimeout(function () {
              f.style.display = 'none';

              document.getElementById('contentGHCC').width = 0;
              document.getElementById('contentGHCC').height = 0;
              openedObject = '';
              course_started = false;
            }, 600);
          }, 600);
        }
        setTimeout(function () {
          const data = {
            suffix: 'getresults',
            moduleId: e.data.Data.ActivityClientID.substr(2),
            ts1: GHCC.activePlayer.ts1player,
            ts2: parseInt(new Date() / 1000),
            ModuleStart: GHCC.moduleChanged || false,
            PlayerStart: GHCC.activePlayer.PlayerStart,
          };
          xdr(
            ghapi + '/api/ghccGetPlayerResult',
            'POST',
            JSON.stringify(data),
            function (cc) {
              var result = JSON.parse(cc);

              //*****************

              //showData(result);

              // EXECUTE TERMINATE FUNCTION
              if (GHCC.FunctionTerminate) {
                GHCC.FunctionTerminate(result);
              }

              //*****************
              setTimeout(function () {
                document.getElementById('contentMask').style.display = 'none';
              }, 1000);
            },
            function (x) {
              new ModalDialog(
                document.body,
                ModalDialogType.NotificationFail,
                lang.generic.err_dlg_text + x,
                lang.generic.err_dlg_btn
              );
            }
          );
        }, 0);
      }
    },
    false
  );
};

export { GHCC, ghccSSO, ghccMessageListener };
