import tw, { styled, css } from 'twin.macro';

export type ButtonContainerProps = {
  align?: 'start' | 'center' | 'end';
};

export const ButtonContainer = styled.div<ButtonContainerProps>(({ align }) => [
  tw`pt-6 pb-6 px-8 flex`,
  css`
    justify-content: ${align};

    &.column {
      flex-direction: column;
      button {
        margin: 5px 0;
      }
    }
  `,
]);
