import { css, styled } from 'twin.macro';

export type ImageContainerProps = {
  parentWidth: string;
};

export const ImageContainer = styled.div<ImageContainerProps>(
  ({ parentWidth }) => [
    css`
      width: 100%;
      height: calc(${parentWidth} * 9 / 16);
      display: inline-block;
      overflow: hidden;
    `,
  ],
);

export const Image = styled.img<ImageContainerProps>(({ parentWidth }) => [
  css`
    width: 100%;
    height: calc(${parentWidth} * 9 / 16);
    object-position: center;
    object-fit: cover;

    &.account {
      transform: scale(0.7);
      height: calc(${parentWidth} * 10 / 16);
    }
  `,
]);
