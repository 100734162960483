import 'twin.macro';
import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  YoutubeIcon,
  InstagramIcon,
} from '../../common-components/Icon/icons.generated';
import useLanguage from '../../hooks/useLanguage';
import { useMemo } from 'react';
import { useWebsession } from '../../hooks/useWebsession';
import useEntitlements from '../../hooks/useEntitlements';

export type FooterSocials = {
  [socialName: string]: {
    link: string;
    icon: React.ReactElement;
  };
};

export type FooterCategory = {
  translationKey: string;
  links: {
    link: string;
    translationKey: string;
  }[];
};

export type FooterCategories = {
  [categoryName: string]: FooterCategory;
};

export default function useFooterContent() {
  const entitlements = useEntitlements();
  const {
    language: { code },
  } = useLanguage();
  const [currentUser] = useWebsession('CurrentUser');

  const socials: FooterSocials = useMemo(
    () => ({
      Facebook: {
        link: 'https://www.facebook.com/GoodHabitz',
        icon: <FacebookIcon className="icon" tw="h-full w-full" />,
      },
      LinkedIn: {
        link: 'https://www.linkedin.com/company/goodhabitz',
        icon: <LinkedinIcon className="icon" tw="h-full w-full" />,
      },
      Twitter: {
        link: 'https://twitter.com/GoodHabitzHQ',
        icon: <TwitterIcon className="icon" tw="h-full w-full" />,
      },
      YouTube: {
        link: 'https://www.youtube.com/c/GoodHabitzHQ',
        icon: <YoutubeIcon className="icon" tw="h-full w-full" />,
      },
      Instagram: {
        link: 'https://www.instagram.com/goodhabitz/',
        icon: <InstagramIcon className="icon" tw="h-full w-full" />,
      },
    }),
    [],
  );

  const categories = useMemo(() => {
    // Handle logged out user
    if (currentUser?.Id === -1) {
      return {
        support: {
          translationKey: 'footer_title_support',
          links: [
            {
              link: `/${code}/contact-and-support.html`,
              translationKey: 'footer_txt_support_contact',
            },
          ],
        },
      } as FooterCategories;
    }

    const categories: FooterCategories = {
      discover: {
        translationKey: 'footer_title_discover',
        links: [
          {
            link: `/${code}/personal-courses.html`,
            translationKey: 'footer_txt_for_you',
          },
          {
            link: `/${code}/all-courses.html`,
            translationKey: 'footer_txt_courses',
          },
        ],
      },
      account: {
        translationKey: 'footer_title_account',
        links: [
          {
            link: `/${code}/my-account/personal-info.html`,
            translationKey: 'footer_txt_account_settings',
          },
        ],
      },
      support: {
        translationKey: 'footer_title_support',
        links: [
          {
            link: 'https://support-goodhabitz.force.com/helpcenter',
            translationKey: 'menu_help_center',
          },
          {
            link: `/${code}/contact-and-support.html`,
            translationKey: 'footer_txt_support_contact',
          },
          {
            link: `/${code}/notifications.html`,
            translationKey: 'footer_txt_notifications',
          },
        ],
      },
    };

    if (entitlements.entGoodscanAccess) {
      categories.discover.links.push({
        link: `/${code}/goodscan.html`,
        translationKey: 'footer_txt_goodscan',
      });
    }

    return categories;
  }, [code, currentUser, entitlements]);

  return {
    categories,
    socials,
  };
}
