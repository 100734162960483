import 'twin.macro';
import { Button, Modal } from '../common-components';
import useTranslation from '../hooks/useTranslation';

type ContactModalSuccessProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ContactModalSuccess = ({ isOpen, onClose }: ContactModalSuccessProps) => {
  const t = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose} bgColor="purple">
      <div tw="rounded-large">
        <img src="/img/invite-process-2.svg" alt="" />
        <div tw="p-5 pb-10 bg-primary-default text-center text-primary-text-default flex flex-col items-center">
          <h2 tw="text-xl font-bold mb-7">{t('thank_you')}</h2>
          <p tw="mb-7">{t('pm_contact_success_message')}</p>
          <Button onClick={onClose} variant="secondary">
            {t('pm_btn_close')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ContactModalSuccess;
