import { createGlobalStyle } from 'styled-components';

const uiButtonFix = `
@media only screen and (max-width: 48em) {
  .embeddedServiceHelpButton .helpButton .uiButton {
    min-width: 52px;
  }

  .embeddedServiceHelpButton .helpButton .uiButton .helpButtonLabel {
    display: none;
  }

  .embeddedServiceHelpButton .embeddedServiceIcon {
    margin-right: 0 !important;
    width: 100%;
  }
}`;

export const ChatboxStyle = createGlobalStyle`
${uiButtonFix}

.embeddedServiceHelpButton .helpButton {
  bottom: 28px !important;
  right: 16px !important;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.16);
}

.embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #1e64ec !important;
  font-family: "Salesforce Sans", sans-serif;
  border-radius: 8px !important;
}

.embeddedServiceHelpButton .helpButton .helpButtonEnabled:hover::before,
.embeddedServiceHelpButton .helpButton .helpButtonEnabled:focus::before {
  background-color: transparent !important;
}

@font-face {
  font-family: 'Salesforce Sans';
  src: url('https://c1.sfdcstatic.com/etc/clientlibs/sfdc-aem-master/clientlibs_base/fonts/SalesforceSans-Regular.woff') format('woff'),
  url('https://c1.sfdcstatic.com/etc/clientlibs/sfdc-aem-master/clientlibs_base/fonts/SalesforceSans-Regular.ttf') format('truetype');
}`;

document.addEventListener('scroll', () => {
  const chatBoxElement = document.querySelector<HTMLElement>(
    '.embeddedServiceHelpButton .helpButton',
  );
  const footerContentContainer = document.querySelector<HTMLElement>(
    '.footer-content-container',
  );

  if (!chatBoxElement || !footerContentContainer) {
    return;
  }

  const chatBoxBottomOffset = 28;
  const footerContentContainerRect =
    footerContentContainer.getBoundingClientRect();

  const shouldChatBoxStickToFooter =
    window.innerHeight - footerContentContainerRect.bottom > 0;

  if (shouldChatBoxStickToFooter) {
    const chatBoxBottomPosition =
      window.innerHeight -
      footerContentContainerRect.bottom +
      chatBoxBottomOffset;

    chatBoxElement.style.setProperty(
      'bottom',
      `${chatBoxBottomPosition}px`,
      'important',
    );
  } else {
    chatBoxElement.style.setProperty(
      'bottom',
      `${chatBoxBottomOffset}px`,
      'important',
    );
  }
});

const scriptIdentifier = 'salesforce-chat-init';

export function injectInitScript() {
  const isInjected = Boolean(document.getElementById(scriptIdentifier));

  if (!isInjected) {
    const embeddedChatScript =
      require('!raw-loader!./init-esw.script.js').default; // eslint-disable-line import/no-webpack-loader-syntax

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.setAttribute('id', scriptIdentifier);
    script.textContent = embeddedChatScript;
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}

export function cleanUpInitScript() {
  const script = document.getElementById(scriptIdentifier);

  if (script) {
    script.parentNode?.removeChild(script);
  }
}
