import { useEffect } from 'react';
import { Spinner } from '../../common-components';
import { fetchWebsession } from '../../utils/apiRequests';
import { setToken, setWebsession } from '../../utils/sessionUtils';

const TokenLoginPage = () => {
  const tokenEventListener = async (event: any) => {
    if (event.data.type === 'token') {
      const token = event.data.token;
      setToken({ sessiontoken: 0, token });
      try {
        const websession = await fetchWebsession();
        if (websession) {
          setWebsession(websession);
          event.source.postMessage({ type: 'ready' }, '*');
        } else {
          event.source.postMessage(
            {
              type: 'error',
              details: 'Could not fetch websession with provided token',
            },
            '*',
          );
        }
      } catch (error) {
        event.source.postMessage(
          {
            type: 'error',
            details: 'Could not fetch websession with provided token',
            cause: error,
          },
          '*',
        );
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', tokenEventListener);
    return () => {
      window.removeEventListener('message', tokenEventListener);
    };
  }, []);

  return <Spinner />;
};

export default TokenLoginPage;
