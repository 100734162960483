import tw, { styled, css } from 'twin.macro';
import { AngleDownIcon } from '../../common-components';

export const LanguageSelect = styled.label(() => [
  tw`relative flex flex-row items-center`,
  css`
    .flag {
      ${tw`h-5`}
    }
    .flag {
      padding-left: 16px;
      padding-right: 10px;
    }
  `,
]);

export const AngleIcon = styled(AngleDownIcon)(() => [
  tw`text-2xl absolute right-0 -top-1 pointer-events-none cursor-pointer mr-[16px]`,
]);

export const Select = styled.select(() => [
  tw`pr-7 h-6 bg-transparent appearance-none font-body cursor-pointer w-[75%]`,
  tw`border-2 border-transparent rounded-medium`,
  tw`focus:(outline-none border-blue-100)`,
]);
