import { getLanguageString } from '../../vanilla-js/common';
import { DashboardMenuItem } from '../../vanilla-js/client';
import { MenuItem } from '../../vanilla-js/websession';

export const getDashboardMenuItems = (
  menuItems: MenuItem[],
): DashboardMenuItem[] => {
  let menuTree: DashboardMenuItem[] = [];

  let tempMenuItems = menuItems
    .map((item: MenuItem) => {
      const { Id, ParentId, MenuName, Icon, Properties, Url, DisplayOrder } =
        item;
      const itemProps = Properties ? JSON.parse(Properties) : {};

      return {
        Id,
        ParentId,
        Name: getLanguageString(MenuName),
        Icon: Icon || '',
        Color: itemProps.Color,
        Background: itemProps.Background,
        Url,
        DisplayOrder,
      };
    })
    .sort((a, b) => {
      if (a.ParentId === b.ParentId) return a.DisplayOrder - b.DisplayOrder;
      return a.ParentId - b.ParentId;
    });

  tempMenuItems.forEach((currentItem) => {
    if (currentItem.ParentId === 4300) menuTree.push(currentItem);
    else if (currentItem.ParentId > 4300) {
      let parentIndex = menuTree.findIndex(
        (mt) => mt.Id === currentItem.ParentId,
      );
      if (parentIndex < 0) return;
      if (menuTree[parentIndex].SubItems)
        menuTree[parentIndex].SubItems?.push(currentItem);
      else menuTree[parentIndex].SubItems = [currentItem];
    }
  });
  return menuTree;
};

export const getActivePageIndex = (menuItems: DashboardMenuItem[]): number => {
  const url = window.location.pathname.substring(
    window.location.pathname.lastIndexOf('/') + 1,
  );

  return menuItems.findIndex((item) => {
    return (
      item.Url.toLowerCase().split('-')[0] ===
      ('/client/dashboard/' + url).toLowerCase().split('-')[0]
    );
  });
};
