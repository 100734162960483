import { SlideUpContainer, ExitButton } from './SlideUpStyle';

type ToggleProps = {
  shown: boolean;
  onChange: Function;
  children?: React.ReactNode;
};

export const SlideUp = ({ shown, onChange, children }: ToggleProps) => {
  return (
    <SlideUpContainer className={shown ? `` : `hidden`}>
      <ExitButton onClick={(event) => onChange(event)}>&times;</ExitButton>
      {children}
    </SlideUpContainer>
  );
};
