import { xdr, xdrAsync, redirectToLogin } from './common';

export const websession = {};
window.websession = websession;

const sessionStorageKey = 'websession';

export function hasWebsession() {
  return !!localStorage.getItem(sessionStorageKey);
}

export function setWebsession(websession) {
  const websessionString =
    typeof websession === 'string' ? websession : JSON.stringify(websession);
  localStorage.setItem(sessionStorageKey, websessionString);
}

export function getWebsession() {
  return JSON.parse(localStorage.getItem(sessionStorageKey));
}

export function removeWebsession() {
  localStorage.removeItem(sessionStorageKey);
}

export function loadWebsession(callback) {
  xdr(
    ghapi + '/api/websession2',
    'GET',
    null,
    (ws) => {
      setWebsession(ws);
      callback();
    },
    (ws) => {
      if (ws === 401) {
        localStorage.removeItem(sessionStorageKey);
        if (localStorage.getItem('token')) {
          localStorage.removeItem('token');
        }
        redirectToLogin();
      }
    }
  );
}

window.loadWebsession = loadWebsession;

export function save_websession() {
  // COPY COURSE DATA TO COURSERESULTDATA IN WEBSESSION OBJECT
  websession.Courses.forEach(function (c) {
    for (var i = 0; i < websession.CourseResults.length; i++) {
      if (websession.CourseResults[i].CourseId === c.CourseId) {
        websession.CourseResults[i].completedId = c.completedId;
        websession.CourseResults[i].completedId = c.completedId;
        websession.CourseResults[i].favId = c.favId;
        websession.CourseResults[i].passedId = c.passedId;
        websession.CourseResults[i].progress = c.progress;
        break;
      }
    }
  });
  // MAKE COPY OF WEBSESSION
  var tmpWebsession = JSON.parse(JSON.stringify(websession));

  // REMOVE COURSE NODE
  delete tmpWebsession.Courses;

  // SAVE COPY OF WEBSESSION
  setWebsession(tmpWebsession);
}

window.save_websession = save_websession;

export const getWebsessionAsync = async () => {
  let ws = getWebsession();
  if (!ws) {
    await xdrAsync(
      ghapi + '/api/websession2',
      'GET',
      null,
      (data) => {
        setWebsession(data);
      },
      (error) => {
        if (error === 401) {
          localStorage.removeItem(sessionStorageKey);
          if (localStorage.getItem('token')) {
            localStorage.removeItem('token');
          }
        }
      }
    );
  }
  return ws ?? getWebsession();
};
