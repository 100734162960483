import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import {
  InputText,
  Textarea,
  Heading4,
  Button,
  Modal,
  ModalActions,
  ModalButton,
  ModalContent,
  ModalTitle,
} from '../../common-components';
import { Footer } from '../../components/Footer/Footer';
import Page from '../../components/Page';
import ContactModalSuccess from '../../components/ContactModalSuccess';
import { contactSupportPageId } from '../../constants/pageIds';
import useTranslation from '../../hooks/useTranslation';
import { contactAndSupportSubmit } from '../../utils/apiRequests';
import { StyledCard, StyledPageWrapper } from './ContactSupportPageStyles';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from 'twin.macro';
import useLanguage from '../../hooks/useLanguage';

type FormFields = {
  FormId: string;
  languagecode: string;
  companyName?: string;
  name?: string;
  email: string;
  phoneNumber?: string;
  subject?: string;
  description: string;
};

const genericFormError = 'formError';

type FormErrors = {
  [genericFormError]?: never;
};

const ContactSuppportContent = () => {
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormFields & FormErrors>();
  const {
    language: { code },
  } = useLanguage();
  const t = useTranslation(contactSupportPageId);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const { mutate } = useMutation(
    (values: FormFields) =>
      contactAndSupportSubmit(
        Object.keys(values).map((item) => ({
          Name: item,
          Value: values[item as keyof typeof values],
        })),
      ),
    {
      onSuccess: () => setIsSuccessModalOpen(true),
      onError: () => setError('formError', {}),
    },
  );

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
    reset();
  };

  return (
    <StyledPageWrapper>
      <StyledCard>
        <Heading4 tw="mb-5">{t('txt_title')}</Heading4>

        <form onSubmit={handleSubmit((data) => mutate(data))}>
          <div tw="mb-4 hidden">
            <InputText {...register('FormId')} hidden={true} value="1025" />
          </div>
          <div tw="mb-4 hidden">
            <InputText {...register('languagecode')} hidden value={code} />
          </div>
          <div tw="mb-4">
            <InputText
              {...register('companyName')}
              label={t('label_company_name')}
            />
          </div>
          <div tw="mb-4">
            <InputText {...register('name')} label={t('label_your_name')} />
          </div>
          <div tw="mb-4">
            <InputText
              {...register('email', { required: true })}
              label={t('label_email')}
              required
            />
          </div>
          <div tw="mb-4">
            <InputText
              {...register('phoneNumber')}
              label={t('label_phone_number')}
            />
          </div>
          <div tw="mb-4">
            <InputText {...register('subject')} label={t('label_subject')} />
          </div>
          <div tw="mb-2">
            <Textarea
              {...register('description', { required: true })}
              label={t('label_problem')}
              required
            />
          </div>
          <p tw="text-xs">{t('frm_txt_fields_required')}</p>
          <br />
          <Button tw="w-[100%] float-right lg:w-[50%]" type="submit">
            {t('btn_send')}
          </Button>
        </form>

        <Modal
          isOpen={Object.keys(errors).length > 0}
          onClose={() => clearErrors()}
        >
          <ModalTitle>Error</ModalTitle>
          <ModalContent>{t('err_dlg_text')}</ModalContent>
          <ModalActions>
            <ModalButton onClick={() => clearErrors()}>
              {t('pm_btn_close')}
            </ModalButton>
          </ModalActions>
        </Modal>
        <ContactModalSuccess
          isOpen={isSuccessModalOpen}
          onClose={handleCloseSuccessModal}
        />
      </StyledCard>
    </StyledPageWrapper>
  );
};

const ContactSupportPage = () => {
  return (
    <Page pageId={contactSupportPageId} background="grey">
      <ContactSuppportContent />
      <Footer />
    </Page>
  );
};

export default ContactSupportPage;
