import {
  AccountInfoData,
  ActivateVoucherFormValues,
} from '../models/AccountInfo';
import { DeleteUserData } from '../models/DeleteUser';
import { GoodScanCourse } from '../models/GoodScanCourse';
import { LanguageFromAPI } from '../models/Language';

import { StartActivity } from '../models/StartActivity';
import { PasswordsData } from '../models/Passwords';
import { LoginFormValues, Token } from '../models/Token';
import {
  get,
  getFromURL,
  getUnauth,
  getWithoutJSON,
  post,
  postToURL,
  postUnauth,
  postWithoutJSON,
  put,
} from '../utils/api';
import { ghccSSO } from '../vanilla-js/GHCC-connector';
import { WebSession } from '../vanilla-js/websession';
import {
  courseListEndpoint,
  accountUpdateEndpoint,
  downloadUserDataEndpoint,
  ghccStartActivityEndpoint,
  goodScanListEndpoint,
  languageEndpoint,
  crowdInLanguageEndpoint,
  passwordUpdateEndpoint,
  tokenEndpoint,
  websessionEndpoint,
  activateVoucherEndpoint,
  contactAndSupportEndpoint,
  adminCourseEndpoint,
  learningBitesEndpoint,
  supportConsentEndpoint,
  supportLogsEndpoint,
  profileEndpoint,
  legalDocumentDownloadEndpoint,
  playerResultsEndpoint,
  relatedLibraryCoursesEndpoint,
  libraryHierarchyEndpoint,
  leafletsExportEndpoint,
  lessonsEndpoint,
  otpEndpoint,
  websessionWithIdEndpoint,
} from './endpoints';
import { sanityPromoBannersQuery } from './sanityQueries';
import { Course } from '../models/Course';

const Page = window.Page ?? {};

export const fetchGoodScanList = () =>
  get<never, GoodScanCourse[]>(goodScanListEndpoint);

export const fetchStartActivity = (body: StartActivity) =>
  post<StartActivity, never>(ghccStartActivityEndpoint, body);

export const ssoGhcc = () => ghccSSO(Page);

export const fetchWebsession = () => get<never, WebSession>(websessionEndpoint);

export const fetchWebsessionWithId = () =>
  get<never, WebSession>(websessionWithIdEndpoint);

export const fetchLanguage = (languageCode: string) => {
  return getUnauth<never, LanguageFromAPI>(languageEndpoint(languageCode));
};

export const fetchCrowdInLanguage = async (languageCode: string) => {
  try {
    return getUnauth<never, LanguageFromAPI>(
      crowdInLanguageEndpoint(languageCode),
    );
  } catch (error) {
    console.error(`Something went wrong: ${(error as Error).message}`);
    return Promise.resolve(null);
  }
};

export const fetchToken = (data: LoginFormValues) =>
  postUnauth<LoginFormValues, Token>(tokenEndpoint, data);

export const fetchCourseList = (page: number) => {
  return get<never, any>(courseListEndpoint(page));
};
export const fetchAdminCourse = () => {
  return get<never, Course>(adminCourseEndpoint);
};
export const postAccountInfoUpdate = (body: AccountInfoData) =>
  post<AccountInfoData, any>(accountUpdateEndpoint, body);

export const passwordUpdate = (body: PasswordsData) =>
  post<PasswordsData, any>(passwordUpdateEndpoint, body);

export const forgetUserUpdate = (body: DeleteUserData) =>
  put<DeleteUserData, any>(accountUpdateEndpoint, body);

export const fetchUserData = () => getWithoutJSON(downloadUserDataEndpoint);

export const blobToFileDownload = (blob: any, fileName: string) => {
  var newBlob = new Blob([blob], { type: 'application/download' });

  const url = window.URL.createObjectURL(newBlob);

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);

  document.body.appendChild(link);
  link.click();
  link.parentNode!.removeChild(link);
};

export const postActivateVoucher = (body: ActivateVoucherFormValues) =>
  postUnauth<ActivateVoucherFormValues, any>(activateVoucherEndpoint, body);

export const contactAndSupportSubmit = (body: unknown) =>
  postUnauth(contactAndSupportEndpoint, body);

export const fetchLearningBites = (language: string) =>
  getUnauth<any, any>(learningBitesEndpoint(language));

export const fetchRelatedLibraryCourses = (language: string) =>
  getUnauth<any, any>(relatedLibraryCoursesEndpoint(language));

export const fetchProfile = () => {
  return get<never, any>(profileEndpoint);
};

export const fetchSupportConsent = (personId: number) => {
  return get<never, any>(supportConsentEndpoint(personId));
};

export const postSupportConsent = (personId: number, body: unknown) => {
  return post<any, any>(supportConsentEndpoint(personId), body);
};

export const fetchSupportLogs = (personId: number) => {
  return get<never, any>(supportLogsEndpoint(personId));
};

export const fetchLegalDocument = (body: any) =>
  postWithoutJSON(legalDocumentDownloadEndpoint, body);

export const getGhccPlayerResults = (body: unknown) => {
  return post<any, any>(playerResultsEndpoint, body);
};

export const getPromoBanners = ($language: string) => {
  return getFromURL<never, any>(sanityPromoBannersQuery({ $language }));
};

export const getLibrariesHierarchy = () => {
  return getFromURL<never, any>(libraryHierarchyEndpoint);
};

export const getExportLeaflets = (request: any) => {
  return postToURL<any, Response>(leafletsExportEndpoint, request);
};

export const getLessonsForLanguage = async (
  language: string,
  ldContext: any,
) => {
  const response = await postToURL<{ 'ld-context': any }, any>(
    lessonsEndpoint(language),
    {
      'ld-context': ldContext,
    },
  );

  return response.json();
};

export const getOTPCode = () => {
  return post<never, { Code: string; ValidUntil: Date }>(otpEndpoint);
};
