import { useEffect } from 'react';
import {
  authorizeAgrioSSO,
  exchangeCodeForToken,
  fetchAgrioSSOUser,
  fetchEdApiToken,
  redirectOnSuccess,
} from '../../utils/agrio-sso';
import { setWebsession } from '../../utils/sessionUtils';
import { fetchWebsession } from '../../utils/apiRequests';

const AgrioSsoLoginPage = () => {
  useEffect(() => {
    const initializeSSO = async () => {
      const url = new URL(window.location.href);
      const code = url.searchParams.get('code');
      await handleDeepLink(url);

      if (code) {
        const oidcTokens = await exchangeCodeForToken({
          code,
          codeVerifier: localStorage.getItem('codeVerifier') || '',
        });
        if (!oidcTokens) {
          console.warn('No tokens returned from exchangeCodeForToken');
          return;
        } else if (!oidcTokens.access_token) {
          console.warn('No access token returned from exchangeCodeForToken');
          return;
        }
        const agrioUserInfo = await fetchAgrioSSOUser(
          oidcTokens?.access_token || '',
        );
        await fetchEdApiToken(agrioUserInfo, oidcTokens.access_token);
        const webSession = await fetchWebsession();
        setWebsession(webSession);

        const deeplink = localStorage.getItem('deeplink');
        localStorage.removeItem('deeplink');

        if (deeplink) {
          localStorage.setItem('single-page-access', deeplink);
        }

        // @ts-ignore
        redirectOnSuccess(deeplink);
      } else {
        await authorizeAgrioSSO();
      }
    };

    const handleDeepLink = async (url: any) => {
      const deeplink = url.searchParams.get('deeplink');
      if (deeplink) {
        localStorage.setItem('deeplink', deeplink);
      }
    };

    initializeSSO();
  }, []);

  return <div>loading...</div>;
};

export default AgrioSsoLoginPage;
