import React, { useEffect, useState } from 'react';
import Keycloak from 'keycloak-js';
import { Spinner } from '../../common-components';
import { fetchWebsession } from '../../utils/apiRequests';
import { getSsoV2UrlParameters } from '../../utils/ssoV2Utils';
import { redirectTo } from '../../utils/routingUtils';
import { setLanguage, setToken, setWebsession } from '../../utils/sessionUtils';
import { persistedwebsession } from '../../hooks/useWebsession';
import 'twin.macro';

const keycloakLogin = async (customer: string) => {
  const keycloak = new Keycloak({
    realm: 'gh-sso-broker',
    url: window.KEYCLOAK_URL,
    clientId: 'gh-website',
  });

  try {
    const isAuthenticated = await keycloak.init({});
    console.log('User is already authenticated:', isAuthenticated);
    if (!isAuthenticated) {
      await keycloak.login({ idpHint: customer });
      return;
    }
  } catch (err) {
    throw new Error(`keycloak error: ${(err as object).toString()}`);
  }
  if (!keycloak.idTokenParsed) {
    throw new Error('OIDC id token is undefined');
  }
  if (!keycloak.idTokenParsed.gh_company_id) {
    throw new Error('gh_company_id is not configured in the Identity Provider');
  }

  const ssotokenBody = JSON.stringify({
    foreignUserId: keycloak.idTokenParsed.sid,
    firstName: keycloak.idTokenParsed.given_name,
    middleName: '',
    surname: keycloak.idTokenParsed.family_name,
    email: keycloak.idTokenParsed.email,
    companyId: keycloak.idTokenParsed.gh_company_id,
  });

  return { ssotokenBody, token: keycloak.token };
};

const fetchToken = async (ssoTokenBody: string, token: string) => {
  try {
    const response = await fetch(window.ghapi + '/api/keycloaktoken', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: ssoTokenBody,
    });
    if (!response.ok) {
      const errorResponse = await response.text();
      throw new Error(errorResponse);
    }
    const jsonResponse = await response.json();
    setToken({
      sessiontoken: jsonResponse.sessionToken,
      token: jsonResponse.token,
    });
  } catch (error) {
    throw new Error('Failed to get Education API JWT: ' + error);
  }
};

const redirectOnSuccess = (deepLink: string) => {
  if (deepLink) {
    document.location.href = deepLink;
  } else {
    redirectTo('personal-courses.html');
  }
};

const SsoV2LoginPage = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');

  const login = async (customer: string) => {
    const companyNameRegex = /^[0-9A-Za-zÀ-ÿs,._+;()*~'#@!?&-]+$/;

    if (!companyNameRegex.test(customer)) {
      setErrorMessage(
        'Company name is not valid. Please check the URL and contact your administrator.',
      );
      throw new Error();
    }
    let ssoTokenBody;
    let token;
    try {
      const keycloakResponse = await keycloakLogin(customer);
      ssoTokenBody = keycloakResponse?.ssotokenBody;
      token = keycloakResponse?.token;
    } catch (err) {
      console.error('SSO login failed:' + err);
      setErrorMessage(
        `Authentication with the Identity Provider was unsuccessful. Please contact your administrator.`,
      );
      throw new Error();
    }
    try {
      if (ssoTokenBody && token) await fetchToken(ssoTokenBody, token);
    } catch (err) {
      console.error(err);
      setErrorMessage(
        `Authentication with GoodHabitz was unsuccessful. Please contact your administrator.`,
      );
      throw new Error();
    }

    try {
      const websession = await fetchWebsession();
      setWebsession(websession);
    } catch (error) {
      console.error(error);
      setErrorMessage(
        `Authentication with the Identity Provider was unsuccessful. Please contact your administrator.`,
      );
      throw new Error();
    }
  };

  useEffect(() => {
    const urlParameters = getSsoV2UrlParameters();
    console.log(JSON.stringify(urlParameters));

    if (!urlParameters)
      return setErrorMessage(
        `SSO login URL parameters are missing. Please contact your administrator.`,
      );

    const { language, customer, deepLink } = urlParameters;

    // If there's deeplink, it will contain information about the language
    if (!deepLink) {
      setLanguage(language);
    } else {
      localStorage.setItem('single-page-access', deepLink);
    }

    // if there's a websession already, skip Keycloak login
    if (persistedwebsession && persistedwebsession.CurrentUser.Id !== -1) {
      redirectOnSuccess(deepLink);
    } else {
      // eslint-disable-next-line promise/prefer-await-to-then
      login(customer).then(() => {
        redirectOnSuccess(deepLink);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return errorMessage ? (
    <div tw="w-full h-full p-10 flex items-center justify-center font-body">
      <h3>{errorMessage}</h3>
    </div>
  ) : (
    <Spinner />
  );
};

export default SsoV2LoginPage;
