import { css } from 'twin.macro'; // eslint-disable-line @typescript-eslint/no-unused-vars
import { Label } from '../Label/Label';
import React, { ChangeEvent } from 'react';
import { inputStyles, error } from './InputText.styles';
import { FieldError } from 'react-hook-form';

type DefaultInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;
interface InputTextProps extends DefaultInputProps {
  label?: string;
  type?: string;
  formError?: FieldError;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const InputText = React.forwardRef(
  ({ label, formError, type = 'text', ...props }: InputTextProps, ref: any) => {
    return (
      <Label
        label={label}
        required={props.required}
        className={props.className}
      >
        <>
          <input
            css={[inputStyles, formError && error]}
            type={type}
            ref={ref}
            {...props}
          />
          {formError && <span>{formError.message}</span>}
        </>
      </Label>
    );
  },
);
