import { Spinner } from '../common-components';
import React from 'react';
import 'twin.macro';

export function SpinnerPage() {
  return (
    <div tw="fixed w-full h-full z-50 flex flex-col justify-center items-center bg-grey-0">
      <Spinner />
    </div>
  );
}
