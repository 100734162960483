import { PropsWithChildren, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ErrorBoundary as ReactErrorBoundary,
  FallbackProps,
} from 'react-error-boundary';
import 'twin.macro';
import { AlertModal } from '../../common-components';
import useTranslation from '../../hooks/useTranslation';
import { edit_url } from '../../vanilla-js/client';
import { addError } from '../../error-capturing';

export function errorToText(e: unknown): string {
  if (typeof e === 'string') {
    return e;
  }
  // xdr throws http status codes as errors
  if (typeof e === 'number') {
    return e.toString(10);
  }
  if (typeof (e as Error)?.message === 'string') {
    return (e as Error).message;
  }
  return JSON.stringify(e);
}

export const FallbackComponent = ({ error }: FallbackProps) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const t = useTranslation();
  const handleClose = () => {
    setIsModalOpen(false);
    document.location = edit_url('/client/login.html');
  };
  return (
    <AlertModal
      isOpen={isModalOpen}
      closeButtonLabel={t('err_dlg_btn')}
      onClose={handleClose}
    >
      {`${t('err_dlg_text')} ${errorToText(error)}`}
    </AlertModal>
  );
};

export const ErrorBoundary = ({ children }: PropsWithChildren<{}>) => {
  const location = useLocation();
  return (
    <>
      <ReactErrorBoundary
        FallbackComponent={FallbackComponent}
        resetKeys={[location]}
        onError={(error, info) => {
          addError(error);
        }}
      >
        {children}
      </ReactErrorBoundary>
    </>
  );
};
